import { Node, NodeProps } from '@xyflow/react';
import { CaseData } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/Case';
import {
  HandleType,
  NodeType
} from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/Elements';
import { LogicalOperator } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/LogicalOperator';
import { Rotation } from 'flyid-core/dist/Util/geometry';
import { generateDoubleId } from 'flyid-core/dist/Util/processFlow';
import useUpdateEffect from 'flyid-ui-components/dist/hooks/useUpdateEffect';
import { cloneDeep } from 'lodash';
import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import { useAppReactFlow } from 'src/hooks/useAppReactFlow';
import { filterElementsByType, getIncomersById } from 'src/util/processFlow/common';
import { createEdgeFrontBetweenNodes } from 'src/util/processFlow/edges';
import {
  arePropsEqual,
  isCommonNodeDataEqual,
  isSpecificDataEqual
} from 'src/util/processFlow/node';
import {
  BaseNodeData,
  CommonNodeData,
  LogicalBlockParent,
  SpecificDataTypesListable,
  TypedNode
} from 'src/util/processFlow/types';
import { Handles } from './BaseNode';
import ParentNode from './ParentNode';

export const LogicalBlockNode: React.FC<NodeProps<TypedNode<LogicalBlockParent>>> = (props) => {
  const logicalBlockData = props.data.specificData;
  if (!logicalBlockData) throw Error('Missing specific data for LogicalBlockNode');

  const { $t } = useIntl();
  const { addNodes, setNodes, getNodes, addEdges, getEdges, deleteElements } = useAppReactFlow();

  const {
    operation,
    contentChildrenIds,
    outputNodeId: logicalBlockOutputNodeId
  } = logicalBlockData;

  const handles: Handles = {
    inputHandles: [HandleType.MULTIPLE],
    outputHandles: []
  };

  // Trigger changes when operation changes
  useUpdateEffect(() => {
    // It was an OR and is now an AND, because LogicalBlock is always initialized with OR operation.
    if (operation === LogicalOperator.AND) {
      // An output node already exists, just leave it be.
      if (logicalBlockOutputNodeId) return;

      const allNodes = getNodes();
      const allEdges = getEdges();
      const allElements = [...allNodes, ...allEdges];

      const allConditionalNodes = filterElementsByType(allNodes, NodeType.Conditional);
      const outputConditionlNodes = allConditionalNodes.filter((n) => {
        // Get only conditional nodes which have incomers from contentChildrenIds
        const incomerIds = getIncomersById(n.id, allElements).map((inNode) => inNode.id);
        return contentChildrenIds.filter((childId) => incomerIds.includes(childId)).length > 0;
      });

      // 1. If there are more than one output nodes
      // Leave the external conditional node but remove all edges with children of this logical block.
      if (outputConditionlNodes.length > 1) {
        const edgesToRemove = getEdges().filter((edge) => {
          // Check if the source of the edge is a child node of the logical block
          const isSourceInsideBlock = contentChildrenIds.includes(edge.source);
          // Check that the target of the edge is the external conditional node
          const isTargetExternalConditional = edge.target === logicalBlockOutputNodeId;
          // Returns true if the edge connects a child of the logical block to the external conditional node
          return isSourceInsideBlock && isTargetExternalConditional;
        });
        // Remove the identified edges
        edgesToRemove.forEach((edgeToRemove) => {
          deleteElements({ edges: [{ id: edgeToRemove.id }] }).catch((err) =>
            console.error('Failed deleting edge', err)
          );
        });
      } // 2. If all inputs of a single output conditional node are from this logical block, attach it.
      else if (outputConditionlNodes.length === 1) {
        // Check that all entries in the conditional node are from this logic block by checking if
        // all the origins of the output node edges are within the logical block
        const theOnlyOutputConditionalNode = outputConditionlNodes[0];
        const allInputsFromBlock = getEdges()
          .filter((edge) => edge.target === theOnlyOutputConditionalNode.id)
          .every((edge) => contentChildrenIds.includes(edge.source));
        // If all the inputs are from the logic block and the conditional node is already present, connect it to the logic block
        if (allInputsFromBlock) {
          // Make the conditional node a child of the logical block
          setNodes((ns) =>
            ns.map((n) => {
              if (n.id === theOnlyOutputConditionalNode.id) {
                n = {
                  ...n,
                  position: { x: 0, y: 0 }, // will be repositioned by ParentNode
                  parentId: props.id,
                  dragHandle: '.custom-drag-handle',
                  draggable: false,
                  deletable: false,
                  data: {
                    ...n.data,
                    parent: props.id,
                    baseNodeData: {
                      ...n.data.baseNodeData,
                      hideDetach: true,
                      rotation: Rotation.State.NONE
                    }
                  }
                };
              } // Whenever a child changes, we have to inform the parent as well
              else if (n.id === props.id) {
                n = cloneDeep(n);
                n.data.specificData = {
                  ...n.data.specificData,
                  outputNodeId: theOnlyOutputConditionalNode.id
                } as LogicalBlockParent;
              }
              return n;
            })
          );
        }
      }
      // 3. There is no conditional node binded to the logical block children:
      // Add conditional node when operator and bind children to it.
      // Remove previous childrens output edges as well
      else {
        const contentChildrenNodes = getNodes().filter((n) => contentChildrenIds.includes(n.id));
        const type = NodeType.Conditional;
        const id = `${type}_${generateDoubleId()}`;

        const outputNode: TypedNode<CaseData[]> = {
          type,
          id,
          position: { x: 0, y: 0 }, // will be repositioned by ParentNode
          dragHandle: '.custom-drag-handle',
          parentId: props.id,
          draggable: false,
          deletable: false,
          zIndex: (props.zIndex ?? 0) + 1,
          // Remaining data will be filled on change callback processor
          data: {
            specificData: [],
            parent: props.id,
            baseNodeData: {
              hideDetach: true
            } as BaseNodeData
          }
        };

        // Add the output node
        addNodes(outputNode as TypedNode);
        // If children nodes already exist, remove their output edges
        deleteElements({
          edges: getEdges().filter((e) => contentChildrenIds.includes(e.source))
        }).catch((err) => console.error('Failed deleting edge', err));
        // And add edges bw them and conditional
        addEdges(contentChildrenNodes.map((n) => createEdgeFrontBetweenNodes(n, outputNode)));
      }
    } // If operator is changing to OR
    else if (operation === LogicalOperator.OR && logicalBlockOutputNodeId) {
      setNodes((ns) =>
        ns
          .map((n) => {
            if (n.id === logicalBlockOutputNodeId) {
              // Reset output node specific data if any, since it will break due to target changes:
              // Invalid cases will target logical OR block, while targeting label designs when AND.
              const specificData = n.data.specificData as CaseData[];
              if (specificData?.length) {
                n = {
                  ...n,
                  parentId: undefined,
                  extent: undefined,
                  position: { ...n.position },
                  dragHandle: '.custom-drag-handle',
                  draggable: true,
                  deletable: true,
                  data: {
                    ...n.data,
                    parent: undefined,
                    baseNodeData: {
                      ...n.data.baseNodeData,
                      hideDetach: false
                    },
                    specificData: []
                  }
                };
                return n;
              }
              // Otherwise, remove output node
              return undefined;
            }
            // Whenever a child changes, we have to inform the parent as well
            else if (n.id === props.id) {
              n = cloneDeep(n);
              (n.data.specificData as LogicalBlockParent).outputNodeId = undefined;
            }
            return n;
          })
          .filter((value): value is Node<CommonNodeData<SpecificDataTypesListable>> => !!value)
      );
    }
  }, [operation]);

  return (
    /* Pass data down to base node */
    <ParentNode
      id={props.id}
      selected={props.selected}
      content={{
        titleId: `${$t({
          id: `processFlow.${NodeType.LogicalBlock}`
        })}: ${logicalBlockData.name || `<${$t({ id: 'null' })}>`} (${$t({
          id: `logic.${operation}`
        })})`,
        placeholder: $t({ id: 'logicalBlock.dragToAdd' })
      }}
      handles={handles}
      {...props.data.baseNodeData}
      intersectsWith={props.data.intersectsWith}
      contentChildrenIds={contentChildrenIds}
      outputNodeId={logicalBlockOutputNodeId}
    />
  );
};

export default memo(LogicalBlockNode, arePropsEqual(isCommonNodeDataEqual, isSpecificDataEqual));
