import { UiState, UserState } from 'flyid-core/dist/Redux';
import { AnyAction, combineReducers } from 'redux';
import { USE_REDUX_LOGGER } from 'src/util/debug';
import { RESET_ACTION } from '../actions/actionTypes';
import { FirestoreState } from '../types/firestoreTypes';
import { LocaleState } from '../types/localeTypes';
import { ProcessFlowState } from '../types/processFlowTypes';
import { LoadingButtonState } from '../types/uiTypes';
import acquisitionPicsReducer from './acquisitionPicsReducer';
import firestoreReducer from './firestoreReducer';
import globalsReducer, { InternalGlobalsState } from './globalsReducer';
import labelImagesReducer from './labelImagesReducer';
import localeReducer from './localeReducer';
import processFlowSlice from './processFlowReducer';
import uiReducer from './uiReducer';
import userReducer from './userReducer';

const logger =
  (tag = '') =>
  (_state, action: AnyAction) => {
    if (USE_REDUX_LOGGER) {
      console.log(tag, action.type, action.payload);
    }
    return null;
  };

const appReducer = combineReducers({
  // Contains data related to the currently logged in user, including profile pictures
  user: userReducer,
  // Contains states related to global ui feedback
  ui: uiReducer,
  // Contains locale and translation data
  locale: localeReducer,
  // Contains state and data of label pictures
  labelImages: labelImagesReducer,
  // Contains state and data of acquisition-related pictures
  acqPictures: acquisitionPicsReducer,
  // Contains data related to process flow building process (UI-related)
  processFlow: processFlowSlice,
  // Contains general state values that require sharing along many components
  globals: globalsReducer,
  // Contains firestore data for child users, domains and owned data, when user is Mod or KeyUser
  firestore: firestoreReducer,
  // Logger
  logger: logger('Action received')
});

// Typing shenanigans to do what we want: add a reset action to the reducer
type AppReducer = typeof appReducer;
type AppState = Exclude<Parameters<AppReducer>[0], undefined>;

// Keep this initial state in sync with reducer state
// When resetting, this will be the new target state.
const initialState: Omit<AppState, 'logger'> = {
  acqPictures: {},
  labelImages: {},
  firestore: new FirestoreState(),
  globals: new InternalGlobalsState(),
  locale: new LocaleState(),
  processFlow: new ProcessFlowState(),
  ui: new UiState(new LoadingButtonState()),
  user: new UserState()
};

const rootReducer: AppReducer = (state, action) => {
  if (action.type === RESET_ACTION) {
    const resetState = { ...initialState } as AppState;
    // If there are auth providers, keep it, these are based on subdomain from current URL
    const { authProviders } = state?.firestore ?? {};
    if (authProviders?.areAuthProvidersLoaded && authProviders?.providers) {
      resetState.firestore.authProviders = authProviders;
    } else {
      // Otherwise set is as not loaded to trigger fetch.
      resetState.firestore.authProviders.areAuthProvidersLoaded = true;
    }
    return resetState;
  }
  return appReducer(state, action);
};

export default rootReducer;
