import { parseEpochFromTimestamp, TimestampLike } from 'flyid-core/dist/Util/time';
import { isJsxElement } from 'flyid-ui-components/dist/utils/helpers';
import { isObject } from 'lodash';
import { IntlShape } from 'react-intl';
import { formatTimeDate } from './string';
import { TableColumns, TableRows } from './table';

type OrderType = 'asc' | 'desc';
type ComparatorReturn = -1 | 0 | 1;
type ObjectComparator = <T extends object>(a: T, b: T) => ComparatorReturn;
type SortableType = number | string;

const asSortable = (value: unknown): SortableType =>
  typeof value === 'number' ? value : String(value);

/**
 * Returns sorting result from comparison of two objects (a and b) using its 'orderBy' key
 */
function desc<T extends object>(a: T, b: T, orderByKey: string): ComparatorReturn {
  const { first, second }: { first: SortableType; second: SortableType } =
    a[orderByKey] instanceof Date || b[orderByKey] instanceof Date
      ? {
          first: parseEpochFromTimestamp(a[orderByKey] as TimestampLike),
          second: parseEpochFromTimestamp(b[orderByKey] as TimestampLike)
        }
      : {
          first: asSortable(a[orderByKey]),
          second: asSortable(b[orderByKey])
        };

  if (second > first) return 1;
  if (second < first) return -1;
  return 0;
}

/**
 * Functions that returns sorting result depending on order (desc or asc)
 */
function getSorting(order: OrderType, orderBy: string): ObjectComparator {
  return (
    order === 'desc'
      ? (a: object, b: object) => desc(a, b, orderBy)
      : (a: object, b: object) => -desc(a, b, orderBy)
  ) as ObjectComparator;
}

/**
 * Sort based on cmp function, if items are equal, sort by initial index
 */
function stableSortFromComparator<T extends object>(
  array: T[],
  cmp: (a: T, b: T) => ComparatorReturn
) {
  const stabilizedThis: [T, number][] = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const containsSearchString = (value: string, searchValue: string) =>
  value.toUpperCase().includes(searchValue);
/**
 * Filters rows whenever searchText matches any of its columns
 */
export function searchData(
  rows: TableRows,
  columns: TableColumns,
  searchText: string,
  intl: IntlShape
) {
  if (searchText) {
    const upperSearch = searchText.toUpperCase();
    return rows.filter((row) =>
      columns
        .filter((column) => {
          return column.searchable === undefined ? !column.hidden : column.searchable;
        })
        .some((column) => {
          if (column.customFilterAndSearch) {
            return column.customFilterAndSearch(searchText, row, column);
          } else if (column.id) {
            const value = column.isDate
              ? formatTimeDate(row[column.id] as Date, intl, column.dateFormat)
              : (column.dataContentGetter?.(row) ?? row[column.id]);

            if (isJsxElement(value)) {
              return containsSearchString(String(value.type), upperSearch) || isObject(value.props)
                ? Object.values(value.props as object).some((p) =>
                    containsSearchString(String(p), upperSearch)
                  )
                : false;
            } else return value ? containsSearchString(String(value), upperSearch) : null;
          }
          return null;
        })
    );
  }
  return rows;
}

export const stableSort = <T extends object>(data: T[], order: OrderType, orderBy: string) => {
  return stableSortFromComparator(data, getSorting(order, orderBy));
};
