import axios, { AxiosRequestConfig, Method } from 'axios';
import { Auth } from 'firebase/auth';
import { getDownloadURL, ref } from 'firebase/storage';
import {
  DomainSettings,
  FieldsSettings,
  ProcessFlowSettings
} from 'flyid-core/dist/Database/Models/Settings/DomainSettings';
import { getLabelImageBackupPath, getLabelImagePath } from 'flyid-core/dist/Util/database';
import { decodeText } from 'flyid-core/dist/Util/web';
import { Dispatch } from 'redux';
import {
  setLabelImageFetchTriggered,
  setLabelImageLoaded
} from 'src/redux/reducers/labelImagesReducer';
import { MyDialogState } from 'src/redux/reducers/uiReducer';
import { silentyNavigateTo } from 'src/router';
import authAxios, { withParentUid, WithPossibleParent } from 'src/util/axios';
import { getSnackbar } from 'src/util/helpers/server';
import { urlDataReader } from '../../workers/fileWorkerApi';
import { updateUi, updateUiNoReset } from '../reducers/uiReducer';
import { AppState, ThunkActionType } from '../store';

export type AddDomainParams = {
  domain: string;
  currentDomains: string[];
  copyDomain: string;
};
export const addDomain =
  (data: AddDomainParams): ThunkActionType =>
  (dispatch, getState, { getAuth }) => {
    dispatch(
      updateUi({
        loadingButton: {
          isAddDomainLoading: true
        }
      })
    );

    const config: AxiosRequestConfig = {
      url: `/domains`,
      method: 'post',
      data
    };

    authAxios(getAuth(), getState(), config)
      .then((res) => {
        dispatch(updateUi({ snackbar: getSnackbar(res) }));
        silentyNavigateTo('/managedomains', { replace: true });
      })
      .catch((err: Error) => {
        dispatch(updateUi({ snackbar: getSnackbar(err) }));
      });
  };

export type RemoveDomainParams = WithPossibleParent<{ domain: string; currentDomains: string[] }>;
export const removeDomain =
  (params: RemoveDomainParams): ThunkActionType =>
  (dispatch, getState, { getAuth }) => {
    dispatch(
      updateUiNoReset({
        backdrop: {
          show: true,
          message: {
            msgCode: 'manDom.removingDomain',
            msg: 'Deleting domain data, it may take a while...'
          }
        },
        dialog: new MyDialogState({ show: false })
      })
    );

    authAxios(getAuth(), getState(), withParentUid({ url: `/domains`, method: 'delete' }, params))
      .then((res) => {
        dispatch(updateUi({ snackbar: getSnackbar(res) }));
      })
      .catch((err: Error) => {
        dispatch(updateUi({ snackbar: getSnackbar(err) }));
      });
  };

export type EditDomainFieldsSettingsParams = WithPossibleParent<{
  domain: string;
  currentSettings: DomainSettings;
  newSettings: FieldsSettings;
}>;
export const editDomainFieldsSettings =
  (params: EditDomainFieldsSettingsParams): ThunkActionType =>
  (dispatch, getState, { getAuth }) => {
    dispatch(
      updateUi({
        loadingButton: {
          isEditDomainSettingsFieldsLoading: true
        }
      })
    );

    authAxios(getAuth(), getState(), withParentUid({ url: `/domains`, method: 'patch' }, params))
      .then((res) => {
        dispatch(updateUi({ snackbar: getSnackbar(res) }));
      })
      .catch((err: Error) => {
        dispatch(updateUi({ snackbar: getSnackbar(err) }));
      });
  };

export type EditProcessFlowSettingsParams = WithPossibleParent<{
  domain: string;
  currentSettings: DomainSettings;
  newSettings: ProcessFlowSettings;
  labelDesignPictures?: { [nodeId: string]: string };
}>;
export const editProcessFlowSettings =
  (params: EditProcessFlowSettingsParams): ThunkActionType =>
  (dispatch, getState, { getAuth }) => {
    dispatch(
      updateUi({
        backdrop: {
          show: true,
          message: {
            msgCode: 'todoMsg',
            msg: 'Updating domain settings'
          }
        }
      })
    );

    authAxios(
      getAuth(),
      getState(),
      withParentUid({ url: `/processFlow`, method: 'patch' }, params)
    )
      .then((res) => {
        dispatch(updateUi({ snackbar: getSnackbar(res) }));
      })
      .catch((err: Error) => {
        dispatch(updateUi({ snackbar: getSnackbar(err) }));
      });
  };

export type RestoreDomainSettingsParams = WithPossibleParent<{
  domain: string;
  selectedSettings: DomainSettings;
}>;
export const restoreDomainSettings =
  (params: RestoreDomainSettingsParams): ThunkActionType =>
  (dispatch, getState, { getAuth }) => {
    dispatch(
      updateUi({
        backdrop: {
          show: true,
          message: {
            msgCode: 'todoMsg',
            msg: 'Restoring domain settings'
          }
        }
      })
    );

    authAxios(
      getAuth(),
      getState(),
      withParentUid(
        {
          url: `/restoreSettings`,
          method: 'patch'
        },
        params
      )
    )
      .then((res) => {
        dispatch(updateUi({ snackbar: getSnackbar(res) }));
      })
      .catch((err: Error) => {
        dispatch(updateUi({ snackbar: getSnackbar(err) }));
      });
  };

export type EditLicensesParams = WithPossibleParent<{ domain: string; licenseKeys: string[] }>;
export const editLicenses =
  (params: EditLicensesParams): ThunkActionType =>
  (dispatch, getState, { getAuth }) => {
    dispatch(
      updateUi({
        backdrop: {
          show: true,
          message: { msgCode: 'editLic.changingLicenses', msg: 'Changing licenses...' }
        }
      })
    );

    authAxios(getAuth(), getState(), withParentUid({ url: `/licenses`, method: 'patch' }, params))
      .then((res) => {
        dispatch(updateUi({ snackbar: getSnackbar(res) }));
        silentyNavigateTo(`/domain/, { replace:true}${params.data.domain}/settings`);
      })
      .catch((err: Error) => {
        dispatch(updateUi({ snackbar: getSnackbar(err) }));
      });
  };

export type AddUpdTableParams = {
  isUpdate: boolean;
  data: {
    domain: string;
    name: string;
    deflatedData: string;
    tableData: {
      keyColumn: string;
      valueColumns: string[];
    };
  };
};
export const addOrUpdateTable =
  ({ isUpdate, data }: AddUpdTableParams): ThunkActionType =>
  (dispatch, getState, { getAuth }) => {
    authAxios(getAuth(), getState(), {
      url: `/tabularData`,
      method: isUpdate ? 'patch' : 'post',
      data
    })
      .then((res) => {
        dispatch(updateUi({ snackbar: getSnackbar(res) }));
        silentyNavigateTo(`/domain/, { replace:true}${data.domain}/settings`);
      })
      .catch((err: Error) => {
        dispatch(updateUi({ snackbar: getSnackbar(err) }));
      });
  };

export type RemoveTableParams = WithPossibleParent<{ domain: string; tableName: string }>;
export const removeTable =
  (data: RemoveTableParams): ThunkActionType =>
  (dispatch, getState, { getAuth }) => {
    dispatch(
      updateUi({
        backdrop: {
          show: true,
          message: { msgCode: 'dSett.removingTable', msg: 'Removing table...' }
        }
      })
    );

    authAxios(
      getAuth(),
      getState(),
      withParentUid(
        {
          url: `/tabularData`,
          method: 'delete',
          data
        },
        data
      )
    )
      .then((res) => {
        dispatch(updateUi({ snackbar: getSnackbar(res) }));
      })
      .catch((err: Error) => {
        dispatch(updateUi({ snackbar: getSnackbar(err) }));
      });
  };

export type ResetUserPasswordParams = { uid: string; isPin: boolean };
export const resetUserPassword =
  (data: ResetUserPasswordParams): ThunkActionType =>
  (dispatch, getState, { getAuth }) => {
    dispatch(
      updateUiNoReset({
        backdrop: {
          show: true,
          message: {
            msgCode: `${data.isPin ? 'setPin' : 'setPw'}.resetting`,
            msg: `Resetting ${data.isPin ? 'PIN' : 'password'}...`
          }
        },
        dialog: new MyDialogState({ show: false })
      })
    );

    authAxios(getAuth(), getState(), {
      url: `/users/pw`,
      method: 'put',
      data
    })
      .then((res) => {
        dispatch(updateUi({ snackbar: getSnackbar(res) }));
      })
      .catch((err: Error) => {
        dispatch(updateUi({ snackbar: getSnackbar(err) }));
      });
  };

export type FetchLabelImageParams = {
  company: string;
  domain: string;
  labelId: string;
  fetchBackup?: boolean;
};
export const fetchLabelImage =
  (data: FetchLabelImageParams): ThunkActionType =>
  (dispatch, getState, { getDefautBucket }) => {
    const { company, domain, labelId, fetchBackup } = data;

    dispatch(setLabelImageFetchTriggered({ domain, labelId }));

    const getPath = fetchBackup ? getLabelImageBackupPath : getLabelImagePath;

    getDownloadURL(ref(getDefautBucket(), getPath(company, domain, labelId)))
      .then((url) =>
        axios({
          baseURL: undefined,
          url,
          method: 'GET',
          responseType: 'blob'
        })
      )
      .then(
        (res) =>
          new Promise((resolve) => {
            urlDataReader(res.data as Blob, {
              onload: (result) => {
                const src = result
                  ? typeof result === 'string'
                    ? result
                    : decodeText(result)
                  : undefined;
                  // This stringification is okay, since the only achievable object here is ArrayBuffer
                // eslint-disable-next-line @typescript-eslint/no-base-to-string
                if (!src) throw new Error('Failed decoding data for image ' + String(result));

                const img = new Image();
                img.onload = () => {
                  dispatch(
                    setLabelImageLoaded({
                      domain,
                      labelId,
                      src
                    })
                  );
                  resolve(true);
                };
                img.src = src;
              }
            });
          })
      )
      .catch((err: Error) => {
        if (!err.message.includes('storage/object-not-found')) console.error(err);
        dispatch(setLabelImageLoaded({ domain, labelId, src: undefined }));
      });
  };

/* eslint-disable no-void */
export type CreateApiKeyParams = WithPossibleParent<{ description: string; authDomains: string[] }>;
export const createApiKey =
  (data: CreateApiKeyParams): ThunkActionType =>
  (dispatch, getState, { getAuth }) => {
    dispatch(
      updateUi({
        backdrop: {
          show: true,
          message: { msgCode: 'manApiKeys.creatingApiKey', msg: 'Creating API Key...' }
        },
        loadingButton: {
          isCreateApiKeyLoading: true
        }
      })
    );

    void makeKeyApiCall('post', data, getState, getAuth, dispatch, {
      onSuccess: () => {
        silentyNavigateTo('/managekeys', { replace: true });
        window.location.reload();
      }
    });
  };

export type EditApiKeyAuthDomainsParams = WithPossibleParent<{
  domains: string[];
  apiKeyId: string;
}>;
export const editApiKeyAuthDomains =
  (data: EditApiKeyAuthDomainsParams): ThunkActionType =>
  (dispatch, getState, { getAuth }) => {
    dispatch(
      updateUi({
        backdrop: {
          show: true,
          message: {
            msgCode: 'manApiKeys.editingApiKeyAuthDomains',
            msg: 'Editing API Key authorized domains...'
          }
        },
        loadingButton: {
          isEditApiKeyAuthDomainsLoading: true
        }
      })
    );

    void makeKeyApiCall('patch', data, getState, getAuth, dispatch, {
      onSuccess: () => {
        silentyNavigateTo(-1);
      }
    });
  };

export type RenewApiKeyParams = WithPossibleParent<{ apiKeyId: string; description: string }>;
export const renewApiKey =
  (data: RenewApiKeyParams): ThunkActionType =>
  (dispatch, getState, { getAuth }) => {
    dispatch(
      updateUi({
        backdrop: {
          show: true,
          message: { msgCode: 'manApiKeys.renewingApiKey', msg: 'Renewing API Key...' }
        }
      })
    );

    void makeKeyApiCall('put', data, getState, getAuth, dispatch, {
      onSuccess: () => window.location.reload()
    });
  };

export type RemoveApiKeyParams = WithPossibleParent<{ apiKeyId: string }>;
export const removeApiKey =
  (data: RemoveApiKeyParams): ThunkActionType =>
  (dispatch, getState, { getAuth }) => {
    dispatch(
      updateUi({
        backdrop: {
          show: true,
          message: { msgCode: 'manApiKeys.removingApiKey', msg: 'Removing API Key...' }
        }
      })
    );

    void makeKeyApiCall('delete', data, getState, getAuth, dispatch, {
      onSuccess: () => window.location.reload()
    });
  };
/* eslint-enable no-void */

function makeKeyApiCall<Data, T extends WithPossibleParent<Data>>(
  method: Method,
  data: T,
  getState: () => AppState,
  getAuth: () => Auth,
  dispatch: Dispatch,
  callbacks: { onSuccess?: () => void; onFailure?: () => void } = {}
) {
  return authAxios(
    getAuth(),
    getState(),
    withParentUid(
      {
        url: `/keys`,
        method
      },
      data
    )
  )
    .then((res) => {
      dispatch(updateUi({ snackbar: getSnackbar(res) }));
      callbacks.onSuccess?.();
    })
    .catch((err: Error) => {
      dispatch(updateUi({ snackbar: getSnackbar(err) }));
      callbacks.onFailure?.();
    });
}
