import ClearIcon from '@mui/icons-material/Clear';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { encodeText } from 'flyid-core/dist/Util/web';
import { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Navigate, useParams } from 'react-router-dom';
import LoadingButton from 'src/components/widgets/LoadingButton';
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks';
import { useTaskInputFileParser } from 'src/hooks/useTaskInputFileParser';
import { createTask } from 'src/redux/actions/operationalActions';
import { updateUi } from 'src/redux/reducers/uiReducer';
import { selectSettings } from 'src/redux/selectors/dataSelectors';
import { appMakeStyles, useAppTheme } from 'src/theme/theme';
import { DecodedFile } from 'src/util/helpers/files';
import { getExpectedColumns, getTaskExampleAddresses } from 'src/util/helpers/tasks';
import { arrayBufferDataReader } from 'src/workers/fileWorkerApi';

const useStyles = appMakeStyles((theme) => ({
  container: {
    ...theme.resizableContainer(2),
    maxWidth: '1200px',
    marginLeft: theme.spacing(2)
  },
  horizontalFlex: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: theme.spacing(2, 0)
  },
  marginLeft: {
    marginLeft: theme.spacing(2)
  },
  tooltip: {
    alignSelf: 'center',
    marginLeft: theme.spacing(1)
  },
  tooltipRoot: {
    maxWidth: 500
  }
}));

const CreateInventoryTask: React.FC = () => {
  const [name, setName] = useState('');
  const [inputFileData, setInputFileData] = useState<DecodedFile | undefined>();

  const { $t } = useIntl();
  const theme = useAppTheme();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const inputRef = useRef<HTMLInputElement>(null);

  const { domain } = useParams<DomainMatchParams>();

  // Fallback to home if domain is missing
  if (!domain) return <Navigate replace to="/" />;

  const { ui, settings, state } = useAppSelector((state) => ({
    ui: state.ui,
    settings: selectSettings(state, domain),
    state
  }));

  const { isWorking, parsedTaskOrError, settingsTimestamp } = useTaskInputFileParser(
    domain,
    state,
    inputFileData,
    settings
  );

  const hasParsedData = !!parsedTaskOrError && typeof parsedTaskOrError !== 'string';
  const hasDataParseFailed = !hasParsedData && !!parsedTaskOrError;

  const tasksCount = hasParsedData ? Object.keys(parsedTaskOrError).length : 0;

  // Expected columns to be used as reference for the user
  let expectedColumns: string[] | null = null;
  if (settings) {
    const { expectedDataColumns } = getExpectedColumns(settings);
    expectedColumns = [settings.fieldSettings.baseFields.address, ...expectedDataColumns];
  }

  const clearInputFile = () => {
    setInputFileData(undefined);
    if (inputRef.current) inputRef.current.value = '';
  };

  const removeFileExtension = (_filename: string) => {
    let filename = _filename.split('.');
    if (filename.length > 1) {
      filename = filename.slice(0, filename.length - 1);
    }
    return filename.join('.');
  };

  const getCurrenTaskName = () => {
    let nameToUse = name;
    if (tasksCount < 0) return;
    else if (tasksCount === 1 && !nameToUse) {
      if (!inputFileData || !inputFileData.name) return;
      // Set task name the same as file name, removing file extension if any
      nameToUse = removeFileExtension(inputFileData.name);
    }
    return nameToUse;
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.preventDefault();

    const file = e.target.files?.[0];
    if (file) {
      arrayBufferDataReader(file, {
        onload: (result) => {
          if (result) {
            //Encode to ArrayBuffer if necessary
            const arrayBuffer = typeof result === 'string' ? encodeText(result) : result;
            setInputFileData(
              arrayBuffer
                ? {
                    name: file.name,
                    size: file.size,
                    type: file.type,
                    arrayBuffer
                  }
                : undefined
            );
          }
        },
        onerror: console.error
      });
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const nameToUse = getCurrenTaskName()?.trim();
    const taskCount = Object.keys(parsedTaskOrError ?? {}).length;
    if (!nameToUse && taskCount === 1) return;

    if (!hasParsedData || !settingsTimestamp) {
      dispatch(
        updateUi({
          snackbar: {
            message: $t({ id: 'createInventoryTask.missingValidFile' }),
            severity: 'error',
            duration: 2000,
            show: true
          }
        })
      );
      return;
    }

    dispatch(
      createTask({
        name: nameToUse || "",
        domain,
        tasksData: parsedTaskOrError,
        settingsTimestamp
      })
    );
  };

  return (
    <Container className={classes.container}>
      <Typography variant="h4" sx={theme.text.title}>
        {$t({ id: 'createInventoryTask.title' }, { domain })}
      </Typography>
      <Typography variant="subtitle1" sx={theme.text.subtitle}>
        {$t({ id: 'createInventoryTask.subtitle' })}
        <Tooltip
          className={classes.tooltip}
          classes={{ tooltip: classes.tooltipRoot }}
          title={
            expectedColumns ? (
              <Typography variant="subtitle2">
                {$t({ id: 'createInventoryTask.tooltip' }, { columns: expectedColumns.join(', ') })}
              </Typography>
            ) : (
              ''
            )
          }
        >
          <HelpOutlineIcon fontSize="small" sx={{ color: 'info.dark' }} />
        </Tooltip>
      </Typography>

      <form onSubmit={handleSubmit}>
        {/* Name, checkfield and max attempts */}
        <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder={
                tasksCount > 1 ? '' : removeFileExtension(inputFileData ? inputFileData.name : '')
              }
              id="name"
              name="name"
              type="text"
              InputLabelProps={{ shrink: true }}
              label={$t({
                id:
                  tasksCount > 1
                    ? 'createInventoryTask.taskBaseName'
                    : 'createInventoryTask.taskName'
              })}
              value={name}
              onChange={(e) => setName(e.target.value)}
              autoFocus
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <div className={classes.horizontalFlex}>
              <Box
                component="input"
                disabled={isWorking}
                accept=".csv, .xls, .xlsx, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                sx={{ display: 'none' }}
                id="file-input-btn"
                type="file"
                onChange={handleFileChange}
                ref={inputRef}
              />
              <label htmlFor="file-input-btn">
                <Button
                  disabled={isWorking}
                  variant="contained"
                  component="span"
                  disableElevation
                  sx={{ minWidth: 160 }}
                >
                  {$t({ id: 'uploadFile' })}
                </Button>
              </label>

              {isWorking ? (
                <CircularProgress sx={{ ml: 2 }} />
              ) : (
                inputFileData && (
                  <>
                    <Typography variant="body1" className={classes.marginLeft}>
                      {inputFileData.name}
                    </Typography>
                    <IconButton aria-label={'someMsg'} onClick={clearInputFile} size="large">
                      <ClearIcon sx={{ color: 'error.main' }} />
                    </IconButton>
                  </>
                )
              )}
            </div>
          </Grid>
        </Grid>

        {hasDataParseFailed ? (
          <Grid item xs={12} sx={{ mt: 2 }}>
            <Typography sx={{ color: 'error.main' }}>
              {$t({ id: 'failedParsingFile' }, { error: parsedTaskOrError })}
            </Typography>
          </Grid>
        ) : (
          hasParsedData && (
            <>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <Typography sx={{ color: 'success.main' }}>
                  {$t(
                    { id: 'createInventoryTask.parsedDataInfoTitle' },
                    { count: Object.keys(parsedTaskOrError).length }
                  )}
                </Typography>
              </Grid>
              {Object.entries(parsedTaskOrError).map(([sheetName, sheetData]) => (
                <Grid item xs={12} sx={{ mt: 2 }} key={sheetName}>
                  <Typography sx={{ color: 'success.main' }}>
                    {$t(
                      { id: 'createInventoryTask.parsedDataInfo' },
                      {
                        sheetName,
                        taskName:
                          tasksCount > 1
                            ? name
                              ? `${name}${sheetName}`
                              : sheetName
                            : name || getCurrenTaskName(),

                        size: sheetData.addressesCount,
                        exampleAddresses: getTaskExampleAddresses(sheetData)
                      }
                    )}
                  </Typography>
                </Grid>
              ))}
            </>
          )
        )}
        <Grid item xs={12} sx={{ mt: 2 }}>
          <LoadingButton
            isLoading={ui.loadingButton.isCreateTaskLoading}
            content={$t({ id: 'create' })}
            type="submit"
          />
        </Grid>
      </form>
    </Container>
  );
};

export default CreateInventoryTask;
