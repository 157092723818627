import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import { DomainSettings } from 'flyid-core/dist/Database/Models';
import {
  AcquisitionDataFlags,
  InflatedAcquisitionData,
  InflatedAddressesData
} from 'flyid-core/dist/Database/Models/AcquisitionData';
import { LogicalOperator } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/LogicalOperator';
import { parseEpochFromTimestamp, TimestampLike } from 'flyid-core/dist/Util/time';
import Carousel from 'flyid-ui-components/dist/utils/Carousel';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks';
import useFirebaseImage from 'src/hooks/useFirebaseImage';
import useStateReducer from 'src/hooks/useStateReducer';
import { Actions } from 'src/redux/actions/actionTypes';
import { MyDialogState, updateUi } from 'src/redux/reducers/uiReducer';
import { selectTargetCompany } from 'src/redux/selectors/globalSelectors';
import { selectCurrentUserProfile } from 'src/redux/selectors/userSelectors';
import { appMakeStyles, useAppTheme } from 'src/theme/theme';
import { getDeflated } from 'src/util/helpers/files';
import { fetchAndShowStoragePictures } from '../../../redux/actions/userActions';
import LoadingCircle from '../../widgets/LoadingCircle';
import { CheckFieldsDatalist } from './SessionReview';
import SessionPreview from './SessionPreview';
import { isNonNulli } from 'flyid-core/dist/Util/helpers';

const useStyles = appMakeStyles((theme) => ({
  container: {
    ...theme.resizableContainer(2),
    marginLeft: 0
  },
  subtitleContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  singleButton: {
    display: 'flex',
    justifyContent: 'right',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  subtitle: {
    marginBottom: theme.spacing(2)
  },
  textField: {
    width: '10vw'
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3)
  },
  image: {
    height: 200,
    width: 400
  },
  headerCell: {
    fontWeight: 'bold'
  },
  checkBoxContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1)
  },
  marginRight: {
    marginRight: theme.spacing(1)
  },
  disabledField: {
    backgroundColor: '#F5F5F5'
  },
  reviewTable: {
    marginBottom: theme.spacing(2)
  },
  tableLabel: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  }
}));

export type SessionReviewerProps = {
  reviewableAddresses: InflatedAddressesData;
  totalAddresses: InflatedAddressesData;
  checkFields: CheckFieldsDatalist[];
  resultFieldNull: string;
  settings: DomainSettings | undefined;
  logicalBlockVerification: LogicalOperator;
};

type ReviewState = {
  page: number;
  total: number;
};

type ChangedDataValues = { [key: string]: string | number | Record<string, never> | boolean };
export type ChangedData = { [address: string]: ChangedDataValues[] };

enum CheckboxType {
  INVALID = 'invalid',
  EMPTY = 'empty'
}

const SessionReviewLayout: React.FC<SessionReviewerProps> = (props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const $t = intl.$t;
  const navigate = useNavigate();
  const theme = useAppTheme();

  const { domain, session } = useParams<SessionMatchParams>();
  if (!domain || !session) return <Navigate to="/" replace />;

  const { profile, targetCompany, userData } = useAppSelector((s) => ({
    profile: selectCurrentUserProfile(s),
    targetCompany: selectTargetCompany(s),
    userData: s.user
  }));

  const [reviewState, setReviewState] = useStateReducer<ReviewState>({ page: 1, total: 0 });
  const [changedData, setChangedData] = useState<ChangedData>({});

  const totalAddresses = props.totalAddresses;
  const checkFields: string[][] = props.checkFields.map((datalists) => {
    return datalists.map((datalist) => {
      return datalist.fieldName;
    });
  });
  const isFieldRequired: boolean[][] = props.checkFields.map((datalists) => {
    return datalists.map((datalist) => {
      return datalist.isRequired;
    });
  });
  const originalAddressData = useMemo(() => {
    return Object.entries(props.reviewableAddresses)[reviewState.page - 1];
  }, [props.reviewableAddresses, reviewState.page]);

  const handleTextfieldChange = (event: React.ChangeEvent<HTMLInputElement>, rowIndex: number) => {
    const { name, value } = event.target;
    const page = reviewState.page - 1;

    const newData: ChangedData = { ...changedData };

    Object.entries(newData).forEach(([address, values], index) => {
      newData[address] = values.map((item, idx) => {
        if (idx === rowIndex && index === page) {
          return { ...item, [name]: value };
        }
        return item;
      });
    });

    setChangedData(newData);
  };

  // Handling Session Review Checkboxes
  const setEmptyAddress = (reset: boolean) => {
    const resetedData: ChangedData = changedData;

    if (reset === true) {
      Object.entries(resetedData).forEach(([objKey, objValue]) => {
        if (objKey === Object.keys(resetedData)[reviewState.page - 1]) {
          objValue.forEach((obj, index) => {
            objValue[index] = {};
          });
        }
      });
    } else {
      Object.entries(resetedData).forEach(([objKey, objValue]) => {
        if (objKey === Object.keys(resetedData)[reviewState.page - 1]) {
          objValue.forEach((item, index) => {
            objValue[index] = {
              ...item,
              ...checkFields[index]?.reduce((acc, field) => {
                acc[field] = props.resultFieldNull;
                return acc;
              }, {} as ChangedDataValues)
            };
          });
        }
      });
    }

    setChangedData(resetedData);
  };

  const [checkboxState, setCheckboxState] = useState<{ empty: boolean; invalid: boolean }[]>([]);

  const handleCheckBox = (flag: string) => {
    let reset = false;
    const page = reviewState.page - 1;

    const newData = checkboxState.map((value, index) => {
      if (index === page) {
        if (flag === (CheckboxType.EMPTY as string)) {
          if (value.empty === true) {
            reset = true;
            return { empty: !value.empty, invalid: value.invalid };
          } else if (value.invalid === true) {
            return { empty: !value.empty, invalid: !value.invalid };
          } else {
            return { empty: !value.empty, invalid: value.invalid };
          }
        } else if (flag === (CheckboxType.INVALID as string)) {
          if (value.invalid === true) {
            reset = true;
            return { empty: value.empty, invalid: !value.invalid };
          } else if (value.empty === true) {
            return { empty: !value.empty, invalid: !value.invalid };
          } else {
            return { empty: value.empty, invalid: !value.invalid };
          }
        }
      }
      return value;
    });

    setCheckboxState(newData);
    setEmptyAddress(reset);
  };

  // Returns the table that contains the original values of an specific session address
  // It also has an input for each check field with the with the possibility of updating the value
  function ReviewTable(initialRow: [string, InflatedAcquisitionData[]]) {
    const initialRowAddress = initialRow?.[0];
    const initialRowValues = initialRow?.[1];
    const changedDataEntries = Object.entries(changedData);
    const pageIndex = reviewState.page - 1;

    return (
      <Box data-testid="review-table-container">
        {initialRow ? (
          <>
            <Box className={classes.checkBoxContainer}>
              <Typography className={classes.marginRight}>
                {$t({ id: 'sessionReview.checkboxes' })}
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    id={`${initialRowAddress}empty`}
                    onChange={() => handleCheckBox(CheckboxType.EMPTY)}
                    checked={checkboxState[reviewState.page - 1].empty}
                  />
                }
                label={$t({ id: 'empty' })}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    id={`${initialRowAddress}invalid`}
                    onChange={() => handleCheckBox(CheckboxType.INVALID)}
                    checked={checkboxState[reviewState.page - 1].invalid}
                  />
                }
                label={$t({ id: 'invalid' })}
              />
            </Box>

            {initialRowValues.map((row, rowIndex) => {
              return (
                <Box data-testid="review-table">
                  <Typography
                    className={classes.tableLabel}
                  >{`${$t({ id: 'item' })} ${rowIndex + 1}`}</Typography>
                  <TableContainer component={Paper} key={rowIndex} className={classes.reviewTable}>
                    <Table sx={{ minWidth: 650 }} aria-label="sessionReviewTable">
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.headerCell}>
                            {$t({ id: 'sessionReview.fieldName' })}
                          </TableCell>
                          <TableCell align="right" className={classes.headerCell}>
                            {$t({ id: 'sessionReview.acquiredValue' })}
                          </TableCell>
                          <TableCell align="right" className={classes.headerCell}>
                            {$t({ id: 'sessionReview.reviewedValue' })}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {checkFields[rowIndex].map((field) => (
                          <TableRow
                            key={`${initialRowAddress}-${field}`}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 }
                            }}
                            className={
                              checkboxState[reviewState.page - 1].empty ||
                              checkboxState[reviewState.page - 1].invalid
                                ? classes.disabledField
                                : ''
                            }
                          >
                            <TableCell component="th" scope="row">
                              {field}
                            </TableCell>
                            <TableCell align="right">{row[field]}</TableCell>
                            <TableCell align="right">
                              <TextField
                                id={`${initialRowAddress}${field}`}
                                name={field}
                                placeholder={
                                  (changedDataEntries[pageIndex][1][rowIndex][field] as string) ??
                                  row[field]
                                }
                                className={classes.textField}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                  handleTextfieldChange(event, rowIndex)
                                }
                                value={changedDataEntries[pageIndex][1][rowIndex][field] ?? ''}
                                variant="outlined"
                                size="small"
                                disabled={
                                  checkboxState[reviewState.page - 1].empty ||
                                  checkboxState[reviewState.page - 1].invalid
                                }
                                required={isFieldRequired[rowIndex][reviewState.page - 1]}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              );
            })}
          </>
        ) : null}
      </Box>
    );
  }

  const handleNextPage = () => {
    if (reviewState.page < reviewState.total + 1) {
      setReviewState({ page: reviewState.page + 1 });
    }
  };

  const handlePreviousPage = () => {
    if (reviewState.page > 1) {
      setReviewState({ page: reviewState.page - 1 });
    }
  };

  // Before submiting the data, we verify if there is any field that was not changed
  // If so, the original value of that field is added to the object
  const handleSubmit = () => {
    let count = 0;

    Object.entries(totalAddresses).forEach(([finalDataKey, finalDataValues]) => {
      Object.entries(props.reviewableAddresses).forEach(
        ([originalDataKey, originalDataValues], index: number) => {
          if (finalDataKey === originalDataKey) {
            // Update each object in the array
            finalDataValues.forEach((finalDataValue) => {
              Object.values(changedData)[index].forEach((values) => {
                Object.entries(values).forEach(([key, value]) => {
                  finalDataValue[key] = value as string | number | boolean | string[] | undefined;
                });

                finalDataValue['checkedAt'] = Date.now();
                finalDataValue['employeeName'] = `${profile.firstName} ${profile.lastName}`;
                finalDataValue['employeeId'] = profile.employeeId;
                finalDataValue['acquisition_flag_invalid'] = checkboxState[count].invalid;
              });
            });
            count += 1;
          }
        }
      );
    });

    const finalData: InflatedAddressesData = {};

    Object.entries(totalAddresses).forEach(([addressKey, addressValues]) => {
      finalData[addressKey] = addressValues;
    });

    showDialogConfirmation(finalData).catch((err) => console.log(err));
  };

  // This function show a confirmation dialog, If the user confirms the action, it will trigger the session update function
  const showDialogConfirmation = async (data: InflatedAddressesData) => {
    await getDeflated(JSON.stringify(data)).then((deflatedAddresses) => {
      dispatch(
        updateUi({
          dialog: new MyDialogState({
            title: $t({ id: 'sessionReview.submitReviewTitle' }),
            message: $t({ id: 'sessionReview.submitReviewMsg' }),
            show: true
          }).setConfirmAction(Actions.UPDATE_SESSION, {
            company: targetCompany!,
            domain: domain,
            session: session,
            userName: `${userData.profile.firstName} ${userData.profile.lastName}`,
            addresses: deflatedAddresses,
            redirectUser: () => navigate(`/domains/${domain}/`)
          })
        })
      );
    });
  };

  function FooterButtons() {
    return (
      <Box
        className={reviewState.page === 1 ? classes.singleButton : classes.buttonContainer}
        data-testid="footer-buttons-container"
      >
        {reviewState.page === 1 ? null : (
          <Button variant="contained" size="small" onClick={handlePreviousPage}>
            {$t({ id: 'previous' })}
          </Button>
        )}

        {reviewState.page === reviewState.total + 1 ? (
          <Button onClick={handleSubmit} variant="contained" size="small">
            {$t({ id: 'sessionReview.finish' })}
          </Button>
        ) : (
          <Button variant="contained" size="small" onClick={handleNextPage}>
            {reviewState.page === reviewState.total ? $t({ id: 'preview' }) : $t({ id: 'next' })}
          </Button>
        )}
      </Box>
    );
  }

  // Fetch the image of an address and set its src and alt to a useState
  const pictureFilenames = useMemo(() => {
    return reviewState.page > reviewState.total
      ? []
      : originalAddressData[1].flatMap(
          (rowData) => rowData[AcquisitionDataFlags.PictureFilenames] ?? []
        );
  }, [reviewState.page, reviewState.total, originalAddressData]);

  const imagesFromStorage = useFirebaseImage(targetCompany, domain, pictureFilenames);

  // If the user clicks on the picture, it will trigger react viewer for zoom and display options
  const showPicture = () => {
    if (props.settings && profile && pictureFilenames && targetCompany) {
      dispatch(
        fetchAndShowStoragePictures({
          company: targetCompany,
          domain,
          address: originalAddressData[0],
          checkedAtMs: parseEpochFromTimestamp(
            originalAddressData[1]['checkedAt'] as TimestampLike
          ),
          pictureFilenames: pictureFilenames,
          addressField: props.settings?.fieldSettings.baseFields.address,
          intl
        })
      );
    }
  };

  // Prepare components states on load
  useEffect(() => {
    // For each object inside the array "reviewableAddresses", add an empty object to be populated into "changedData" state
    if (props.reviewableAddresses) {
      const initialChangedData: ChangedData = Object.entries(props.reviewableAddresses).reduce(
        (acc, [key, values]) => {
          const objs = values.map(() => {
            return {} as ChangedDataValues;
          });
          acc[key] = objs;
          return acc;
        },
        {} as ChangedData
      );

      setChangedData(initialChangedData);
      setCheckboxState(
        Object.keys(props.reviewableAddresses).map(() => ({ empty: false, invalid: false }))
      );
    }

    setReviewState({
      page: 1,
      total: props.reviewableAddresses ? Object.keys(props.reviewableAddresses).length : 0
    });
  }, [props.reviewableAddresses]);

  return (
    <>
      {props.reviewableAddresses &&
      props.totalAddresses &&
      props.checkFields &&
      isNonNulli(props.resultFieldNull) &&
      props.settings &&
      props.logicalBlockVerification ? (
        <Box className={classes.container} data-testid="session-review-container">
          <Box>
            {reviewState.page === reviewState.total + 1 ? (
              <>
                <SessionPreview
                  originalData={props.reviewableAddresses}
                  changedData={changedData}
                  checkFields={checkFields}
                  checkboxState={checkboxState}
                />
                {FooterButtons()}
              </>
            ) : (
              <>
                <Box>
                  <Typography variant="h4" sx={theme.text.title}>
                    {`${$t({ id: 'domain.sessionReview' })} - ${session}`}
                  </Typography>
                </Box>
                <Box className={classes.subtitleContainer}>
                  <Typography variant="subtitle1" sx={theme.text.subtitle}>
                    {`${originalAddressData ? `${$t({ id: 'sessionReview.address' })}: ${originalAddressData[0]}` : ''}`}
                  </Typography>
                  <Typography variant="subtitle1" sx={theme.text.subtitle}>
                    {$t({ id: 'sessionReview.page' })} {reviewState.page} / {reviewState.total}
                  </Typography>
                </Box>
                <Typography variant="subtitle2" sx={theme.text.subtitle}>
                  {$t({ id: 'sessionReview.description' })}
                </Typography>

                {
                  imagesFromStorage.loading ? (
                    <LoadingCircle />
                  ) : imagesFromStorage.images.length ? (
                    <Box className={classes.imageContainer} data-testid="carousel">
                      <Carousel images={imagesFromStorage.images} onClick={showPicture} />
                    </Box>
                  ) : null /* Todo Missing image fallback */
                }

                {ReviewTable(originalAddressData)}
                {FooterButtons()}
              </>
            )}
          </Box>
        </Box>
      ) : (
        <LoadingCircle />
      )}
    </>
  );
};

export default SessionReviewLayout;
