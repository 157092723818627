import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { storeValue } from 'flyid-core/dist/Util/web';
import { immerable } from 'immer';
import { cloneDeep } from 'lodash';

const GLOBAL_STORAGE_KEY = 'global-storage-key';

export class InternalGlobalsState {
  [immerable] = true;
  /** The globally selected company */
  targetCompany = '';
  /** The globally selected parentUid for actions that allow selection of a parent moderador */
  targetParentUid = '';
}
export type GlobalsState = Omit<InternalGlobalsState, typeof immerable>;
export type GlobalsStateKey = keyof GlobalsState;

export const getGlobalStorageKey = (key: GlobalsStateKey) =>
  `${GLOBAL_STORAGE_KEY}-${key as string}`;

const globalsSlice = createSlice({
  name: 'globals',
  initialState: new InternalGlobalsState(),
  reducers: {
    updateGlobals: (state, action: PayloadAction<Partial<InternalGlobalsState>>) => {
      const withEffects = { ...action.payload };
      // Apply transformation effects depending on data change
      if (action.payload.hasOwnProperty('targetCompany')) {
        // When 'targetCompany' changes but 'targetParentUid' doesn't, we have to reset 'targetParentUid'
        // Since the targeted user does cannot belong to the new target company.
        withEffects['targetParentUid'] = action.payload.targetParentUid ?? '';
      }

      Object.assign(state, withEffects);
      Object.entries(state).forEach(([key, value]) => {
        storeValue(getGlobalStorageKey(key as GlobalsStateKey), value);
      });
    },
    resetGlobals: (state) => {
      state = cloneDeep(new InternalGlobalsState());
      Object.keys(state).forEach((key, value) => {
        storeValue(getGlobalStorageKey(key as GlobalsStateKey), value);
      });
    }
  }
});
export type GlobalsSlice = typeof globalsSlice;

export const { updateGlobals, resetGlobals } = globalsSlice.actions;

export default globalsSlice.reducer;
