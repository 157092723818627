import ClearIcon from '@mui/icons-material/Clear';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { AddressesMapKey } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/TabularData';
import { isTranslatableError } from 'flyid-core/dist/Util/exceptions';
import { encodeText } from 'flyid-core/dist/Util/web';
import React, { FormEvent, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import LoadingButton from 'src/components/widgets/LoadingButton';
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks';
import { addOrUpdateTable } from 'src/redux/actions/managementActions';
import { updateUi, updateUiNoReset } from 'src/redux/reducers/uiReducer';
import { selectSettings } from 'src/redux/selectors/dataSelectors';
import { appMakeStyles, useAppTheme } from 'src/theme/theme';
import { DecodedFile } from 'src/util/helpers/files';
import { getDeflated, useTableInputFileParser } from 'src/workers/dataWorkerApi';
import { arrayBufferDataReader } from 'src/workers/fileWorkerApi';
import { Nilable } from 'tsdef';

const useStyles = appMakeStyles(({ resizableContainer, spacing, form, other }) => ({
  root: {
    ...form,
    ...resizableContainer(2),
    marginLeft: 0,
    flexGrow: 1
  },
  horizontalFlex: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: spacing(0)
  },
  marginLeft: { marginLeft: spacing(2) },
  title: {
    color: other.grey.dark,
    marginBottom: spacing(2)
  },
  tooltip: { alignSelf: 'center', marginLeft: spacing(1) },
  tooltipRoot: { maxWidth: 300 },
  content: { margin: spacing(2) }
}));

enum TableType {
  CUSTOM = 'CUSTOM',
  ADDRESS = 'ADDRESS'
}

type LocationState = { updateName: string };

const AddOrUpdateTabularData: React.FC = () => {
  const { $t } = useIntl();
  const { spacing, text, select } = useAppTheme();
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const { domain } = useParams<DomainMatchParams>();
  const location = useLocation();
  const updateName = (location.state as Nilable<LocationState>)?.updateName;
  const isUpdate = !!updateName;

  // Fallback to home if domain is missing
  if (!domain) return <Navigate replace to="/" />;

  const { ui, settings } = useAppSelector((_state) => ({
    ui: _state.ui,
    settings: selectSettings(_state, domain)
  }));

  const inputRef = useRef<HTMLInputElement>(null);
  const [tableType, setTableType] = useState<TableType>(
    isUpdate && updateName === AddressesMapKey ? TableType.ADDRESS : TableType.CUSTOM
  );
  const [name, setName] = useState(updateName ?? '');
  const [inputFileData, setInputFileData] = useState<DecodedFile | undefined>();

  const { isWorking, parsedTableOrError: parsedTableOrError } = useTableInputFileParser(
    name,
    inputFileData,
    settings
  );
  console.log(parsedTableOrError);

  const hasParsedData = !!parsedTableOrError && !isTranslatableError(parsedTableOrError);
  const hasDataParseFailed = !hasParsedData && !!parsedTableOrError;
  const disableFileInput = isWorking || ui.loadingButton.isAddUpdTableLoading;

  const clearInputFile = () => {
    setInputFileData(undefined);
    if (inputRef.current) inputRef.current.value = '';
  };

  // noinspection DuplicatedCode
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.preventDefault();

    const file = e.target.files?.[0];
    if (file) {
      arrayBufferDataReader(file, {
        onload: (result) => {
          if (result) {
            //Encode to ArrayBuffer if necessary
            const arrayBuffer = typeof result === 'string' ? encodeText(result) : result;
            setInputFileData(
              arrayBuffer
                ? {
                    name: file.name,
                    size: file.size,
                    type: file.type,
                    arrayBuffer
                  }
                : undefined
            );
          }
        },
        onerror: console.error
      });
    }
  };

  const handleTableTypeChange = (e: SelectChangeEvent<TableType>) => {
    clearInputFile();
    setTableType(e.target.value as TableType);
    setName((e.target.value as TableType) === TableType.ADDRESS ? AddressesMapKey : '');
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (!parsedTableOrError || isTranslatableError(parsedTableOrError)) return;
    if (tableType !== TableType.ADDRESS && name === AddressesMapKey) {
      dispatch(
        updateUi({
          snackbar: {
            show: true,
            message: $t({ id: 'addUpdTabularData.addressesMapKeyNameForbidden' })
          }
        })
      );
      return;
    }

    if (isUpdate) {
      const tableToUpdate = settings?.tabularData?.dataTables?.[name];
      console.log(settings);
      if (!tableToUpdate) {
        history.back(); // something bad happened
        return;
      }

      const newTable = parsedTableOrError.tableData;
      if (tableToUpdate.keyColumn !== newTable.keyColumn) {
        dispatch(
          updateUi({
            snackbar: {
              show: true,
              message: $t(
                { id: 'addUpdTabularData.keyColumnChanged' },
                {
                  old: <b key="kcb1">{tableToUpdate.keyColumn}</b>,
                  new: <b key="kcb2">{newTable.keyColumn}</b>
                }
              )
            }
          })
        );
        return;
      }

      const missingColumns = tableToUpdate.valueColumns.filter(
        (col) => !newTable.valueColumns.includes(col)
      );
      if (missingColumns.length) {
        dispatch(
          updateUi({
            snackbar: {
              show: true,
              message: $t(
                { id: 'addUpdTabularData.missingValueColumns' },
                { columns: <b key="mcb1">{missingColumns.join(', ')}</b> }
              )
            }
          })
        );
        return;
      }
    }

    const { keyColumn, valueColumns } = parsedTableOrError.tableData;
    dispatch(updateUi({ loadingButton: { isAddUpdTableLoading: true } }));

    getDeflated(JSON.stringify(parsedTableOrError))
      .then((deflatedData) =>
        dispatch(
          addOrUpdateTable({
            isUpdate,
            data: { domain, name, deflatedData, tableData: { keyColumn, valueColumns } }
          })
        )
      )
      .catch((err) => {
        dispatch(updateUiNoReset({ loadingButton: { isAddUpdTableLoading: false } }));
        console.log(err);
      });
  };

  const hasAddressMapTable = !!settings?.tabularData?.hasAddressMapTable;
  const tableTypeIsAddressMap = tableType === TableType.ADDRESS;
  const action = $t({ id: isUpdate ? 'update' : 'add' });
  return (
    <Box className={classes.root}>
      <Typography variant="h4" sx={text.title}>
        {$t({ id: 'addUpdTabularData.title' }, { action })}
      </Typography>

      <form onSubmit={handleSubmit}>
        {/* Name and file input data */}
        <Grid
          container
          spacing={2}
          justifyContent="flex-start"
          alignItems="center"
          className={classes.content}
        >
          <Grid xs={tableTypeIsAddressMap ? 12 : 6} lg={tableTypeIsAddressMap ? 12 : 6}>
            <FormControl fullWidth sx={tableTypeIsAddressMap ? { maxWidth: '300px' } : undefined}>
              <InputLabel id="tableType">{$t({ id: 'addUpdTabularData.tableType' })}</InputLabel>
              <Select
                fullWidth
                disabled={isUpdate}
                label={$t({ id: 'addUpdTabularData.tableType' })}
                id="tableType"
                value={tableType}
                onChange={handleTableTypeChange}
                MenuProps={select.getMenuProps()}
              >
                {Object.keys(TableType).map((type) => {
                  const hasAddrMap = hasAddressMapTable && type as TableType === TableType.ADDRESS;
                  return (
                    <MenuItem key={type} value={type} disabled={hasAddrMap}>
                      {$t({ id: `addUpdTabularData.${type}` }) +
                        (hasAddrMap && !isUpdate ? $t({ id: `addUpdTabularData.hasAddrMap` }) : '')}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          {!tableTypeIsAddressMap && (
            <Grid xs={6} lg={6}>
              <TextField
                fullWidth
                disabled={isUpdate}
                required={!tableTypeIsAddressMap}
                variant="outlined"
                id="name"
                name="name"
                type="text"
                InputLabelProps={{ shrink: true }}
                label={$t({ id: 'name' })}
                value={name}
                onChange={(e) => setName(e.target.value)}
                autoFocus
              />
            </Grid>
          )}
          {/* File Input */}
          <Grid container xs={12} alignItems="center">
            <Grid xs="auto">
              <Box
                component="input"
                disabled={disableFileInput}
                accept=".csv, .xls, .xlsx, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                sx={{ display: 'none' }}
                id="file-input-btn"
                type="file"
                onChange={handleFileChange}
                ref={inputRef}
              />
              <label htmlFor="file-input-btn">
                <Button
                  disabled={disableFileInput}
                  variant="contained"
                  component="span"
                  disableElevation
                  sx={{ minWidth: spacing(20) }}
                >
                  {$t({ id: 'uploadFile' })}
                </Button>
              </label>
            </Grid>

            <Grid container xs justifyContent="center">
              {isWorking ? (
                <Grid>
                  <CircularProgress sx={{ ml: 2 }} />
                </Grid>
              ) : (
                inputFileData && (
                  <Grid container alignItems="center" wrap="nowrap">
                    <Grid>
                      <Typography variant="body1" className={classes.marginLeft}>
                        {inputFileData.name}
                      </Typography>
                    </Grid>
                    <Grid>
                      <IconButton aria-label={'someMsg'} onClick={clearInputFile} size="large">
                        <ClearIcon sx={{ color: 'error.main' }} />
                      </IconButton>
                    </Grid>
                  </Grid>
                )
              )}
            </Grid>
          </Grid>
          {hasDataParseFailed ? (
            <Grid xs={12} sx={{ mt: 2 }}>
              <Typography sx={{ color: 'error.main' }}>
                {$t(
                  { id: 'failedParsingFile' },
                  { error: $t({ id: String(parsedTableOrError.msgCode) }, parsedTableOrError.args) }
                )}
              </Typography>
            </Grid>
          ) : (
            hasParsedData && (
              <Box sx={{ mt: 1 }}>
                <Grid xs={12}>
                  <Typography sx={{ color: 'success.main' }}>
                    {$t(
                      { id: 'addUpdTabularData.parsedDataInfoTitle' },
                      { count: Object.keys(parsedTableOrError).length }
                    )}
                  </Typography>
                </Grid>
                <Grid xs={12}>
                  <Typography sx={{ color: 'success.main' }}>
                    {$t(
                      { id: 'addUpdTabularData.parsedDataKeyInfo' },
                      {
                        keyColumn: <b key="b1">{parsedTableOrError.tableData.keyColumn}</b>
                      }
                    )}
                  </Typography>
                  <Typography sx={{ color: 'success.main' }}>
                    {$t(
                      { id: 'addUpdTabularData.parsedDataValuesInfo' },
                      {
                        valueColumns: (
                          <b key="b2">{`[${parsedTableOrError.tableData.valueColumns.join(
                            ', '
                          )}]`}</b>
                        )
                      }
                    )}
                  </Typography>
                  <Typography sx={{ color: 'success.main' }}>
                    {$t(
                      { id: 'addUpdTabularData.parsedDataRowsInfo' },
                      {
                        rows: <b key="b3"> {Object.keys(parsedTableOrError.data).length}</b>
                      }
                    )}
                  </Typography>
                </Grid>
              </Box>
            )
          )}
          {/* Save Button */}
          {hasParsedData && (
            <Grid xs={12}>
              <LoadingButton
                isLoading={ui.loadingButton.isAddUpdTableLoading}
                content={action}
                context="form"
                type="submit"
              />
            </Grid>
          )}
        </Grid>
      </form>
    </Box>
  );
};

export default AddOrUpdateTabularData;
