import { getDoc } from 'firebase/firestore';
import { getSessionDoc } from 'flyid-core/dist/Util/database';
import { useMemo, useState } from 'react';
import { useDocumentDataOnce } from 'react-firebase-hooks/firestore';
import { Navigate, useParams } from 'react-router-dom';
import { buildDocumentRef } from 'src/firebase/firestore';
import { useAppSelector } from 'src/hooks/reduxHooks';
import { useClosestSettings } from 'src/hooks/useClosestSettings';
import { selectSettings } from 'src/redux/selectors/dataSelectors';
import { selectCurrentUserProfile } from 'src/redux/selectors/userSelectors';
import { selectTargetCompany } from 'src/redux/selectors/globalSelectors';
import { getInflated } from 'src/workers/dataWorkerApi';
import SessionReviewLayout from './SessionReviewLayout';
import { InflatedAddressesData } from 'flyid-core/dist/Database/Models';
import { ReviewableAddresses } from 'flyid-core/dist/Database/Models';
import { LogicalOperator } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/LogicalOperator';
import LoadingCircle from '../../widgets/LoadingCircle';
import { useOnceEffect } from 'flyid-ui-components/dist/hooks';

export type CheckFieldsDatalist = { fieldName: string; isRequired: boolean }[];

type oldReviewableAddresses = { address: string; reason: string }[];

const SessionReview: React.FC = () => {
  const { domain, session } = useParams<SessionMatchParams>();
  // Fallback to home if domain or session is missing
  if (!domain || !session) return <Navigate replace to="/" />;

  // Getting user profile and settings
  const { profile, mostRecentSettings, company } = useAppSelector((state) => ({
    company: selectTargetCompany(state),
    profile: selectCurrentUserProfile(state),
    mostRecentSettings: selectSettings(state, domain)
  }));

  // Getting session data
  const [sessionData, loadingSessionData] = useDocumentDataOnce(
    profile && buildDocumentRef(getSessionDoc(company!, domain, session))
  );

  const [, settings] = useClosestSettings(
    company,
    domain,
    sessionData?.startTime,
    mostRecentSettings
  );

  // Checking for logical operators
  // const [logicalOperator, setLocagicalOperator] = useState<LogicalOperator>(LogicalOperator.NONE);

  // Getting the fields that are going to be checked
  const fields = useMemo(() => {
    let checkFields: CheckFieldsDatalist = [];
    const checkFieldsDatalist: CheckFieldsDatalist[] = [];
    let resultFieldNull = '';

    if (settings !== null && settings !== undefined) {
      // Getting Data Fields and Manual Input Fields
      Object.values(settings.processFlow.labelDesigns).forEach((ld) => {
        ld.barcodePatterns.forEach((bp) => {
          bp.dataFields.forEach((df) => {
            if (df.type === 'data') {
              checkFields.push({
                fieldName: df.name,
                isRequired: bp.isRequired
              });
            }
          });
          // Getting Manual Input Fields
          Object.values(settings.processFlow.manualInputFields).forEach((mif) =>
            checkFields.push({ fieldName: mif.field, isRequired: mif.mandatory })
          );
        });
      });

      checkFieldsDatalist.push(checkFields);
      checkFields = [];

      // Getting Result Fields
      resultFieldNull = settings.fieldSettings.resultFields.null;

      // Setting process flow logical operator
      // const logicalBlocks = settings.processFlow.logicalBlocks;

      // if (Object.keys(logicalBlocks).length === 1) {
      //   Object.entries(logicalBlocks).map(([_, value]) => {
      //     setLocagicalOperator(value.operation); // todo não usar set state em loop
      //   });
      // }
    }

    return {
      checkFields: checkFieldsDatalist,
      resultFieldNull
    };
  }, [settings]);

  const [totalAddresses, setTotalAddresses] = useState<InflatedAddressesData>({});
  const [reviewableAddresses, setReviewableAddresses] = useState<InflatedAddressesData>({});

  // Filtering which addresses are going to be reviewed
  const getReviewableAddresses: () => [boolean, undefined] = () => {
    if (!profile || !company) return [false, undefined];

    const fetchAndPrepare = async () => {
      const docRef = buildDocumentRef(getSessionDoc(company, domain, session));
      const docSnap = await getDoc(docRef);

      const addresses = docSnap.exists() ? docSnap.data().addresses : '';
      const inflatedAddresses: InflatedAddressesData = JSON.parse(
        await getInflated(addresses)
      ) as InflatedAddressesData;

      // rawData was _reviewableAddresses
      const rawData = docSnap.exists()
        ? docSnap.data().reviewableAddresses !== undefined
          ? (docSnap.data().reviewableAddresses as ReviewableAddresses)
          : {}
        : {};

      // This part of the code only exists to transform inconsistent data
      const _reviewableAddresses = Array.isArray(rawData)
        ? (rawData as oldReviewableAddresses).reduce(
            (acc, data) => {
              acc[data.address] = [data.reason];
              return acc;
            },
            {} as Record<string, string[]>
          )
        : rawData;

      setTotalAddresses(inflatedAddresses);

      if (reviewableAddresses.length) {
        setReviewableAddresses({});
      }

      Object.entries(inflatedAddresses).forEach(([key, values]) => {
        Object.keys(_reviewableAddresses).forEach((address) => {
          if (key === address) {
            setReviewableAddresses((prev) => ({
              ...prev,
              [address]: values,
              ...reviewableAddresses
            }));
          }
        });
      });
    };
    fetchAndPrepare().catch(console.error);

    return [true, undefined];
  };

  useOnceEffect(() => {
    return getReviewableAddresses();
  }, [profile, company]);

  return loadingSessionData ? (
    <LoadingCircle />
  ) : (
    <SessionReviewLayout
      reviewableAddresses={reviewableAddresses}
      totalAddresses={totalAddresses}
      checkFields={fields.checkFields}
      settings={settings}
      resultFieldNull={fields.resultFieldNull}
      logicalBlockVerification={LogicalOperator.NONE}
    />
  );
};

export default SessionReview;
