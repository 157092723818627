import { getRedirectResult, SAMLAuthProvider, signInWithRedirect, User } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from 'src/firebase/firebase';
import { useAppSelector } from 'src/hooks/reduxHooks';

// const subdomainRegex = new RegExp('https?://([^.]+)\\.flyid(-.*)?\\.panoramaid\\.com\\.br');
const subdomainRegex = new RegExp('https?://([^.]+)\\.localhost*');

enum RedirectionState {
  /** Just initialized hook */
  INIT,
  /** (SSO) Checking whether the user has already been logged in */
  SSO_CHECKING_CHECK_AUTH_STATE,
  /** (SSO) If not logged in, redirect to login auth provider login page */
  SSO_SHOW_AUTH_PROVIDER_LOGIN_PAGE,
  /** (SSO) The user has been redirected/popup has been shown */
  SSO_DONE_SHOWING_AUTH_PROVIDER_LOGIN_PAGE,
  /** (SSO) Something went bad during login procedure */
  ERROR,
  /** Either auth process has completed or is not using an auth provider. */
  DONE
}
// This state has to be global across all hook usages.
let redirectionState = RedirectionState.INIT;

export const useAuth = (): {
  user?: User | null;
  loading: boolean;
  error?: Error;
  usingSSO: boolean;
} => {
  const { providers, areAuthProvidersLoaded } = useAppSelector(
    ({ firestore }) => firestore.authProviders
  );

  // These two states simulate redirectionState as a state change.
  const [effectTrigger, setEffectTrigger] = useState(0);
  const setRedirectionState = (state: RedirectionState) => {
    redirectionState = state;
    setEffectTrigger(Math.random());
  };

  const [authProviderError, setAuthProviderError] = useState<Error | undefined>(undefined);
  const [user, authLoading, authError] = useAuthState(auth);
  // Keep loading while all auth data is not ready or redirect has been triggered
  const loading = !areAuthProvidersLoaded || authLoading;
  const error = authProviderError ?? authError;

  // COR Uncomment to run mock SAML auth
  // const url = 'http://auth_provider_tests.flyid.panoramaid.com.br';
  const url = window.location.href;
  const subDomain = url.match(subdomainRegex)?.[1];
  const provider = subDomain && subDomain !== 'v2' ? providers?.[subDomain]?.provider : undefined;
  const usingSSO = !!provider;
  // After SSO auth has been completed, user will be set to the authenticated user
  useEffect(() => {
    if (!loading) {
      if (!usingSSO) {
        // If not using SSO, set RedirectionState to done, if not yet.
        if (redirectionState !== RedirectionState.DONE) setRedirectionState(RedirectionState.DONE);
      }
      // Otherwise, evaluate redirection/popup showing
      else if (!user && !error) {
        switch (redirectionState) {
          // Initially check for user authentication state
          case RedirectionState.INIT:
            console.log('Checking auth state');
            setRedirectionState(RedirectionState.SSO_CHECKING_CHECK_AUTH_STATE);
            getRedirectResult(auth)
              .then((result) => {
                console.log('Redirect result is ', result);
                setRedirectionState(
                  result === null
                    ? // If result is null, authentication has not been triggered or failed,
                      // we should  redirect to the auth provider login page or show it as a popup.
                      RedirectionState.SSO_SHOW_AUTH_PROVIDER_LOGIN_PAGE
                    : // Otherwise authentication is completed
                      RedirectionState.DONE
                );
              })
              .catch((err: Error) => {
                const _err =
                  err instanceof Error
                    ? err
                    : Error(typeof err === 'string' ? (err as string) : String(err));
                console.error(`Authentication failed: ${_err.message}`);
                setRedirectionState(RedirectionState.ERROR);
                setAuthProviderError(_err);
              });
            break;
          case RedirectionState.SSO_SHOW_AUTH_PROVIDER_LOGIN_PAGE:
            // Avoids multiple redirects
            setRedirectionState(RedirectionState.SSO_DONE_SHOWING_AUTH_PROVIDER_LOGIN_PAGE);
            const authProvider = new SAMLAuthProvider(provider);
            try {
              signInWithRedirect(auth, authProvider).catch(console.error);
              // Use popup to test SSO when developing, since signInWithRedirect expects
              // domain to match firebase config's authDomain, which won't happen with localhost domain.
              // signInWithPopup(auth, authProvider).catch(console.error);
            } catch (err: unknown) {
              console.error((err as Error).message);
            }
            break;
        }
      } // Already logged in
      else if (!error) {
        if (redirectionState !== RedirectionState.DONE) setRedirectionState(RedirectionState.DONE);
      } // Logged in with error?
      else if (redirectionState !== RedirectionState.ERROR) {
        setRedirectionState(RedirectionState.ERROR);
        console.error('Authentication somehow failed!', error);
      }
    }
  }, [redirectionState, loading, user, error, effectTrigger]);

  if (!usingSSO) return { user, loading, error: authError, usingSSO };

  return { user, loading, error, usingSSO };
};
