import { cx } from '@emotion/css';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Avatar,
  Box,
  Collapse,
  Container,
  Fab,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  MenuItem,
  Pagination,
  Select,
  SelectChangeEvent,
  Skeleton,
  Tooltip,
  Typography
} from '@mui/material';
import { PermissionsKeys, UserPublic } from 'flyid-core/dist/Database/Models/User';
import { isEmpty, isObject } from 'lodash';
import { Fragment, useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { getNoProfileImg } from 'src/assets/assets';
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks';
import useStateReducer from 'src/hooks/useStateReducer';
import { MyDialogState } from 'src/redux/reducers/uiReducer';
import { selectTargetCompany } from 'src/redux/selectors/globalSelectors';
import {
  selectCurrentUserProfile,
  selectUserProfiles,
  selectUserProfileThumbs
} from 'src/redux/selectors/userSelectors';
import { appMakeStyles, useAppTheme } from 'src/theme/theme';
import { isKeyUserProf } from 'src/util/helpers/user';
import { Actions } from '../../redux/actions/actionsHandler';
import { fetchProfileImages, RemoveUserParams } from '../../redux/actions/userActions';
import { updateUi } from '../../redux/reducers/uiReducer';
import { groupUsersByParent } from '../utils/GroupBy';

const useStyles = appMakeStyles((theme) => ({
  container: { ...theme.resizableContainer(2), marginLeft: 0 },
  listContainer: {
    maxWidth: theme.spacing(75),
    marginBottom: theme.spacing(2)
  },
  listItem: {
    paddingRight: theme.spacing(15),
    margin: theme.spacing(2)
  },
  itemText: {
    margin: theme.spacing(1, 1)
  },
  avatar: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    padding: theme.spacing(0.5)
  },
  skeletonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignContent: 'flex-start'
  },
  skeletonItemText: {
    minWidth: theme.spacing(30),
    margin: theme.spacing(0, 1),
    height: theme.spacing(8)
  },
  paginationContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(4)
  },
  pageSizeSelectorContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  childListItem: {
    maxWidth: theme.spacing(86),
    paddingRight: theme.spacing(20),
    margin: theme.spacing(2, 6)
  }
}));

type CustomPageState = {
  data: string[];
  page: number;
  pageSize: number;
};

const pilotKey = 'pilot';
const checkerKey = 'checker';
const assistantKey = 'assistant';
const moderatorKey = 'moderator';
const permissionTypes = [pilotKey, checkerKey, assistantKey, moderatorKey];

/**
 * Some lines have the comment "istanbul ignore next",
 * this is related to the Instanbul library for testing
 * support. It doesn't affect the code itself, only the
 * test coverage for this component.
 */

const ManageUsers: React.FC = () => {
  const classes = useStyles();
  const theme = useAppTheme();
  const dispatch = useAppDispatch();
  const { $t } = useIntl();
  const noProfileImage = getNoProfileImg();

  const { companyProfiles, profilePics, myProfile, moderatorUid, company } = useAppSelector(
    (s) => ({
      company: selectTargetCompany(s),
      myProfile: selectCurrentUserProfile(s),
      companyProfiles: selectUserProfiles(s),
      profilePics: selectUserProfileThumbs(s),
      moderatorUid: s.user.uid
    })
  );

  const currentUserIsKeyUser = isKeyUserProf(myProfile);

  const usersByParent = useMemo(() => {
    if (companyProfiles) {
      const users = groupUsersByParent(companyProfiles);
      return users;
    }
    return;
  }, [companyProfiles, company]);

  useEffect(() => {
    if (companyProfiles && company) {
      // Child user profiles to be fetched
      dispatch(
        fetchProfileImages({
          profileUids: Object.keys(companyProfiles),
          company
        })
      );
    }
  }, [companyProfiles, company]);

  const [openChildrenList, setOpenChildrenList] = useStateReducer({});
  const toggleOpenChildrenList = (uid: string) =>
    setOpenChildrenList({ [uid]: !Boolean(openChildrenList[uid]) });

  const renderUser = (uid: string, profile: UserPublic, isModerator: boolean) => {
    const profileImageThumb = profilePics[uid];
    const labelId = `label${uid}`;

    const permissions: string[] = [];
    permissionTypes.forEach((permission) => {
      if (profile[permission]) {
        permissions.push($t({ id: permission }));
      }
    });

    const hasChildren = isModerator && Boolean(usersByParent?.[uid]?.length);
    return (
      <ListItem
        key={uid}
        alignItems="flex-start"
        className={isModerator ? classes.listItem : classes.childListItem}
        onClick={
          hasChildren ? /* istanbul ignore next */ () => toggleOpenChildrenList(uid) : undefined
        }
      >
        <ListItemAvatar>
          {profileImageThumb?.isLoaded ? (
            <Avatar
              data-testid="user-avatar-ku-view"
              alt={$t({ id: 'altUsrProfImage' })}
              src={profileImageThumb.src || noProfileImage}
              className={classes.avatar}
            />
          ) : (
            <Skeleton
              data-testid="user-avatar-skeleton-ku-view"
              variant="circular"
              className={classes.avatar}
              animation="wave"
            />
          )}
        </ListItemAvatar>
        <ListItemText
          data-testid="user-data-ku-view"
          id={labelId}
          className={classes.itemText}
          primary={`${profile.firstName} ${profile.lastName}`}
          secondary={$t(
            { id: 'manUsr.userDesc' },
            {
              domains: profile?.authDomains.join(', ') ?? /* istanbul ignore next */ '',
              nl: <br key={`${uid}nl`} />,
              perm: permissions?.join(', ') ?? /* istanbul ignore next */ ''
            }
          )}
        />
        <ListItemSecondaryAction
          sx={{ marginLeft: theme.spacing(2) }}
          data-testid="list-item-secondary"
        >
          <Tooltip title={$t({ id: isModerator ? 'nav.profile' : 'edit' })}>
            <IconButton
              data-testid="edit-user-ku-view"
              component={Link}
              to={`/profile/${uid}`}
              style={{ color: theme.palette.info.main }}
            >
              {isModerator ? <VisibilityIcon /> : <EditIcon />}
            </IconButton>
          </Tooltip>
          {!isModerator ? (
            <Tooltip title={$t({ id: 'remove' })}>
              <IconButton
                data-testid="remove-user-ku-view"
                style={{ color: theme.palette.error.main }}
                onClick={() => showRemoveUserDialogConfirmation(uid)}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip
              title={
                hasChildren
                  ? $t({ id: openChildrenList[uid] ? 'close' : 'expand' })
                  : $t({ id: 'manUsr.parentHasNoUsers' })
              }
            >
              <IconButton
                style={{
                  color: hasChildren ? theme.palette.grey['500'] : theme.palette.grey['300']
                }}
                onClick={hasChildren ? () => toggleOpenChildrenList(uid) : undefined}
              >
                {openChildrenList[uid] ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon data-testid="expand-icon" />
                )}
              </IconButton>
            </Tooltip>
          )}
        </ListItemSecondaryAction>
      </ListItem>
    );
  };

  const renderCompanyUsers = useCallback(() => {
    if (usersByParent) {
      const moderators = usersByParent.moderators;
      return (
        <>
          {currentUserIsKeyUser ? (
            <List className={classes.listContainer} key={`users${company ?? 'companyNotSelected'}`}>
              {!isEmpty(moderators) ? (
                moderators.map(([parentUid, profile]) => (
                  <Fragment key={parentUid}>
                    {renderUser(parentUid, profile, true)}
                    <Collapse
                      in={Boolean(openChildrenList[parentUid])}
                      timeout="auto"
                      unmountOnExit
                    >
                      {usersByParent[parentUid]?.map(([uid, _profile]) =>
                        renderUser(uid, _profile, false)
                      )}
                    </Collapse>
                  </Fragment>
                ))
              ) : (
                <ListItem
                  data-testid="empty-users-ku"
                  key="emptyUsers"
                  alignItems="flex-start"
                  className={classes.childListItem}
                >
                  <ListItemText
                    id="emptyUsers"
                    className={classes.itemText}
                    primary={$t({ id: 'manUsr.noUsers' })}
                  />
                </ListItem>
              )}
            </List>
          ) : /* istanbul ignore next */ null}
        </>
      );
    }
    /* istanbul ignore next */
    return;
  }, [currentUserIsKeyUser, usersByParent, profilePics, openChildrenList, company]);

  /* Normal manage users configuration */
  const [pageState, setPageState] = useStateReducer<CustomPageState>({
    data: [],
    page: 1,
    pageSize: 2
  });

  const resetPageState = () => {
    /* istanbul ignore next */
    setPageState({ data: [], page: 1, pageSize: 2 });
  };

  const showRemoveUserDialogConfirmation = (uid: string) => {
    const userData = companyProfiles?.[uid];
    if (!userData) /* istanbul ignore next */ return;

    const actionData: RemoveUserParams = { data: { uid } };

    // If user is key user, the parent uid of target user is added to action data
    // If key user try to delete a moderator, an error will be shown
    if (currentUserIsKeyUser) {
      if (!userData[moderatorKey]) {
        actionData.parentUid = userData.parent;
      } else {
        /* istanbul ignore next */
        dispatch(
          updateUi({
            snackbar: {
              message: $t({ id: 'manUsr.remMod' }),
              severity: 'error',
              show: true
            }
          })
        );
        /* istanbul ignore next */
        return;
      }
    }

    dispatch(
      updateUi({
        dialog: new MyDialogState({
          title: $t({ id: 'manUsr.remUserTitle' }),
          message: $t(
            { id: 'manUsr.remUserMsg' },
            {
              name: <b key="mub0">{`${userData.firstName} ${userData.lastName}`}</b>,
              nl: <br key="munl0" />
            }
          ),
          show: true
        }).setConfirmAction(Actions.REMOVE_USER, actionData)
      })
    );
  };

  const handlePageSizeChange = (event: SelectChangeEvent<number>) => {
    /* istanbul ignore next */
    setPageState({ pageSize: event.target.value as number });
  };

  const handlePaginationChange = (event, value: number) => {
    /* istanbul ignore next */
    setPageState({ page: value });
  };

  useEffect(() => {
    if (isObject(companyProfiles) && myProfile) {
      setPageState({
        data: Object.keys(companyProfiles).slice(
          pageState.pageSize * (pageState.page - 1),
          pageState.pageSize * pageState.page
        )
      });
    }
  }, [companyProfiles, pageState.page, pageState.pageSize]);

  useEffect(() => {
    if (pageState.data.length) /* istanbul ignore next */ resetPageState();
  }, [companyProfiles]);

  return (
    <>
      {myProfile?.keyUser ? (
        <Container data-testid="manage-users-ku-container" className={classes.container}>
          <Typography variant="h4" sx={theme.text.title}>
            {$t({ id: 'manUsr.title' })}
          </Typography>
          {currentUserIsKeyUser && company === '' ? (
            <Typography variant="subtitle1" sx={theme.text.subtitle}>
              {$t({ id: 'keyUserCompany.warning' })}
            </Typography>
          ) : (
            <Typography variant="subtitle1" sx={theme.text.subtitle}>
              {$t({ id: 'manUsr.subtitle' })}
            </Typography>
          )}

          {companyProfiles ? (
            <Box>{renderCompanyUsers()}</Box>
          ) : (
            <>
              {[0, 1, 2].map((i) => (
                <Container
                  className={cx(classes.listItem, classes.skeletonContainer)}
                  key={`skel${i}`}
                >
                  <Skeleton
                    data-testid="users-skeletons-ku-view"
                    variant="circular"
                    animation="wave"
                    className={classes.avatar}
                  />
                  <Skeleton
                    data-testid="users-skeletons-ku-view"
                    variant="text"
                    animation="wave"
                    className={classes.skeletonItemText}
                  />
                </Container>
              ))}
            </>
          )}
          <List>
            <ListItem>
              {currentUserIsKeyUser && company === '' ? null : (
                <Fab
                  data-testid="add-user-btn-ku"
                  variant="extended"
                  size="medium"
                  color="secondary"
                  aria-label="add"
                  component={Link}
                  to="/adduser"
                >
                  <AddCircleIcon style={{ marginRight: theme.spacing(1) }} />
                  {$t({ id: 'manUsr.addUsr' })}
                </Fab>
              )}
            </ListItem>
          </List>
        </Container>
      ) : (
        <Container data-testid="manage-users-mod-container" className={classes.container}>
          <Typography variant="h4" sx={theme.text.title}>
            {$t({ id: 'manUsr.title' })}
          </Typography>
          <Typography variant="subtitle1" sx={theme.text.subtitle}>
            {$t({ id: 'manUsr.subtitle' })}
          </Typography>
          <List
            className={classes.listContainer}
            subheader={
              <ListSubheader sx={{ backgroundColor: 'background.default' }}>
                {$t({ id: 'manUsr.users' })}
              </ListSubheader>
            }
          >
            {pageState.data && companyProfiles ? (
              !isEmpty(pageState.data) ? (
                pageState.data.map((uid) => {
                  const profile = companyProfiles[uid];
                  const profileThumbData = profilePics[uid];
                  const labelId = `label${uid}`;

                  if (!profile || moderatorUid === uid) {
                    /* istanbul ignore next */
                    return null;
                  }

                  const permissions: string[] = [];
                  PermissionsKeys.forEach((permission) => {
                    if (profile && profile[permission]) {
                      permissions.push($t({ id: permission }));
                    }
                  });

                  return (
                    <ListItem
                      data-testid="user-mod-view"
                      key={uid}
                      alignItems="flex-start"
                      className={classes.listItem}
                    >
                      <ListItemAvatar>
                        {profileThumbData?.isLoaded ? (
                          <Avatar
                            data-testid="user-avatar-mod-view"
                            alt={$t({ id: 'altUserProfileImage' })}
                            src={profileThumbData.src || noProfileImage}
                            className={classes.avatar}
                          />
                        ) : (
                          <Skeleton
                            data-testid="user-avatar-skeleton-mod-view"
                            variant="circular"
                            className={classes.avatar}
                            animation="wave"
                          />
                        )}
                      </ListItemAvatar>
                      <ListItemText
                        data-testid="user-data-mod-view"
                        id={labelId}
                        className={classes.itemText}
                        primary={`${profile.firstName} ${profile.lastName}`}
                        secondary={$t(
                          { id: 'manUsr.userDesc' },
                          {
                            domains: profile.authDomains ? profile.authDomains.join(', ') : '',
                            nl: <br key={`${uid}nl`} />,
                            perm: permissions ? permissions.join(', ') : ''
                          }
                        )}
                      />
                      <ListItemSecondaryAction sx={{ pr: -3, mr: -3 }}>
                        <Tooltip title="Edit">
                          <IconButton
                            data-testid="edit-user-mod-view"
                            component={Link}
                            to={`/profile/${uid}`}
                            edge="start"
                            aria-label="Edit"
                            sx={{ color: 'info.main' }}
                            size="large"
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={$t({ id: 'remove' })}>
                          <IconButton
                            data-testid="remove-user-mod-view"
                            edge="end"
                            aria-label={$t({ id: 'remove' })}
                            sx={{ color: 'error.main' }}
                            onClick={() => showRemoveUserDialogConfirmation(uid)}
                            size="large"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })
              ) : (
                <ListItem
                  data-testid="empty-users-mod"
                  key="emptyUsers"
                  alignItems="flex-start"
                  className={classes.listItem}
                >
                  <ListItemText
                    id="emptyUsers"
                    className={classes.itemText}
                    primary={$t({ id: 'manUsr.noUsers' })}
                  />
                </ListItem>
              )
            ) : (
              [0, 1, 2].map((i) => (
                <Container
                  className={cx(classes.listItem, classes.skeletonContainer)}
                  key={`skel${i}`}
                >
                  <Skeleton
                    data-testid="user-skeletons-mod-view"
                    variant="circular"
                    animation="wave"
                    className={classes.avatar}
                  />
                  <Skeleton
                    data-testid="user-skeletons-mod-view"
                    variant="text"
                    animation="wave"
                    className={classes.skeletonItemText}
                  />
                </Container>
              ))
            )}

            {companyProfiles ? (
              <Box className={classes.paginationContainer}>
                <Pagination
                  count={Math.ceil(Object.keys(companyProfiles).length / pageState.pageSize)}
                  color="primary"
                  page={pageState.page}
                  onChange={handlePaginationChange}
                  size="large"
                />
                <Box className={classes.pageSizeSelectorContainer}>
                  <Typography>Rows per page: </Typography>
                  <FormControl sx={{ m: 1, minWidth: 60 }} size="small">
                    <InputLabel id="page-size-select-label"></InputLabel>
                    <Select
                      labelId="page-size-select-label"
                      id="page-size-select"
                      value={pageState.pageSize}
                      label=""
                      onChange={handlePageSizeChange}
                    >
                      <MenuItem value={2}>02</MenuItem>
                      <MenuItem value={4}>04</MenuItem>
                      <MenuItem value={8}>08</MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Box>
            ) : null}

            <ListItem>
              <Fab
                data-testid="add-user-btn-moderator"
                variant="extended"
                size="medium"
                color="secondary"
                aria-label="add"
                component={Link}
                to="/adduser"
              >
                <AddCircleIcon sx={{ mr: 1 }} />
                {$t({ id: 'manUsr.addUsr' })}
              </Fab>
            </ListItem>
          </List>
        </Container>
      )}
    </>
  );
};

export default ManageUsers;
