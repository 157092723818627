import { License } from 'flyid-core/dist/Database/Models/License';
import { ManualInputField } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/ManualInputField';
import { parseDateFromTimestamp } from 'flyid-core/dist/Util/time';
import { IntlShape } from 'react-intl';

type VariadicFunction = (...args: unknown[]) => void;

/** Returns the function name */
export function nameOf(f: VariadicFunction) {
  f.toString().replace(/[ |()=>]/g, '');
}

/**
 * Sorts a Map by key values and returns a new sorted Map.
 */
export function getSortedMap<T>(map: Map<string, T>) {
  return new Map(Array.from(map).sort((a, b) => String(a[0]).localeCompare(b[0])));
}

export function buildLicenseDescription(
  license: License,
  id: string,
  intl: IntlShape,
  cropLimit?: number
) {
  const _id = cropLimit ? id.substring(0, cropLimit) : id;
  return `${license.description} - S/N: ${_id}${cropLimit ? '...' : ''} - exp: ${intl.formatDate(
    parseDateFromTimestamp(license.expDate)
  )}`;
}

export function buildShortLicenseDescription(license: License, id: string, cropLimit = 4) {
  const _id = cropLimit ? id.substring(0, cropLimit) : id;
  return `${license.description} - ${_id}${cropLimit ? '...' : ''}`;
}

export function buildMifDescription(mif: ManualInputField, intl: IntlShape) {
  let desc = mif.field;
  if (mif.useCheckField) {
    desc = desc.concat(` | ${intl.$t({ id: 'checkField' })}: ${mif.checkField}`);
    if (mif.maxAttempts > 0) {
      desc = desc.concat(` | ${intl.$t({ id: 'dSett.repeat' }, { maxAttempts: mif.maxAttempts })}`);
    }
  }
  return desc;
}

/** Fallback in case navigator.clipboard is not available */
export function fallbackCopyTextToClipboard(text: string, callback: () => void) {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand('copy');
    if (successful && callback) {
      callback();
    }
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}

export function copyTextToClipboard(text: string, callback: () => void) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text, callback);
    return;
  }
  navigator.clipboard.writeText(text).then(
    function () {
      if (callback) callback();
    },
    function (err) {
      console.error('Async: Could not copy text: ', err);
    }
  );
}

/**
 * Get object keys typed as key of T.
 * Should only be used when we are sure the object contains only the desired keys (does not inherite others).
 * @See https://stackoverflow.com/a/55012175 and https://stackoverflow.com/a/67452316
 */
export const typedKeysOf = <T extends object>(obj: T) => Object.keys(obj) as Array<keyof T>;
