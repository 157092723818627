import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Button,
  Container,
  Fab,
  Grid,
  IconButton,
  lighten,
  MenuItem,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import {
  Case,
  CaseData,
  caseEquals,
  isElseCase
} from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/Case';
import { getPureId, NodeType } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/Elements';
import { LabelDesign } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/LabelDesign';
import {
  isLogicalOperator,
  LogicalOperator,
  LogicalOperatorValues
} from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/LogicalOperator';
import { TwoWayMap } from 'flyid-core/dist/Util/structs';
import React, { ChangeEvent, FormEvent, useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useAppDispatch } from 'src/hooks/reduxHooks';
import { useAppReactFlow } from 'src/hooks/useAppReactFlow';
import { updateUi } from 'src/redux/reducers/uiReducer';
import { appMakeStyles, useAppTheme } from 'src/theme/theme';
import { buildCaseDataDescription, buildOperatorDescription } from 'src/util/processFlow/cases';
import { Nilable } from 'tsdef';

const myWidth = 900;

const useStyles = appMakeStyles(({ spacing, palette, other }) => ({
  root: { padding: spacing(1) },
  gridContainer: {
    width: myWidth
  },
  labelInputContainer: {
    marginTop: spacing(2),
    marginBottom: spacing(2)
  },
  title: {
    color: other.grey.dark,
    marginBottom: spacing(2)
  },
  input: {
    marginBottom: spacing(2)
  },
  tooltip: {
    alignSelf: 'center'
  },
  formControl: {
    margin: spacing(0, 1),
    minWidth: '100%'
  },
  extendedButtonIcon: {
    marginRight: spacing(1)
  },
  header: {
    color: other.grey.dark,
    alignSelf: 'center'
  },
  gridItem: {
    alignSelf: 'center',
    paddingLeft: spacing(2)
  },
  fieldModifierTop: {
    backgroundColor: lighten(palette.primary.dark, 0.85),
    borderRadius: '15px 15px 0 0',
    minHeight: spacing(6)
  },
  fieldItemLight: {
    backgroundColor: lighten(palette.primary.dark, 0.96)
  },
  fieldItemDark: {
    backgroundColor: lighten(palette.primary.dark, 0.93)
  },
  fieldModifier: {
    backgroundColor: lighten(palette.primary.dark, 0.85)
  },
  fieldModifierBottom: {
    backgroundColor: lighten(palette.primary.dark, 0.85),
    borderRadius: '0 0 15px 15px',
    padding: spacing(1)
  },
  addFieldGridItem: {
    paddingLeft: spacing(1),
    minHeight: spacing(6)
  }
}));

const initialSelection = [LogicalOperator.NONE];
type Props = {
  casesData: CaseData[];
  availableConditions: Map<string, Case>;
  conditionsAndOpposites: TwoWayMap<string, string>;
  elseCaseDescriptor: string;
  onCasesChange: (c: CaseData[]) => void;
  handleSubmit: (e: FormEvent) => void;
};

const CaseEditor: React.FC<Props> = (props) => {
  const { spacing, other, palette } = useAppTheme();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const { $t } = intl;

  const { getNodes } = useAppReactFlow();
  const [selection, setSelection] = useState<string[]>([...initialSelection]);

  const currConditionsExist = Boolean(props.casesData.some((c) => c.length));

  const handleSelectionChange = (index: number) => (e: ChangeEvent<HTMLTextAreaElement>) => {
    let data = [...selection];
    if (index === data.length - 1 && (e.target.value as LogicalOperator) !== LogicalOperator.NONE) {
      // Last item changed to something other than 'none', add next selection box
      data.splice(index, 0, e.target.value);
    } else {
      // If item other than last changed to none, remove all selection boxes to the right of it
      if ((e.target.value as LogicalOperator) === LogicalOperator.NONE)
        data = data.slice(0, index + 1);
      // Record the change
      data[index] = e.target.value;
    }

    setSelection(data);
  };

  const handleAddCondition = useCallback(() => {
    if (!selection.length) return;

    const newCaseData: CaseData = selection
      .map((c) => (isLogicalOperator(c) ? c : props.availableConditions.get(c)))
      .filter((c): c is Case | LogicalOperator => Boolean(c && c !== LogicalOperator.NONE));

    // Not looking up for all cases, just the identically equal, since there could be a zillion
    // combinations that lead to the same boolean expression
    const conditionExists = props.casesData.some(
      (caseData) =>
        caseData.length === newCaseData.length && // Different lengths = different cases
        caseData.every((c, index) => {
          const caseIsEqual = isLogicalOperator(c)
            ? c === newCaseData[index]
            : caseEquals(c, newCaseData[index] as Case);
          // if (caseIsEqual) console.log(index, c);
          return caseIsEqual;
        })
    );

    if (conditionExists) {
      dispatch(
        updateUi({
          snackbar: {
            message: $t({ id: 'caseEditor.caseAlreadyExists' }),
            severity: 'error',
            show: true
          }
        })
      );
      return;
    }

    // Force 'else' to be the last case
    const elseIndex = props.casesData.findIndex((cd) => cd.length === 1 && isElseCase(cd[0]));
    if (elseIndex !== -1) {
      const currentCases = [...props.casesData];
      currentCases.splice(elseIndex, 0, newCaseData);
      props.onCasesChange(currentCases);
    } else props.onCasesChange([...props.casesData, newCaseData]);

    setSelection([...initialSelection]);
  }, [props, selection]);

  const handleRemoveCase = (index) => {
    props.onCasesChange(props.casesData.filter((_, idx) => idx !== index));
  };

  const nodeNamesById = useMemo(() => {
    const targetNodeIds = props.casesData.flatMap((casesData) =>
      casesData
        .filter((c): c is Case => !isLogicalOperator(c))
        .map((c) => c.targetNodeId)
        .filter(Boolean)
    );
    return getNodes()
      .filter((n) => targetNodeIds.includes(getPureId(n.id)) && n.type === NodeType.LabelDesign)
      .reduce<{ [id: string]: string }>(
        (r, n) =>
          Object.assign(r, {
            [getPureId(n.id)]: (n.data.specificData as Nilable<LabelDesign>)?.name ?? ''
          }),
        {}
      );
  }, [getNodes, props.casesData]);

  const renderExistingFieldsHeader = () => (
    <Grid item xs={12} container className={classes.fieldModifierTop}>
      {/* Header */}
      <Grid item xs className={classes.header} sx={{ pl: 2 }}>
        <Typography>{$t({ id: 'caseEditor.condition' })}</Typography>
      </Grid>
      <Grid item xs={1} className={classes.header}>
        <Typography>{$t({ id: 'actions' })}</Typography>
      </Grid>
    </Grid>
  );

  const renderExistingCases = () =>
    props.casesData.map((caseData, index) => {
      const caseValues = buildCaseDataDescription(intl, nodeNamesById, caseData);
      const isElse = isElseCase(caseData[0]);
      return (
        <Grid
          key={`condition${index}`}
          xs={12}
          item
          container
          justifyContent="center"
          className={index % 2 === 0 ? classes.fieldItemDark : classes.fieldItemLight}
        >
          <Grid item xs className={classes.gridItem}>
            <Typography>
              {caseValues.map((cv, i) => (
                <Box key={`cv${i}`} sx={{ mr: 1, my: isElse ? 1 : undefined }}>
                  {cv}
                </Box>
              ))}
            </Typography>
          </Grid>
          <Grid item xs={1} className={classes.gridItem} justifyContent="flex-start">
            {isElse ? null : (
              <Tooltip title={$t({ id: 'remove' })}>
                <IconButton
                  edge="end"
                  aria-label={$t({ id: 'remove' })}
                  sx={{ color: 'error.main' }}
                  onClick={() => handleRemoveCase(index)}
                  size="large"
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
        </Grid>
      );
    });

  const renderAddCase = () => (
    <>
      <Grid
        item
        xs={12}
        className={currConditionsExist ? classes.fieldModifier : classes.fieldModifierTop}
      >
        <Typography
          variant="subtitle1"
          className={classes.addFieldGridItem}
          sx={{
            color: other.grey.dark,
            minHeight: spacing(6),
            display: 'flex',
            alignItems: 'center',
            ml: 1
          }}
        >
          {$t({ id: 'caseEditor.addNewCases' })}
        </Typography>
      </Grid>

      <Grid item container xs className={classes.fieldModifierBottom} alignItems="center">
        {props.availableConditions.size ? (
          selection.map((selected, index) => {
            // Exclude already selected conditions and its opposites
            const excludedConditions = selection
              .slice(0, index)
              .flatMap((selElement) => [
                selElement,
                props.conditionsAndOpposites.getAny(selElement)
              ])
              .filter((s): s is string => Boolean(s));
            // Exclude else if already exists or if not on first selection
            if (props.casesData.some((cd) => cd.some(isElseCase)) || index !== 0)
              excludedConditions.push(props.elseCaseDescriptor);

            const availableConditions = Array.from(props.availableConditions)
              .map(([k]) => k) // Pick only keys
              .filter((c) => !excludedConditions.includes(c));
            return availableConditions.length ? (
              // Even indexes should be case types
              index % 2 === 0 ? (
                <Grid item xs sx={{ pl: 1 }} key={`${selected}${index}`}>
                  <TextField
                    fullWidth
                    select
                    variant="standard"
                    name="condition"
                    id="condition"
                    label={$t({ id: 'ebp.fieldType' })}
                    value={(selected as LogicalOperator) === LogicalOperator.NONE ? '' : selected}
                    onChange={handleSelectionChange(index)}
                  >
                    {availableConditions.map((name, i) => (
                      <MenuItem value={name} key={`item${i}`}>
                        {name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              ) : (
                selection[0] !== props.elseCaseDescriptor && (
                  // Odd indexes should be logic operators
                  <Grid item xs sx={{ pl: 1 }} key={`${selected}${index}`}>
                    <TextField
                      fullWidth
                      select
                      variant="standard"
                      name="operator"
                      id="operator"
                      label={$t({ id: 'caseEditor.operation' })}
                      value={selected}
                      onChange={handleSelectionChange(index)}
                    >
                      {LogicalOperatorValues.map((name, i) => (
                        <MenuItem value={name} key={`item${i}`}>
                          {buildOperatorDescription(intl, name, i)}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                )
              )
            ) : undefined;
          })
        ) : (
          <Grid item xs sx={{ pl: 1 }}>
            <Typography color={palette.error.main}>
              {$t({ id: 'caseEditor.noConditionsAvailable' })}
            </Typography>
          </Grid>
        )}

        {/* Add button */}
        <Grid item xs />
        <Grid item xs={1} sx={{ textAlign: 'center', my: 1, mx: 0 }}>
          <Tooltip title={$t({ id: 'add' })}>
            <div>
              <Fab
                onClick={handleAddCondition}
                size="small"
                color="secondary"
                aria-label={$t({ id: 'add' })}
                disabled={
                  (selection.length - 1) % 2 === 0 ||
                  (selection[selection.length - 2] as LogicalOperator) === LogicalOperator.NONE
                }
              >
                <AddIcon fontSize="large" />
              </Fab>
            </div>
          </Tooltip>
        </Grid>
      </Grid>
    </>
  );

  return (
    <Container className={classes.root}>
      {/* Conditional editor */}
      <form onSubmit={props.handleSubmit} className={classes.gridContainer}>
        <Grid container direction="column">
          {/* Existing fields */}
          {currConditionsExist ? (
            <>
              {/* Field Descriptions */}
              {renderExistingFieldsHeader()}
              {/* Field Items */}
              {renderExistingCases()}
            </>
          ) : (
            /* If there are no fields added yet */
            <Grid item xs={12} sx={{ mb: 1 }}>
              <Typography variant="body1">{$t({ id: 'caseEditor.noCases' })}</Typography>
            </Grid>
          )}

          {/* Adding new fields */}
          {renderAddCase()}
        </Grid>

        {/* Save button */}
        <Grid container sx={{ mt: 2 }}>
          <Grid item xs />
          <Grid item xs="auto">
            <Button variant="contained" color="secondary" type="submit">
              {$t({ id: 'saveChanges' })}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default CaseEditor;
