import { Edge, isEdge, Node } from '@xyflow/react';
import { getType, NodeType } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/Elements';
import { Nilable, Undefinable } from 'tsdef';
import { TypedNode } from './types';

export type Element = Node | Edge;
export type Elements = Array<Element>;

export const filterElementsByType = (els: Elements, type?: string) =>
  type ? els.filter((n) => n.type === type) : [];

export const getElementIndex = (els: Elements, id?: string) =>
  id ? els.findIndex((n) => n.id === id) : -1;

export const getElementByIndex = (els: Elements, index?: number) =>
  // eslint-disable-next-line eqeqeq
  index !== -1 && index != null ? els[index] : undefined;

export const getElementById = <T extends Undefinable<Element>>(els?: Elements, id?: string) =>
  id ? (els?.filter((e) => e.id === id)[0] as T) : undefined;

export const connectionExists = (sourceType: NodeType, targetType: NodeType, edges: Edge[]) =>
  edges.some(
    (e) => getType<NodeType>(e.source) === sourceType && getType<NodeType>(e.target) === targetType
  );

export const isHandleConnected = (
  handleId: Nilable<string>,
  handleType: 'source' | 'target',
  edges: Edge[]
) => edges.some((e) => e[handleType === 'source' ? 'sourceHandle' : 'targetHandle'] === handleId);

/**
 * Returns all incoming nodes to the node with {@param nodeId}
 */
export const getIncomersById = (nodeId: string, elements: Elements): TypedNode[] => {
  const incomersIds = elements
    .filter((e) => isEdge(e) && e.target === nodeId)
    .map((e) => (e as Edge).source);
  return elements.filter((e) => incomersIds.includes(e.id)) as TypedNode[];
};

export const getOutgoersById = (nodeId: string, nodes: TypedNode[], edges: Edge[]): TypedNode[] => {
  const outgoersIds = edges.filter((e) => e.source === nodeId).map((e) => e.target);
  return nodes.filter((e) => outgoersIds.includes(e.id));
};

// Recursive getters
const MAX_DEPTH = 100;

export const getIncomersRecursively = (
  nodeId: string,
  elements: Elements,
  doNotCross: NodeType[] = []
): Node[] => getIncomersRecursivelyInternal(nodeId, elements, doNotCross);

const getIncomersRecursivelyInternal = (
  nodeId: string,
  elements: Elements,
  doNotCross: NodeType[] = [],
  stackSize = 0,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  visitedNodes?: Elements
): TypedNode[] => {
  if (stackSize > MAX_DEPTH) {
    console.error('Reached maximum depth size!');
    return [];
  }

  let incomers = getIncomersById(nodeId, elements);
  if (doNotCross.length) {
    incomers = incomers.filter((n) => n.type && !doNotCross.includes(n.type as NodeType));
  }

  return incomers.length
    ? incomers.concat(
        incomers.flatMap((i) =>
          getIncomersRecursivelyInternal(i.id, elements, doNotCross, stackSize + 1)
        )
      )
    : [];
};
