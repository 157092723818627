import { useUpdateEffect } from 'flyid-ui-components/dist/hooks';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Navigate, Outlet, RouteProps, useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks';
import { useAuth } from 'src/hooks/useAuth';
import { logoutAction, resendEmailVerification } from 'src/redux/actions/userActions';
import { selectTargetCompany } from 'src/redux/selectors/globalSelectors';
import { selectAuthDomains, selectCurrentUser } from 'src/redux/selectors/userSelectors';
import { useAppTheme } from 'src/theme/theme';
import { isKeyUserProf, isModeratorProf } from '../../util/helpers/user';
import BadRequest from '../widgets/BadRequest';
import LoadingCircle from '../widgets/LoadingCircle';

type ProtectedRouteProps = RouteProps & {
  modOnly?: boolean;
};

enum AuthState {
  NOT_SIGNED,
  LOADING,
  FULLY_SIGNED
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ modOnly }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user: authUser, loading: authLoading, usingSSO } = useAuth();
  const { userData, targetCompany, authDomains, locale } = useAppSelector((s) => {
    return {
      locale: s.locale.locale,
      userData: selectCurrentUser(s),
      authDomains: selectAuthDomains(s),
      targetCompany: selectTargetCompany(s)
    };
  });

  const { profile, emailVerified, isLoaded: isProfileLoaded, profileError } = userData;
  const isKeyUser = isKeyUserProf(profile);
  const isModerator = isModeratorProf(profile);

  const authState = useMemo(
    () =>
      // Auth or profile is loading
      authLoading || (authUser && !isProfileLoaded)
        ? AuthState.LOADING
        : !authUser
          ? // Auth is loaded but no user hasn signed in
            AuthState.NOT_SIGNED
          : // User is signed in and profile is loaded
            AuthState.FULLY_SIGNED,
    [authLoading, isProfileLoaded, authUser]
  );

  const pathname = location?.pathname;
  const [redirectDest, setRedirectTo] = useState<string | null>(null);
  useUpdateEffect(
    () => {
      // This rerouting must be kept in sync with routes paths.
      if (isKeyUser && pathname?.startsWith('/domains')) {
        // Is a key user in a domains-related route, which is susceptible to breaks when company
        // changes and company/domain relationship becomes inconsistent
        const domain = pathname.split('/')[2]; // routes follows the /domains/:domain pattern
        const viewingSessions = pathname.split('/')[3] === 'sessions';
        if (targetCompany && domain && authDomains && !authDomains.includes(domain)) {
          // The domain does not belong to the given company, redirect to first available domain
          // or main page, if none is available.
          setRedirectTo(
            authDomains.length
              ? viewingSessions
                ? // Redirect to the first available domain's sessions on the newly selected company
                  `/domains/${authDomains[0]}/sessions`
                : // Otherwise, change to the corresponding page on the newly selected company
                  (location?.pathname.replace(domain, authDomains[0]) ?? '/')
              : // Fallback to main page.
                '/'
          );
        }
      }
    },
    [targetCompany, authDomains],
    [!!targetCompany, !!authDomains]
  );

  // Reset redirection to avoid recursive redirects. This effect only applies on the next render,
  // as an effect of the value changing.
  // Therefore at least one render will have the redirectDest set.
  useEffect(() => {
    if (redirectDest) setRedirectTo(null);
  }, [redirectDest]);

  const hasProfileError = !!profileError;
  const usesPin = usingSSO && (profile.pilot || profile.checker);

  const { $t } = useIntl();
  const theme = useAppTheme();
  const dispatch = useAppDispatch();
  // When signing with auth provider, show redirection feedback
  if (usingSSO) {
    if (authState === AuthState.NOT_SIGNED)
      return <LoadingCircle text={$t({ id: 'sso.redirecting' })} />;
    else if (authState !== AuthState.LOADING && hasProfileError) {
      setTimeout(() => dispatch(logoutAction()), 3000);
      return <BadRequest text={$t({ id: 'sso.noProfile' })} />;
    }
  }
  // Loading authentication or profile data
  if (authState === AuthState.LOADING) return <LoadingCircle />;
  else if (authState === AuthState.FULLY_SIGNED) {
    // Show email verification widget if not verified yet
    if (!emailVerified)
      return (
        <BadRequest
          text={$t({ id: 'lacksEmailVerification' })}
          iconStyle={{ color: theme.palette.warning.main }}
          link={{
            text: $t({ id: 'resendEmailVerification' }),
            actionCallback: () => dispatch(resendEmailVerification(locale))
          }}
        />
      );

    // Inform of network blockage when lacking profile data
    if (profile?.hasOwnProperty('pwUpdated') === false)
      return (
        <BadRequest
          text={$t({ id: 'networkIsBlockingDomains' })}
          iconStyle={{ color: theme.palette.warning.main }}
        />
      );

    // If password has not been set, redirect user to password setting page
    if (profile?.pwUpdated === false) {
      if (!usingSSO && pathname !== '/setpw') {
        navigate('/setpw', { replace: true });
      } else if (pathname !== '/setpin' && usesPin) {
        navigate('/setpin', { replace: true });
      }
    }

    // Force user to unauthorized render when trying to access modOnly routes
    if (modOnly && !isModerator && !isKeyUser) {
      return <BadRequest text={$t({ id: 'unauthorized' })} />;
    }

    // Redirect when required
    if (redirectDest) navigate(redirectDest, { replace: true });

    // Otherwise route to destination path
    return <Outlet />;
  } // Go to login page if not logged in yet.
  else return <Navigate replace to="/login" />;
};

export default ProtectedRoute;
