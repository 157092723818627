import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  Box,
  Typography,
  TableHead,
  lighten,
  TableRow
} from '@mui/material';
import { appMakeStyles } from 'src/theme/theme';

const useStyles = appMakeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'left'
  },
  tableTitle: {
    marginTop: '20px',
    fontSize: '26px'
  },
  tableSubtitle: {
    marginTop: '20px',
    marginBottom: '20px',
    fontSize: '18px'
  },
  headerCell: {
    backgroundColor: lighten(theme.palette.primary.dark, 0.85),
    fontWeight: 'bold'
  }
}));

type AccuracyReportDataFormat = {
  columnDataRows: string[][];
  columnDataColumns: string[];
  positionDataRows: string[];
  positionDataColumns: string[];
};

type Props = {
  title?: string;
  subtitle?: string;
  data: AccuracyReportDataFormat;
  type: number;
};

const AccuracyReportTable: React.FC<Props> = (props) => {
  const classes = useStyles();

  const tableRows = [0, 1, 2, 3, 4];

  return (
    <Box className={classes.container}>
      <Box>
        {props.title ? <Typography className={classes.tableTitle}>{props.title}</Typography> : null}
        {props.subtitle ? (
          <Typography className={classes.tableSubtitle}>{props.subtitle}</Typography>
        ) : null}
        <Table>
          <TableHead>
            {props.type === 0
              ? props.data.columnDataColumns.map((column, index) => {
                  return (
                    <TableCell key={`${index}${column}`} className={classes.headerCell}>
                      {column}
                    </TableCell>
                  );
                })
              : props.data.positionDataColumns.map((column, index) => {
                  return (
                    <TableCell key={`${index}${column}`} className={classes.headerCell}>
                      {column}
                    </TableCell>
                  );
                })}
          </TableHead>

          <TableBody>
            {props.type === 0
              ? tableRows.map((tr) => {
                  return (
                    <TableRow>
                      {props.data.columnDataRows.map((array, index) => {
                        return <TableCell key={`${index}${array[tr]}`}>{array[tr]}</TableCell>;
                      })}
                    </TableRow>
                  );
                })
              : props.data.positionDataRows.map((row, index) => {
                  return <TableCell key={`${index}${row}`}>{row}</TableCell>;
                })}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};

export default AccuracyReportTable;
