import { Button, Container, Grid, lighten, Typography } from '@mui/material';
import {
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  Background,
  BackgroundVariant,
  Connection,
  Controls,
  Edge,
  EdgeChange,
  EdgeRemoveChange,
  HandleType as FlowHandleType,
  getConnectedEdges,
  getIncomers,
  IsValidConnection,
  Node,
  NodeAddChange,
  NodeChange,
  NodeRemoveChange,
  OnConnectStart,
  ReactFlow,
  ReactFlowProvider,
  reconnectEdge,
  useKeyPress
} from '@xyflow/react';
import { deleteDoc, getDoc, setDoc } from 'firebase/firestore';
import {
  DomainSettings,
  ProcessFlowBackup,
  ProcessFlowSettings
} from 'flyid-core/dist/Database/Models/Settings/DomainSettings';
import { AutoFillData } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/AutoFillData';
import { CaseData } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/Case';
import { CustomMarker } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/CustomMarker';
import {
  EdgeTypes,
  getNodeTypeFromStringValue,
  getPureId,
  getType,
  HandleType,
  NodeType
} from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/Elements';
import { LabelDesign } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/LabelDesign';
import { LogicalBlock } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/LogicalBlock';
import { ManualInputField } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/ManualInputField';
import { PictureTaking } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/PictureTaking';
import { getDomainsSettingsBackupDoc } from 'flyid-core/dist/Util/database';
import {
  calculateParentDimensions,
  ExpectedSizes,
  generateDoubleId,
  getNewChildPosition,
  getOutputNodePosition,
  getTypedId
} from 'flyid-core/dist/Util/processFlow';
import { convertListToMap, MapOf } from 'flyid-core/dist/Util/types';
import { cloneDeep, debounce, isEqual, isFunction } from 'lodash';
import React, { DragEvent, MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Navigate, useParams } from 'react-router-dom';
import { buildDocumentRef } from 'src/firebase/firestore';
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks';
import { useAppReactFlow } from 'src/hooks/useAppReactFlow';
import useStateReducer from 'src/hooks/useStateReducer';
import { editProcessFlowSettings, fetchLabelImage } from 'src/redux/actions/managementActions';
import { setConnectingData, setIsCtrlPressed } from 'src/redux/reducers/processFlowReducer';
import { updateUi } from 'src/redux/reducers/uiReducer';
import {
  domainLabelImagesSelector,
  selectSettingsForProcessFlow
} from 'src/redux/selectors/dataSelectors';
import { selectTargetCompany } from 'src/redux/selectors/globalSelectors';
import { selectCurrentUserProfile, selectDomainParent } from 'src/redux/selectors/userSelectors';
import { appMakeStyles, useAppTheme } from 'src/theme/theme';
import {
  filterElementsByType,
  getElementById,
  getElementIndex,
  isHandleConnected
} from 'src/util/processFlow/common';
import {
  createEdgeFrontBetweenNodes,
  edgeTypes,
  frontToSettEdges,
  settToFrontEdges
} from 'src/util/processFlow/edges';
import {
  checkNodeData,
  createNodeCopy,
  filterNodesByType,
  getDetachedNode,
  getNodeById,
  OnErrorCallback,
  PARENT_NODE_TYPES
} from 'src/util/processFlow/node';
import {
  frontToSettNodes,
  getNewNodeData,
  NodeEditor,
  nodeTypes,
  settToFrontNodes
} from 'src/util/processFlow/nodeData';
import {
  AppReactFlowInstance,
  BaseNodeData,
  CommonNodeData,
  ElementsChangeCallback,
  FlowNodeFront,
  LogicalBlockParent,
  OnDetachFromParentCallbackType,
  OnSaveCallbackType,
  SpecificDataTypesListable,
  TypedNode
} from 'src/util/processFlow/types';
import { Nilable, Nullable } from 'tsdef';
import TransitionModal from '../../../utils/TransitionModal';
import { MarkerDefinition } from './edges/MarkerDefinition';
import FlowSidebar from './FlowSidebar';
import ConnectionLine from './widgets/ConnectionLine';
// eslint-disable-next-line import/no-unassigned-import
import '@xyflow/react/dist/style.css';
import { copyLabelImageState, resetLabelImageState } from 'src/redux/reducers/labelImagesReducer';
import { getStore } from 'src/redux/store';
import { DEBUG_WORKFLOW } from 'src/util/debug';
import { isProcessFlowConsistent } from 'src/util/processFlow/validation';
const flowHeigth = 550;

const useStyles = appMakeStyles(({ palette, spacing }) => ({
  root: {
    flexGrow: 1,
    maxWidth: '100%'
  },
  flowContainer: {
    display: 'flex',
    flexDirection: 'row',
    borderColor: palette.primary.dark,
    borderRadius: spacing(1),
    borderWidth: 'medium',
    borderStyle: 'double',
    minWidth: '600px'
  },
  flowWrapper: { width: '100%', height: flowHeigth },
  flow: { background: 'white' },
  mp3Checkbox: {
    margin: spacing(0, 0, 1, 0)
  },
  modalContainer: {
    margin: spacing(2)
  }
}));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ElementsDebugger = ({ nodes, edges }: { nodes?: Node[]; edges?: Edge[] }) => {
  if (nodes) console.log('nodes', nodes);
  if (edges) console.log('edges', edges);
  return null;
};

type ModalState = {
  id?: string;
  open: boolean;
};

enum SettingsState {
  UNINITIALIZED,
  INITIALIZING,
  SETTINGS_SELECTED,
  WAITING_LABELS,
  INITIALIZED,
  ERROR
}

const Workflow: React.FC = () => {
  const { domain } = useParams<DomainMatchParams>();
  // Fallback to home if domain is missing
  if (!domain) return <Navigate replace to="/" />;

  const classes = useStyles();
  const { text, palette } = useAppTheme();
  const intl = useIntl();
  const { $t } = intl;

  const dispatch = useAppDispatch();
  const { getIntersectingNodes, getNode } = useAppReactFlow();

  const [nodes, setNodes] = useState<TypedNode[]>([]);
  const [edges, setEdges] = useState<Edge[]>([]);

  const reactFlowWrapper = useRef<HTMLDivElement>(null);
  const [reactFlowInstance, setReactFlowInstance] = useState<Nullable<AppReactFlowInstance>>(null);

  const [initState, setInitState] = useState(SettingsState.UNINITIALIZED);
  const [modalState, setModalState] = useStateReducer<ModalState>({ open: false });

  const [initialNodesStringified, setInitialNodesStringified] = useState<string>('');
  const [initialEdgesStringified, setInitialEdgesStringified] = useState<string>('');
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [isDiscarding, setIsDiscarding] = useState(false);
  const [reconnectingEdge, setReconnectingEdge] = useState<Edge | null>(null);

  // Listens to changes on ctrl key
  const isCtrlPressed = useKeyPress('Control');

  const { settings, domainLabelStates, profile, company, parentUid } = useAppSelector((state) => ({
    parentUid: selectDomainParent(state, domain),
    company: selectTargetCompany(state),
    settings: selectSettingsForProcessFlow(state, domain),
    domainLabelStates: domainLabelImagesSelector(domain, state),
    profile: selectCurrentUserProfile(state)
  }));

  // Process callbacks
  const getOnDetachFromParent: (nodeId: string) => OnDetachFromParentCallbackType = useCallback(
    (nodeId: string) =>
      (parent: string, repositionSiblings = true, callback?: ElementsChangeCallback) => {
        const _nodes = reactFlowInstance?.getNodes() ?? [];

        const node = getNodeById(_nodes, nodeId);
        const parentNode = getNodeById<LogicalBlockParent>(_nodes, parent);

        if (!node || !parentNode) return;

        const currentNodeEdges = getConnectedEdges([node], reactFlowInstance?.getEdges() ?? []).map(
          (e) => e.id
        );

        const { outputNodeId, contentChildrenIds } = parentNode.data.specificData ?? {};

        callback?.(setNodes, setEdges);

        const updatedSiblings = [...(contentChildrenIds ?? [])];
        updatedSiblings.splice(updatedSiblings.indexOf(nodeId), 1);

        const updatedSiblingNodes = _nodes.filter((n) => updatedSiblings.includes(n.id));
        const outputNode = outputNodeId ? getNodeById(_nodes, outputNodeId) : undefined;

        const { width: newParentWidth, height: newParentHeight } =
          calculateParentDimensions(updatedSiblingNodes, outputNode) ??
          ExpectedSizes[NodeType.LogicalBlock];

        setNodes((ns) =>
          ns.map((n) => {
            const childIndex = updatedSiblings.indexOf(n.id) ?? -1;
            // Detach current node
            if (n.id === nodeId) {
              return getDetachedNode(parentNode, n);
            } // Reposition siblings
            else if (childIndex !== -1 && repositionSiblings) {
              return {
                ...n,
                position: getNewChildPosition(n, childIndex, newParentWidth, outputNode)
              };
            } // Reposition output node
            else if (n.id === outputNodeId) {
              return {
                ...n,
                position: getOutputNodePosition(n, newParentWidth, newParentHeight)
              };
            } // Update parent's children
            else if (n.id === parentNode.id) {
              // If other type of parent node ever appears, add type switch case here
              const specificData = n.data.specificData;
              return {
                ...n,
                data: {
                  ...n.data,
                  specificData: {
                    ...specificData,
                    contentChildrenIds: updatedSiblings
                  } as typeof specificData
                }
              };
            }
            return n;
          })
        );

        // console.log(currentNodeEdges, reactFlowInstance!.getEdges());
        reactFlowInstance
          ?.deleteElements({
            edges: currentNodeEdges.map((id) => ({ id }))
          })
          .catch((err) => console.error('Failed deleting elements', err));
      },
    [reactFlowInstance, setNodes, setEdges]
  );

  const getOnEditorSave: (id: string) => OnSaveCallbackType<SpecificDataTypesListable> =
    useCallback(
      (id: string) => (specificData, callback) => {
        // Execute callback before setting new data
        callback?.(setNodes, setEdges);
        setNodes((nds) =>
          nds.map((n) => {
            if (n.id === id) {
              n = cloneDeep(n);
              n.data = { ...n.data, specificData };
            }
            return n;
          })
        );
        setModalState({ open: false });
      },
      [setNodes, setEdges, setModalState]
    );

  const getBaseNodeData = (node: FlowNodeFront, isStartEndNode = false): BaseNodeData => {
    const typedId = getTypedId(node);
    return {
      onEditClick: isStartEndNode ? undefined : () => setModalState({ open: true, id: typedId }),
      onCloseClick: () => setModalState({ open: false }),
      onDetachFromParent: node.parent ? getOnDetachFromParent(typedId) : undefined,
      onNodeCopy: getOnNodeCopy(typedId),
      hideDetach: node.parent && node.type === NodeType.Conditional ? true : false,
      rotation: node.rotation
    };
  };

  const getOnNodeCopy = useCallback(
    (nodeId: string) => () => {
      const targetNode = getNode(nodeId);
      if (!targetNode) return;

      const nodeCopy = createNodeCopy(targetNode, getBaseNodeData);
      if (!nodeCopy) return alert('Failed creating node copy!');

      // Copy label image as well, if is a label design node
      if (getType(targetNode.id) === NodeType.LabelDesign) {
        // Make sure label states are up to date
        const currDomainLabelStates = domainLabelImagesSelector(domain, getStore().getState());

        // Make sure the node being copied has a picture loaded and available for copying
        const sourceId = getPureId(targetNode.id);
        const sourceStateImage = currDomainLabelStates?.[sourceId] ?? {};

        const { localSrc, src, isLoaded } = sourceStateImage;
        if (!isLoaded || !(localSrc ?? src))
          return alert('Label design node does not have a label to copy from!');

        (nodeCopy.data.specificData as LabelDesign).copiedFromId = sourceId;
        dispatch(
          copyLabelImageState({
            domain,
            sourceId,
            targetId: getPureId(nodeCopy.id)
          })
        );
      }

      setNodes((currNodes) =>
        currNodes
          // Make sure copied node is not selected
          .map((n) => (n.id === nodeId ? { ...n, selected: false } : n))
          // Add new node
          .concat(nodeCopy)
      );
    },
    [reactFlowInstance, getNode, setNodes, getBaseNodeData]
  );

  // Is valid connection always shows feedback on destination node, regardless of being source or target
  const isValidConnection: IsValidConnection = useCallback(
    (conn) => {
      const { source, target, targetHandle, sourceHandle } = conn;

      if (!targetHandle || !sourceHandle || !reactFlowInstance) return false;

      const targetHandleType = getType<HandleType>(targetHandle);
      const sourceHandleType = getType<HandleType>(sourceHandle);

      // Do not allow direct connection between start and end nodes
      if (sourceHandleType === HandleType.START) {
        if (targetHandleType === HandleType.END) {
          return false;
        }
        // Allow only LabelDesign to be connected to start
        const typedTarget = getType<NodeType>(conn.target);
        if (typedTarget && NodeType.LabelDesign !== typedTarget) {
          if (DEBUG_WORKFLOW) console.log('Start only allows label design!');
          return false;
        }
      }

      // Do not allow self-connection
      if (source && target && source !== target) {
        const _nodes = reactFlowInstance.getNodes() || [];
        const _edges = reactFlowInstance.getEdges() || [];

        const sourceNode = getElementById<TypedNode>(_nodes, source);
        const targetNode = getElementById<TypedNode>(_nodes, target);

        // Make node-specific validations
        if (sourceNode && targetNode) {
          // Check connections by source type
          switch (sourceNode?.type) {
            case NodeType.Conditional: {
              // Do not allow chained conditionals, since it is unecessary
              if (targetNode?.type === sourceNode.type) {
                if (DEBUG_WORKFLOW) console.log('Chained conditionals!');
                return false;
              }

              // Do not allow the same source handle to connect to more than one element,
              // but allow an unconnected single type handle to connect to other nodes
              const outgoingEdges = _edges.filter((e) => e.source === sourceNode.id);
              if (isHandleConnected(conn.sourceHandle, 'source', outgoingEdges)) {
                if (DEBUG_WORKFLOW) console.log('Already connected to another source');
                return false;
              }
              break;
            }
          }

          // Check connections by target type
          switch (targetNode.type) {
            case NodeType.Conditional: {
              // Conditionals inside a parent should not accept any connections,
              // since they are created automatically
              if (targetNode.data.parent) {
                if (DEBUG_WORKFLOW) console.log('Cannot connect to child conditional');
                return false;
              }
              break;
            }
          }

          // When reconnecting, escape new connection checks.
          // Currently not working, since reconnectingEdge state is not updating on this callback (why?)
          if (!reconnectingEdge) {
            // New connection handle type checks
            if (sourceHandleType !== HandleType.MULTIPLE) {
              // Only allow multiple connections FROM 'MULTIPLE' handle type
              // const outgoers = getOutgoers(sourceNode, _nodes, _edges);
              // if (outgoers.length >= 1) {
              //   if (DEBUG_WORKFLOW) console.log('Multiple connections FROM non-multiple handle');
              //   return false;
              // }
            }
            // Only allow multiple connections TO 'MULTIPLE' or 'END' handle types
            if (targetHandleType !== HandleType.MULTIPLE && targetHandleType !== HandleType.END) {
              const incomers = getIncomers(targetNode, _nodes, _edges);
              if (incomers.length >= 1) {
                if (DEBUG_WORKFLOW) console.log('Multiple connections TO non-multiple handle');
                return false;
              }
            }
          }
          return true;
        }
      }
      return false;
    },
    [reactFlowInstance, reconnectingEdge]
  );

  // Callbacks that add functionality to ReactFlow
  const onInit = (i: AppReactFlowInstance) => {
    setReactFlowInstance(i);
  };

  const onEdgesChange = useCallback(
    (changes: EdgeChange[]) => {
      const finalChanges = [...changes];

      if (reactFlowInstance) {
        const removeEdgeChanges = changes.filter(
          (ch): ch is EdgeRemoveChange => ch.type === 'remove'
        );
        //TODO Review conditional cleaning. Maybe clean whenever target node data changes as well
        const _edges = reactFlowInstance.getEdges();
        const removedEdges =
          _edges.filter((e) => removeEdgeChanges.some((ec) => ec.id === e.id)) ?? [];
        // If edges have been removed
        if (removedEdges.length) {
          // If removing an edge that targets a ConditionalNode, we should reset its cases data and remove
          // its outgoing edges, since it will affect the available cases to be chosen.
          const conditionalTargetEdges = removedEdges.filter(
            (e) => getType<NodeType>(e.target) === NodeType.Conditional
          );
          if (conditionalTargetEdges.length) {
            // Clean cases data of the target conditional(s)
            const targetedConditionalIds = conditionalTargetEdges.map((e) => e.target);
            setNodes((nds) =>
              nds.map((n) =>
                targetedConditionalIds.includes(n.id)
                  ? {
                      ...n,
                      data: {
                        ...n.data,
                        specificData: []
                      }
                    }
                  : n
              )
            );
            // Remove outgoing edges which will get stale, since Case handlers will be removed as well
            finalChanges.push(
              ..._edges
                .filter((e) => targetedConditionalIds.includes(e.source))
                .map((e) => ({ id: e.id, type: 'remove' }) as EdgeRemoveChange)
            );
          }
        }
      }
      setEdges((eds) => applyEdgeChanges(changes, eds));
    },
    [setEdges, setNodes, reactFlowInstance]
  );
  const onReconnectStart = useCallback(
    (event: React.MouseEvent, edge: Edge, handleType: FlowHandleType) => {
      if (DEBUG_WORKFLOW) console.log('onReconnectStart', edge, handleType);
      setReconnectingEdge(edge);
    },
    [reactFlowInstance]
  );
  const onReconnectEnd = useCallback(
    (event, edge: Edge, handleType: FlowHandleType) => {
      if (DEBUG_WORKFLOW) console.log('onReconnectEnd', edge, handleType);
      setReconnectingEdge(null);
    },
    [reactFlowInstance]
  );
  const onReconnect = useCallback(
    (oldEdge: Edge, newConn: Connection) =>
      setEdges(reconnectEdge(oldEdge, newConn, reactFlowInstance!.getEdges())),
    [reactFlowInstance]
  );
  const onConnect = useCallback(
    (params: Edge | Connection) => {
      const _edges = reactFlowInstance?.getEdges() ?? [];
      const newEls = addEdge({ ...(params as Edge), type: EdgeTypes.Custom }, _edges);
      setEdges(newEls);
    },
    [reactFlowInstance]
  );
  const onNodesChange = useCallback(
    (changes: NodeChange<TypedNode>[]) => {
      const addChanges = changes.filter(
        (c): c is NodeAddChange<Node<CommonNodeData>> => c.type === 'add'
      );

      const nodeIdsToRemove = changes
        .filter((c): c is NodeRemoveChange => c.type === 'remove')
        .map((c) => c.id);

      const nodesToRemove = reactFlowInstance
        ?.getNodes()
        .filter((n) => nodeIdsToRemove.includes(n.id));

      if (addChanges.length) {
        const conditionalOutputAdded = addChanges.filter(
          (c) => c.item?.type === NodeType.Conditional && !!c.item.parentId
        )[0];

        // If adding a Conditional to parent node, just fill missing data
        if (conditionalOutputAdded) {
          const node = conditionalOutputAdded.item;
          const newData: Partial<CommonNodeData> = {
            baseNodeData: {
              onEditClick: () => setModalState({ open: true, id: node.id }),
              onCloseClick: () => setModalState({ open: false })
            },
            ...getNewNodeData(node.type as NodeType),
            parent: node.parentId
          };
          Object.assign(node.data, newData);

          // Cannot just applyNodeChanges to added data since applyNodeChanges adds node to start of
          // nodes list instead of its end, which breaks adding a child node, since it will precede its parent
          setNodes((ns) =>
            ns.map((n) => {
              // Add child to parent node
              if (n.id === node.parentId) {
                const specificData = n.data.specificData;
                return {
                  ...n,
                  data: {
                    ...n.data,
                    specificData: {
                      ...specificData,
                      outputNodeId: node.id
                    } as typeof specificData
                  }
                };
              }
              return n;
            })
          );
        }
      }

      if (nodesToRemove?.length) {
        // If removing a Conditional from parent node, remove parent outputNodeId
        const removedConditionalParents = nodesToRemove
          .filter((n) => n.type === NodeType.Conditional && n.data.specificData)
          .map((n) => n.data.parent!);
        if (removedConditionalParents.length) {
          setNodes((ns) =>
            ns.map((n) => {
              if (removedConditionalParents.includes(n.id)) {
                const specificData = n.data.specificData;
                return {
                  ...n,
                  data: {
                    ...n.data,
                    specificData: {
                      ...specificData,
                      outputNodeId: undefined
                    } as typeof specificData
                  }
                };
              }
              return n;
            })
          );
        }
      }

      // After all operations have been placed, then we apply changes.
      setNodes((ns) => applyNodeChanges<TypedNode>(changes, ns));
    },
    [setNodes, reactFlowInstance]
  );
  const onNodeDrag = useCallback(
    (e: MouseEvent, node: TypedNode) => {
      // Drag calculations when moving a LabelDesign node
      if (node.type === NodeType.LabelDesign || node.type === NodeType.CustomMarker) {
        const parentIntersections = getIntersectingNodes(node).filter(
          (n) => n.type === NodeType.LogicalBlock
        );
        if (parentIntersections.length) {
          // If intersects with exactly one parent node, show 'add to parent' textures
          if (parentIntersections.length === 1) {
            const nodeIntersection = node.data.intersectsWith;
            const parentNode = parentIntersections[0];
            if (parentNode.data.intersectsWith !== node.id || nodeIntersection !== parentNode.id) {
              setNodes((ns) =>
                ns.map((n) => {
                  // Update dragged node
                  if (n.id === node.id) {
                    return { ...n, data: { ...n.data, intersectsWith: parentNode.id } };
                  }
                  // Update new parent node
                  if (n.id === parentNode.id) {
                    return { ...n, data: { ...n.data, intersectsWith: node.id } };
                  }
                  // Node was previously intersecting another parent
                  if (nodeIntersection === n.id && nodeIntersection !== parentNode.id) {
                    return { ...n, data: { ...n.data, intersectsWith: undefined } };
                  }
                  return n;
                })
              );
            }
          }
        } else if (node.data.intersectsWith) {
          // Cleanup intersections
          setNodes((ns) =>
            ns.map((n) => {
              if (n.id === node.data.intersectsWith || node.id) {
                return { ...n, data: { ...n.data, intersectsWith: undefined } };
              }
              return n;
            })
          );
        }
      }
    },
    [getIntersectingNodes, setNodes]
  );
  const onNodeDragStop = useCallback(
    (_: MouseEvent, droppedNode: TypedNode) => {
      if (
        (droppedNode.type === NodeType.LabelDesign || droppedNode.type === NodeType.CustomMarker) &&
        droppedNode.data.intersectsWith
      ) {
        const _nodes = reactFlowInstance?.getNodes() ?? [];
        const parentId = droppedNode.data.intersectsWith;
        const parentNode = getNodeById<LogicalBlockParent>(_nodes, parentId);

        // If is not intersecting with a parent, just let it be moved.
        if (!parentNode) return;

        const { contentChildrenIds, outputNodeId } = parentNode.data.specificData ?? {};

        const outputSiblingNode = outputNodeId ? getNodeById(_nodes, outputNodeId) : undefined;
        const siblingNodeIds =
          contentChildrenIds?.filter((c) => getType(c) !== NodeType.Conditional) ?? [];

        // Do not allow multiple custom markers on a single logical block
        if (
          droppedNode.type === NodeType.CustomMarker &&
          siblingNodeIds.some((siblingId) => getType(siblingId) === NodeType.CustomMarker)
        ) {
          dispatch(
            updateUi({
              snackbar: {
                message: 'Should not have more than one custom marker per logical block!',
                show: true
              }
            })
          );
          return;
        }

        const updatedSiblingNodes = _nodes
          .filter((n) => siblingNodeIds.includes(n.id))
          .concat(droppedNode);

        const { width: newParentWidth, height: newParentHeight } =
          calculateParentDimensions(updatedSiblingNodes, outputSiblingNode) ??
          ExpectedSizes[NodeType.LogicalBlock];

        let updatedNode: TypedNode;
        // Add dragged node to intersected parent
        setNodes((ns) =>
          ns.map((n) => {
            const childIndex = siblingNodeIds.indexOf(n.id) ?? -1;
            // Modify node to become a child
            if (n.id === droppedNode.id) {
              const prevChildrenCount = siblingNodeIds.length ?? 0;
              updatedNode = {
                ...n,
                // ReactFlow data
                extent: 'parent',
                parentId,
                position: getNewChildPosition(
                  droppedNode,
                  prevChildrenCount,
                  newParentWidth,
                  outputSiblingNode
                ),
                draggable: false,
                deletable: false,
                // Fly.id node data
                data: {
                  ...n.data,
                  parent: parentId,
                  intersectsWith: undefined,
                  baseNodeData: {
                    ...n.data.baseNodeData,
                    onDetachFromParent: getOnDetachFromParent(n.id)
                  }
                }
              };
              return updatedNode;
            } // Add to intersected parent
            else if (n.id === parentId) {
              // If other type of parent node ever appears, treat cases here
              const specificData = n.data.specificData as LogicalBlockParent;
              return {
                ...n,
                data: {
                  ...n.data,
                  specificData: {
                    ...specificData,
                    contentChildrenIds: specificData.contentChildrenIds.concat(droppedNode.id)
                  },
                  intersectsWith: undefined
                }
              };
            } // Update siblings positioning
            else if (childIndex !== -1) {
              return {
                ...n,
                position: getNewChildPosition(n, childIndex, newParentWidth, outputSiblingNode)
              };
            } else if (n.id === outputNodeId) {
              return {
                ...n,
                position: getOutputNodePosition(n, newParentWidth, newParentHeight)
              };
            }
            return n;
          })
        );

        setEdges((es) => {
          // Transfer edges targeting this node to target parent
          let _edges = es.map((e) =>
            e.target === droppedNode.id
              ? createEdgeFrontBetweenNodes(getNodeById(_nodes, e.source)!, parentNode)
              : e
          );

          if (outputSiblingNode) {
            // If output sibling exists, remove current node output edges and add new connections to it (output).
            _edges = _edges.filter((e) => e.source !== droppedNode.id);
            _edges = _edges.concat(createEdgeFrontBetweenNodes(updatedNode, outputSiblingNode));
          }
          return _edges;
        });
      }
    },
    [reactFlowInstance, setEdges, setNodes]
  );
  const onNodesDelete = useCallback(
    (nodesToBeDeleted: TypedNode[]) => {
      if (DEBUG_WORKFLOW) console.log('nodesToBeDeleted:', nodesToBeDeleted);
      const parentNodes = nodesToBeDeleted.filter((n) =>
        PARENT_NODE_TYPES.includes(n.type as NodeType)
      );
      if (parentNodes.length) {
        const actions: {
          [nodeId: string]: {
            parent: TypedNode;
            action: 'detach' | 'delete';
          };
        } = {};

        parentNodes.forEach((parent) => {
          const parentData = parent.data.specificData as LogicalBlockParent | undefined;
          parentData?.contentChildrenIds.forEach((c) => {
            actions[c] = { parent, action: 'detach' };
          });
          if (parentData?.outputNodeId) {
            actions[parentData?.outputNodeId] = { parent, action: 'delete' };
          }
        });

        setNodes((ns) =>
          ns
            .map((n) => {
              const { action, parent } = actions[n.id] ?? {};
              if (action) {
                if (action === 'delete') return undefined;
                else if (action === 'detach') return getDetachedNode(parent, n);
              }
              return n;
            })
            .filter((n): n is TypedNode => Boolean(n))
        );
      }
    },
    [reactFlowInstance]
  );
  const onDragOver = useCallback((e: DragEvent) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';
  }, []);
  const onDrop = useCallback(
    (e: DragEvent) => {
      e.preventDefault();

      if (!reactFlowWrapper.current || !reactFlowInstance) return;

      // Check for valid node type
      const type = e.dataTransfer.getData('application/reactflow');
      const nodeType = getNodeTypeFromStringValue(type);
      if (!nodeType) return;

      const isStartOrEndNode = nodeType === NodeType.Start || nodeType === NodeType.End;
      setNodes((_nodes) => {
        // Do not allow more than one 'Start' or 'End' nodes to exist
        const nodesOfThisType = filterElementsByType(_nodes, type);
        if (nodeType === NodeType.Start) {
          if (nodesOfThisType?.length) {
            alert(
              $t({ id: 'processFlow.singleNode' }, { name: $t({ id: nodeType.toLowerCase() }) })
            );
            return _nodes;
          }
        }
        const id = `${nodeType}_${generateDoubleId()}`;
        const position = reactFlowInstance.screenToFlowPosition({ x: e.clientX, y: e.clientY });

        const baseNodeData: BaseNodeData = {
          onEditClick: isStartOrEndNode ? undefined : () => setModalState({ open: true, id }),
          onCloseClick: () => setModalState({ open: false }),
          onNodeCopy: getOnNodeCopy(id)
        };

        const data: CommonNodeData = {
          baseNodeData,
          ...getNewNodeData(nodeType)
        };

        const dragHandle = '.custom-drag-handle';
        const newNode: TypedNode = { id, type, position, data, dragHandle };
        // Parent nodes must be at the top of the nodes list to work properly
        return PARENT_NODE_TYPES.includes(nodeType)
          ? [newNode].concat(_nodes)
          : _nodes.concat(newNode);
      });
    },
    [reactFlowInstance, setModalState, isValidConnection]
  );
  const onConnectStart: OnConnectStart = useCallback(
    (e, connectionParams) => {
      if (DEBUG_WORKFLOW) console.log('onConnectStart');
      dispatch(setConnectingData({ isConnecting: true, connectionParams }));
    },
    [dispatch, setConnectingData]
  );
  const onConnectEnd = useCallback(() => {
    if (DEBUG_WORKFLOW) console.log('onConnectEnd');
    dispatch(setConnectingData({ isConnecting: false }));
  }, [dispatch, setConnectingData]);

  // Initializes nodes and edges based on either backup data or current settings
  const initializeNodesAndEdges = useCallback(
    (forceInit = false) => {
      if (
        settings &&
        reactFlowInstance &&
        profile &&
        company &&
        (initState === SettingsState.UNINITIALIZED || forceInit)
      ) {
        setInitState(SettingsState.INITIALIZING);
        // Initializes flow with cloud data
        const initSettingsProcessFlow = () => {
          try {
            const initialNodes = [...settToFrontNodes(settings.processFlow, getBaseNodeData)];
            const initialEdges = [...settToFrontEdges(settings.processFlow)];
            if (DEBUG_WORKFLOW) console.log(initialNodes, initialEdges);

            setNodes(initialNodes);
            setEdges(initialEdges);

            if (settings.processFlow.labelDesigns) {
              Object.entries(settings.processFlow.labelDesigns).forEach(([labelId]) => {
                dispatch(fetchLabelImage({ company, domain, labelId }));
              });
            }
            setHasUnsavedChanges(false);
            setInitState(SettingsState.SETTINGS_SELECTED);
          } catch (err) {
            console.error(err);
            //TODO treat properly?
            setInitState(SettingsState.ERROR);
          }
        };

        // Initializes flow with backup data, if backup is available, otherwise init current sett
        if (!forceInit) {
          const docRef = buildDocumentRef(getDomainsSettingsBackupDoc(company, domain));
          getDoc(docRef)
            .then((docSnap) => {
              if (docSnap.exists()) {
                const data = docSnap.data();

                const initialNodes = JSON.parse(data.strNodes) as TypedNode[];
                const initialEdges = JSON.parse(data.strEdges) as Edge[];
                if (DEBUG_WORKFLOW) console.log('Init from backup', initialNodes, initialEdges);

                // Complements the base node data
                initialNodes.forEach((n) => {
                  const nodeId = getPureId(n.id);
                  const nodeData = n.data;

                  const nodeFront = { ...n, id: nodeId } as FlowNodeFront;
                  const isStartOrEndNode = n.type === NodeType.Start || n.type === NodeType.End;
                  const rotation = nodeData.baseNodeData.rotation;

                  const baseNodeData = getBaseNodeData(nodeFront, isStartOrEndNode);
                  baseNodeData.rotation = rotation;

                  nodeData.baseNodeData = baseNodeData;
                  nodeData.editor = NodeEditor[n.type as NodeType];

                  // Check whether using backup label design and fetch backup label
                  if (n.type === NodeType.LabelDesign) {
                    const specificData = nodeData.specificData as LabelDesign;
                    dispatch(
                      fetchLabelImage({
                        company,
                        domain,
                        labelId: nodeId,
                        fetchBackup: specificData.isBackup
                      })
                    );
                  }
                });
                setNodes(initialNodes);
                setEdges(initialEdges);
                setHasUnsavedChanges(true);
                setInitState(SettingsState.SETTINGS_SELECTED);
              } else {
                if (DEBUG_WORKFLOW) console.log('No unsaved changes document found');
                initSettingsProcessFlow();
              }
            })
            .catch((err) => {
              console.error(err);
            });
        } else {
          // console.log('Escaping backup, init from original data');
          initSettingsProcessFlow();
        }
      }
    },
    [settings, reactFlowInstance, profile, company, initState]
  );

  /**
   * Responsible for initializing settings and saving the initial nodes and edges once settings are selected,
   * with a debounce to handle stabilization
   */
  const debouncedStateInitializer = useCallback(
    debounce((sett: Nilable<DomainSettings>) => {
      if (sett) {
        // Save original settings from the cloud for comparison when settings change
        // These values are not the exact same as the cloud ones, but represent its initial value.
        const initialNodes = [...settToFrontNodes(sett.processFlow, getBaseNodeData)];
        const initialEdges = [...settToFrontEdges(sett.processFlow)];
        setInitialNodesStringified(JSON.stringify(initialNodes));
        setInitialEdgesStringified(JSON.stringify(initialEdges));
        setInitState(SettingsState.WAITING_LABELS);
      }
    }, 50),
    [getBaseNodeData]
  );

  /**
   * Saves current settings (modified if compared to original) to cloud
   */
  const saveLocalChanges = useCallback(
    async (backup: ProcessFlowBackup) => {
      if (!profile || !company) {
        console.warn("It's not possible to save the changes right now, try again later.", backup);
        return;
      }

      const docRef = buildDocumentRef(getDomainsSettingsBackupDoc(company, domain));
      try {
        await setDoc(docRef, backup);
      } catch (err) {
        console.error(err);
      }
    },
    [profile, company, domain]
  );

  /**
   * Responsible for debouncing save to cloud from every user change action.
   */
  const debouncedSaveFunction = useCallback(
    debounce((_isInit: boolean) => {
      if (!reactFlowInstance) return;
      const currNodes = reactFlowInstance.getNodes();
      const currEdges = reactFlowInstance.getEdges();

      const strNodes = JSON.stringify(currNodes);
      const strEdges = JSON.stringify(currEdges);
      const backup: ProcessFlowBackup = { strNodes, strEdges };
      if (
        _isInit &&
        (!isEqual(initialNodesStringified, strNodes) || !isEqual(initialEdgesStringified, strEdges))
      ) {
        setHasUnsavedChanges(true);
        saveLocalChanges(backup)
          .then(() => {
            if (DEBUG_WORKFLOW) console.log('Local changes successfully saved');
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }, 2000),
    [
      reactFlowInstance,
      setHasUnsavedChanges,
      saveLocalChanges,
      initialNodesStringified,
      initialEdgesStringified
    ]
  );

  // Debounce fit view to avoid early-fitting
  const debouncedFitView = useCallback(
    debounce(() => reactFlowInstance?.fitView(), 50),
    [reactFlowInstance]
  );

  /**
   * Discards changes by deleting the backup and reinitializing nodes and edges
   */
  const discardChanges = useCallback(() => {
    if (!profile || !company) {
      console.warn(
        "It's not possible to discard the changes because the profile is not available."
      );
      return;
    }

    // Cancel save debouncer, if any
    debouncedSaveFunction.cancel();

    setIsDiscarding(true);
    const docRef = buildDocumentRef(getDomainsSettingsBackupDoc(company, domain));
    // Make sure to reload pictures on redux state
    dispatch(resetLabelImageState({ targetDomain: domain, labelIds: null }));

    deleteDoc(docRef)
      .then(() => {
        if (DEBUG_WORKFLOW) console.log('Backup successfully deleted');
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsDiscarding(false);
      });
    initializeNodesAndEdges(true);
  }, [profile, initializeNodesAndEdges]);

  /**
   * Flow Initialization Process
   * 1. `initializeNodesAndEdges`: Loads and sets nodes and edges from backup or current settings
   * 2. `debouncedStateInitializer`: Debounces and saves the initial state of nodes and edges for future comparisons.
   * 3. `debouncedFitView`: Fits the view after initialization is complete.
   * 4. `useEffect` for `updateUi`: Updates UI to show or hide loading messages based on settings state.
   * 5. `useEffect` cleanup: Cleans up the debounce function on component unmount.
   */
  useEffect(() => {
    switch (initState) {
      // Initialize settings if not yet
      case SettingsState.UNINITIALIZED:
        initializeNodesAndEdges();
        break;
      // Debounce react flow changes to make sure initial references are set only
      // when react flow state has stabilized
      case SettingsState.SETTINGS_SELECTED:
        debouncedStateInitializer(settings);
        break;
      // Wait for labels to load as well
      case SettingsState.WAITING_LABELS:
        const labelsLoaded = filterNodesByType(NodeType.LabelDesign, nodes).every(
          (node) => !domainLabelStates || domainLabelStates[getPureId(node.id)]?.isLoaded
        );
        if (labelsLoaded) setInitState(SettingsState.INITIALIZED);
        break;
      // Fit view after all elements have been loaded
      case SettingsState.INITIALIZED:
        debouncedFitView()?.catch((err) => console.error('debouncedFitView error', err));
        break;
    }
  }, [initState, initializeNodesAndEdges, domainLabelStates]);

  // Unmounting effects
  useEffect(
    () => () => {
      // Clean up the debounce function
      debouncedStateInitializer.cancel();
      // Cancel saving on component unmount
      debouncedSaveFunction?.cancel();
      // Make sure to hide loading backdrops when user changes current page
      dispatch(updateUi({ backdrop: { message: null, show: false } }));
    },
    []
  );

  const lockSettings = initState !== SettingsState.INITIALIZED && initState !== SettingsState.ERROR;
  // Responsible for updating the UI backdrop to show or hide a loading message based on the current settings state.
  useEffect(() => {
    if (lockSettings) {
      dispatch(
        updateUi({
          backdrop: {
            show: true,
            message: {
              msgCode:
                initState !== SettingsState.WAITING_LABELS
                  ? 'processFlow.loadingFlow'
                  : 'processFlow.loadingLabels',
              msg:
                initState !== SettingsState.WAITING_LABELS
                  ? 'Loading acquisition flow...'
                  : 'Loading label pictures..'
            }
          }
        })
      );
    } else {
      dispatch(
        updateUi({
          backdrop: {
            show: false
          }
        })
      );
    }
  }, [initState, dispatch]);

  // At every change, debounce it before saving to backup instance
  useEffect(() => {
    if (!isDiscarding) {
      debouncedSaveFunction(initState === SettingsState.INITIALIZED);
    }
  }, [nodes, edges, debouncedSaveFunction]);

  /** Handles the Ctrl key press, used to trigger node copy events */
  useEffect(() => {
    dispatch(setIsCtrlPressed(isCtrlPressed));
  }, [isCtrlPressed]);

  const renderEditor = useCallback(() => {
    const _nodes = reactFlowInstance?.getNodes() || [];
    const nodeIndex = getElementIndex(_nodes, modalState.id);
    if (!modalState.id || nodeIndex === -1) {
      console.info(
        `Couldn't find node with index ${nodeIndex}, id:${String(modalState.id)}`,
        _nodes
      );
      setModalState({ open: false });
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const nodeData = _nodes[nodeIndex].data;
    const EditorComponent = nodeData.editor;
    if (!EditorComponent) {
      setModalState({ open: false });
      return;
    }

    return (
      <EditorComponent
        data={nodeData.specificData}
        onSave={getOnEditorSave(modalState.id)}
        nodeId={modalState.id}
        domain={domain}
      />
    );
  }, [reactFlowInstance, modalState, getOnEditorSave]);

  const showError: OnErrorCallback = useCallback(
    (error, msgPropsOrFun) => {
      dispatch(
        updateUi({
          snackbar: {
            message: $t(
              { id: error },
              isFunction(msgPropsOrFun) ? msgPropsOrFun(intl) : msgPropsOrFun
            ) as JSX.Element | string,
            severity: 'error',
            duration: 3000,
            show: true
          }
        })
      );
      return undefined;
    },
    [dispatch, $t]
  );

  const onSaveSettings = useCallback(() => {
    // Get all nodes by type, then sort it by type and id in domain settings
    if (settings && reactFlowInstance && domainLabelStates && parentUid) {
      const _nodes = reactFlowInstance.getNodes();
      const _edges = reactFlowInstance.getEdges();

      // Check each node data for completion
      const labelDesigns = checkNodeData<LabelDesign>(_nodes, NodeType.LabelDesign, showError);
      if (!labelDesigns) return;

      const autoFillData = checkNodeData<AutoFillData[]>(_nodes, NodeType.AutoFillData, showError);
      if (!autoFillData) return;

      const conditionals = checkNodeData<CaseData[]>(_nodes, NodeType.Conditional, showError);
      if (!conditionals) return;

      const logicalBlocks = checkNodeData<LogicalBlock>(_nodes, NodeType.LogicalBlock, showError);
      if (!logicalBlocks) return;

      const mifs = checkNodeData<ManualInputField>(_nodes, NodeType.ManualInputField, showError);
      if (!mifs) return;

      const picTaking = checkNodeData<PictureTaking>(_nodes, NodeType.TakePicture, showError);
      if (!picTaking) return;

      const customMarkers = checkNodeData<CustomMarker>(_nodes, NodeType.CustomMarker, showError);
      if (!customMarkers) return;

      // Check workflow consistency
      if (!isProcessFlowConsistent(_nodes, _edges, showError)) return;

      // Transform nested lists to map of list due to firebase requirements
      const _conditionals = {} as MapOf<MapOf<CaseData>>;
      Object.entries(conditionals).forEach(
        ([id, caseDataList]) => (_conditionals[id] = convertListToMap(caseDataList))
      );

      // Transform parent's outputNodeIds to pure (currently only Conditional is an output)
      Object.values(logicalBlocks).forEach((lb) => {
        lb.outputNodeId = lb.outputNodeId ? getPureId(lb.outputNodeId) : undefined;
      });

      // Build up Domain settings from edges, nodes and its data
      const newSettings: ProcessFlowSettings = {
        autoFillData,
        conditionals: _conditionals,
        labelDesigns,
        logicalBlocks,
        manualInputFields: mifs,
        picTaking,
        customMarkers,
        edges: frontToSettEdges(_edges),
        nodes: frontToSettNodes(_nodes)
      };

      // Checks for new labels that have been uploaded or backed up and store them
      let labelDesignPictures: { [labelId: string]: string } = {};
      Object.entries(labelDesigns).forEach(([labelId, labelData]) => {
        if (!labelId) return;

        // Remove frontend-only flags
        delete labelData.copiedFromId;
        delete labelData.isBackup;

        const labelState = domainLabelStates[labelId];
        if (!labelState) return;

        const { localSrc } = labelState;
        if (localSrc) {
          labelDesignPictures[labelId] = localSrc;
          labelData.isBackup = false;
        }
      });
      labelDesignPictures = labelDesignPictures || {};

      dispatch(
        editProcessFlowSettings({
          parentUid,
          data: {
            currentSettings: settings,
            newSettings,
            domain,
            ...(Object.keys(labelDesignPictures).length ? { labelDesignPictures } : {})
          }
        })
      );
      setHasUnsavedChanges(false);
    }
  }, [reactFlowInstance, settings, domainLabelStates]);

  return (
    <Container className={classes.root}>
      {/* Header */}
      <Typography variant="subtitle1" sx={text.subtitle}>
        {$t({ id: 'processFlow.subtitle' })}
      </Typography>

      {/* Process flow editor */}
      <div className={classes.flowContainer}>
        <div className={classes.flowWrapper} ref={reactFlowWrapper}>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            draggable={false}
            className={classes.flow}
            snapToGrid={true}
            snapGrid={[2, 2]}
            minZoom={0.25}
            defaultViewport={{ x: 0, y: 0, zoom: 0.5 }}
            maxZoom={2.5}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            deleteKeyCode={'Delete'}
            connectionLineComponent={ConnectionLine}
            // Locking states
            edgesReconnectable={!lockSettings}
            edgesFocusable={!lockSettings}
            nodesDraggable={!lockSettings}
            nodesConnectable={!lockSettings}
            nodesFocusable={!lockSettings}
            panOnDrag={!lockSettings}
            zoomOnPinch={!lockSettings}
            zoomOnScroll={!lockSettings}
            zoomOnDoubleClick={!lockSettings}
            elementsSelectable={!lockSettings}
            // Callbacks
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onNodeDrag={onNodeDrag}
            onNodeDragStop={onNodeDragStop}
            onNodesDelete={onNodesDelete}
            onDrop={onDrop}
            onDragOver={onDragOver}
            onInit={onInit}
            onConnect={onConnect}
            onConnectStart={onConnectStart}
            onClickConnectStart={onConnectStart}
            onConnectEnd={onConnectEnd}
            onClickConnectEnd={onConnectEnd}
            onReconnect={onReconnect}
            onReconnectStart={onReconnectStart}
            onReconnectEnd={onReconnectEnd}
            isValidConnection={isValidConnection}
          >
            <MarkerDefinition id="custom-edge-marker-pri" color={palette.primary.dark} />
            <MarkerDefinition id="custom-edge-marker-sec" color={palette.secondary.dark} />
            <Background
              color={lighten(palette.primary.dark, 0.7)}
              variant={BackgroundVariant.Lines}
            />
            {!lockSettings && <Controls showInteractive={false} />}
            {/* COR */}
            {/* <ElementsDebugger edges={edges} /> */}
            {/* <ElementsDebugger nodes={nodes} /> */}
          </ReactFlow>
        </div>
        {!lockSettings && (
          <FlowSidebar style={{ borderLeft: `double medium ${palette.primary.dark}` }} />
        )}
      </div>
      <TransitionModal
        open={modalState.open}
        onClose={() => setModalState({ open: false })}
        containerClass={classes.modalContainer}
      >
        {(modalState.open && renderEditor()) || <div />}
      </TransitionModal>
      <Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
        {/* Discard button */}
        {hasUnsavedChanges ? (
          <Grid item xs="auto">
            <Button
              variant="outlined"
              color="primary"
              sx={{ mr: 2 }}
              onClick={discardChanges}
              disabled={!settings}
            >
              {$t({ id: 'discardChanges' })}
            </Button>
          </Grid>
        ) : null}
        {/* Save button */}
        <Grid item xs="auto">
          <Button
            variant="contained"
            color="secondary"
            onClick={onSaveSettings}
            disabled={!settings}
          >
            {$t({ id: 'saveChanges' })}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

const WrappedFlow: React.FC = (props) => (
  <ReactFlowProvider>
    <Workflow {...props} />
  </ReactFlowProvider>
);

export default WrappedFlow;
