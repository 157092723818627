import { updateDoc, writeBatch } from 'firebase/firestore';
import { ReviewState, TaskParserResult, TaskState } from 'flyid-core/dist/Database/Models';
import { getInventoryTaskDoc, getSessionDoc } from 'flyid-core/dist/Util/database';
import { firestore } from 'src/firebase/firebase';
import { buildDocumentRef } from 'src/firebase/firestore';
import { silentyNavigateTo } from 'src/router';
import authAxios from 'src/util/axios';
import { getSnackbar } from 'src/util/helpers/server';
import { updateUi } from '../reducers/uiReducer';
import { ThunkActionType } from '../store';

export type RemoveSessionsParams = { company: string; domain: string; session: string[] };
export const removeSessions =
  (data: RemoveSessionsParams): ThunkActionType =>
  (dispatch, getState, { getAuth }) => {
    const { company, domain, session } = data;
    if (!Array.isArray(session) || !session.length || !company || !domain) {
      return;
    }

    dispatch(
      updateUi({
        backdrop: {
          show: true,
          message: {
            msgCode: 'domain.removingSessions',
            msg: 'Removing session(s)...'
          }
        }
      })
    );

    authAxios(getAuth(), getState(), {
      url: `/sessions`,
      method: 'delete',
      params: { domain, session }
    })
      .then(() => {
        dispatch(
          updateUi({
            snackbar: {
              message: {
                msgCode: 'domain.sessionsRemoved',
                msg: 'Session(s) removed'
              },
              duration: 5000,
              severity: 'success',
              show: true
            }
          })
        );
      })
      .catch((err: Error) => {
        dispatch(
          updateUi({
            snackbar: getSnackbar(err)
          })
        );
      });
  };

export type TriggerSessionPushParams = { domain: string; session: string[] };
export const triggerSessionPush =
  (data: TriggerSessionPushParams): ThunkActionType =>
  (dispatch, getState, { getAuth }) => {
    dispatch(
      updateUi({
        backdrop: {
          show: true,
          message: { msgCode: 'manDom.trignSessionPush', msg: 'Triggering session push' }
        },
        dialog: {
          show: false
        }
      })
    );

    authAxios(getAuth(), getState(), {
      baseURL: process.env.REACT_APP_ENTRY_POINT,
      url: `triggerSessionPush`,
      method: 'post',
      params: {
        ...data,
        isForcedPush: true
        // COR Comment on release !!!
        // pushUrl: `${process.env.REACT_APP_API_ENTRY_POINT}/sessionPush`
      }
    })
      .then(() =>
        dispatch(
          updateUi({
            snackbar: {
              message: {
                msgCode: 'domain.pushTriggered',
                msg: 'Push has been triggered!'
              },
              duration: 5000,
              severity: 'success',
              show: true
            }
          })
        )
      )
      .catch((err: Error) => dispatch(updateUi({ snackbar: getSnackbar(err) })));
  };

export type RestartTaskParams = { company: string; domain: string; taskId: string };
export const restartTask =
  (data: RestartTaskParams): ThunkActionType =>
  (dispatch) => {
    const { company, domain, taskId } = data;
    if (!taskId || !company || !domain) {
      return;
    }

    dispatch(
      updateUi({
        backdrop: {
          show: true,
          message: {
            msgCode: 'inventoryTasks.restartingTask',
            msg: 'Restarting task...'
          }
        }
      })
    );

    updateDoc(buildDocumentRef(getInventoryTaskDoc(company, domain, taskId)), {
      state: TaskState.PENDING
    })
      .then(() => {
        dispatch(
          updateUi({
            snackbar: {
              message: {
                msgCode: 'inventoryTasks.taskRestarted',
                msg: 'Task has been restarted!'
              },
              duration: 5000,
              severity: 'success',
              show: true
            }
          })
        );
      })
      .catch((err: Error) => {
        dispatch(
          updateUi({
            snackbar: getSnackbar(err)
          })
        );
      });
  };

export type RemoveTaskParams = { company: string; domain: string; taskIds: string[] };
export const removeTask =
  (data: RemoveTaskParams): ThunkActionType =>
  (dispatch) => {
    const { company, domain, taskIds } = data;
    if (!taskIds.length || !company || !domain) {
      return;
    }

    dispatch(
      updateUi({
        backdrop: {
          show: true,
          message: {
            msgCode: 'inventoryTasks.removingTask',
            msg: 'Removing task...'
          }
        }
      })
    );

    const batch = writeBatch(firestore);
    taskIds.forEach((task) =>
      batch.delete(buildDocumentRef(getInventoryTaskDoc(company, domain, task)))
    );
    batch
      .commit()
      .then(() => {
        dispatch(
          updateUi({
            snackbar: {
              message: {
                msgCode: 'inventoryTasks.taskRemoved',
                msg: 'Tasks removed'
              },
              duration: 5000,
              severity: 'success',
              show: true
            }
          })
        );
      })
      .catch((err: Error) => {
        dispatch(
          updateUi({
            snackbar: getSnackbar(err)
          })
        );
      });
  };

export type CreateTaskParams = {
  domain: string;
  name: string;
  tasksData: TaskParserResult;
  settingsTimestamp: number;
};
export const createTask =
  (data: CreateTaskParams): ThunkActionType =>
  (dispatch, getState, { getAuth }) => {
    const { domain, name, tasksData, settingsTimestamp } = data;

    if (!domain || !tasksData || !settingsTimestamp) {
      return;
    }

    dispatch(
      updateUi({
        loadingButton: {
          isCreateTaskLoading: true
        }
      })
    );

    authAxios(getAuth(), getState(), {
      url: `/tasks`,
      method: 'post',
      params: { domain, name, isParsed: true },
      data: { parsedData: tasksData, settingsTimestamp },
      headers: {
        ['Content-Type']: 'application/json'
      }
    })
      .then(() => {
        dispatch(
          updateUi({
            snackbar: {
              message: { msgCode: 'createInventoryTask.taskCreated', msg: 'Task Created!' },
              duration: 3000,
              severity: 'success',
              show: true
            }
          })
        );
        silentyNavigateTo(-1);
      })
      .catch((err: Error) => {
        dispatch(
          updateUi({
            snackbar: getSnackbar(err)
          })
        );
      });
  };

// Updates Session addresses data (session review)
export type UpdateSessionParams = {
  company: string;
  domain: string;
  session: string;
  userName: string;
  addresses: string;
  redirectUser: () => void;
};
export const updateSession =
  (data: UpdateSessionParams): ThunkActionType =>
  (dispatch) => {
    const { company, domain, session, userName, addresses, redirectUser } = data;

    if (!company || !domain || !session || !userName || !addresses) {
      console.error(`
        Cannot finish the review: Missing data!
        Company: ${company}
        Domain: ${domain}
        Session: ${session}
        User Name: ${userName}
        Addresses: ${addresses}
      `);
      return;
    }

    dispatch(
      updateUi({
        backdrop: {
          show: true,
          message: {
            msgCode: 'sessionReview.submittingReview',
            msg: 'Submitting review...'
          }
        }
      })
    );

    updateDoc(buildDocumentRef(getSessionDoc(company, domain, session)), {
      addresses,
      reviewState: ReviewState.COMPLETED,
      whenWasReviewed: new Date(),
      whoReviewed: userName
    })
      .then(() => {
        dispatch(
          updateUi({
            snackbar: {
              message: {
                msgCode: 'sessionReview.reviewSubmitted',
                msg: 'Review has been submitted!'
              },
              duration: 5000,
              severity: 'success',
              show: true
            }
          })
        );
      })
      .catch((err: Error) => {
        dispatch(
          updateUi({
            snackbar: getSnackbar(err)
          })
        );
      })
      .finally(() => redirectUser());
  };
