import { Container, Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppSelector } from 'src/hooks/reduxHooks';
import { selectCurrentUserProfile } from 'src/redux/selectors/userSelectors';
import { appMakeStyles } from 'src/theme/theme';

const useStyles = appMakeStyles((theme) => ({
  container: { ...theme.resizableContainer(2) },
  margin: {
    marginBottom: theme.spacing(2)
  }
}));

const Main: React.FC = () => {
  const classes = useStyles();
  const { profile } = useAppSelector((state) => ({
    profile: selectCurrentUserProfile(state)
  }));

  return (
    <Container className={classes.container}>
      <Typography variant="h4" className={classes.margin}>
        <FormattedMessage
          id="main.title"
          values={{ firstName: profile.firstName, lastName: profile.lastName }}
        />
      </Typography>
      <Typography variant="subtitle1" className={classes.margin}>
        <FormattedMessage id="main.msg" />
      </Typography>
    </Container>
  );
};

export default Main;
