import React, { useEffect, useState } from 'react';
import { Button, Menu, MenuItem, Checkbox, Badge, ListItemIcon, ListItemText } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useIntl } from 'react-intl';

type Props = {
  /** All filters available for selection */
  availableFilters: string[] | Record<string, string>;
  /** Callback for filter changes */
  onFilterChange: (selected: string[]) => void;
  /** The initially selected filters, if any */
  initialState?: string[];
};

/**
 * Filter component for selection of elements using a with multi-select dropdown.
 */
const DropdownFilter: React.FC<Props> = ({ availableFilters, initialState, onFilterChange }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { $t } = useIntl();

  const [selectedFilters, setSelectedFilters] = useState<string[]>(initialState ?? []);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFilterChange = (selected: string) => {
    setSelectedFilters((prev) => {
      const newSelected = prev.includes(selected)
        ? prev.filter((f) => f !== selected)
        : [...prev, selected];
      return newSelected;
    });
  };

  const handleClearFilters = () => {
    setSelectedFilters([]);
  };

  useEffect(() => {
    onFilterChange(selectedFilters);
  }, [selectedFilters]);

  return (
    <div>
      <Button
        variant="text"
        onClick={handleClick}
        startIcon={<FilterListIcon />}
        sx={{ pr: 2 }}
        data-testid="filter-button"
      >
        {$t({ id: 'filters' })}
        {selectedFilters.length > 0 && (
          <Badge
            badgeContent={selectedFilters.length}
            color="secondary"
            sx={{ pl: 1.5, pr: 1 }}
            data-testid="filter-badge"
          />
        )}
      </Button>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose} data-testid="filter-menu">
        {(Array.isArray(availableFilters) ? availableFilters : Object.keys(availableFilters)).map(
          (key) => (
            <MenuItem
              key={key}
              onClick={() => handleFilterChange(key)}
              data-testid={`filter-item-${key}`}
            >
              <ListItemIcon>
                <Checkbox checked={selectedFilters.includes(key)} data-testid={`checkbox-${key}`} />
              </ListItemIcon>
              <ListItemText
                primary={Array.isArray(availableFilters) ? key : availableFilters[key]}
              />
            </MenuItem>
          )
        )}
      </Menu>

      {selectedFilters.length > 0 && (
        <Button
          variant="outlined"
          onClick={handleClearFilters}
          sx={{ ml: 2 }}
          data-testid="clear-filters-button"
        >
          {$t({ id: 'clearFilters' })}
        </Button>
      )}
    </div>
  );
};

export default DropdownFilter;
