import { FirebaseApp } from 'firebase/app';
import { Auth, onAuthStateChanged, Unsubscribe, User } from 'firebase/auth';
import {
  documentId,
  Firestore,
  getDoc,
  onSnapshot,
  Query,
  query,
  QuerySnapshot,
  where
} from 'firebase/firestore';
import { FirebaseStorage } from 'firebase/storage';
import { migrateSettings } from 'flyid-core/dist/Database/Migration/settingsMigration';
import { APIKey, License } from 'flyid-core/dist/Database/Models';
import { filterArray } from 'flyid-core/dist/Util/arrays';
import {
  getApiKeysCol,
  getAuthenticationProviderSettingsDoc,
  getCompanyDoc,
  getDomainsCol,
  getOurLicensesCol,
  getUserProfileDoc,
  getUsersCol
} from 'flyid-core/dist/Util/database';
import { getStoredOrDefault } from 'flyid-core/dist/Util/web';
import { debounce } from 'lodash';
import {
  removeData,
  setAuthProvider,
  setDataError,
  setDataLoaded,
  setDataLoading
} from 'src/redux/reducers/firestoreReducer';
import { getGlobalStorageKey, updateGlobals } from 'src/redux/reducers/globalsReducer';
import { updateUi } from 'src/redux/reducers/uiReducer';
import { setUserProfile, setUserProfileError } from 'src/redux/reducers/userReducer';
import { selectTargetCompany } from 'src/redux/selectors/globalSelectors';
import { selectAuthDomains, selectCurrentUserProfile } from 'src/redux/selectors/userSelectors';
import type { AppStore } from 'src/redux/store';
import { DEBUG_LISTENERS } from 'src/util/debug';
import { mostRecentSettingsDocQuery } from 'src/util/helpers/database';
import { isKeyUserProf, isModeratorProf } from 'src/util/helpers/user';
import { Nilable } from 'tsdef';
import { selectFirestoreTarget } from './../redux/types/firestoreTypes';
import { firestore } from './firebase';
import { buildCollectionRef, buildDocumentRef, docsToMap, getDividedQueries } from './firestore';

export type UpdateListenersData = { company: string; state: Nilable<ListenersState> };
export type UnsubscribeListeners = { [company: string]: Nilable<Array<keyof ListenersState>> };

type Unsubs = Unsubscribe | Unsubscribe[];
/** Keeps track of data listeners, if Unsubs exists, then listeners have been set. */
export type ListenersState = {
  domainSettings?: Unsubs;
  userProfiles?: Unsubs;
  authLicenses?: Unsubs;
  apiKeys?: Unsubs;
  companiesData?: Unsubs;
  authDomains?: Unsubs;
};

let listenersStatePerCompany: { [company: string]: ListenersState | undefined } = {};
let keyUserCompaniesDataUnsubs: Unsubscribe[] | null = null;

const updateListenersState = (company: string, state: Nilable<ListenersState>) => {
  // console.log(`Updating listeners of ${company}:`, state);
  listenersStatePerCompany[company] = { ...listenersStatePerCompany[company], ...state };
};

const cancelListener = (unsubs: Nilable<Unsubs>) =>
  Array.isArray(unsubs) ? unsubs.forEach((unsub) => unsub()) : unsubs?.();

/** Send null payload to unsubscribe all */
const unsubscribeListeners = (data: UnsubscribeListeners | null) => {
  // Null payload will trigger complete cleanup of listeners
  if (!data) {
    if (DEBUG_LISTENERS) console.log('unsubscribing all');
    // Unsubscribe locals as well
    userDataListenerUnsubscriber?.();
    userDataListenerUnsubscriber = null;

    keyUserCompaniesDataUnsubs?.forEach((unsub) => unsub());
    keyUserCompaniesDataUnsubs = null;

    Object.values(listenersStatePerCompany).forEach((companyListeners) => {
      if (companyListeners) Object.values(companyListeners).forEach(cancelListener);
    });
    listenersStatePerCompany = {};
  } else {
    if (DEBUG_LISTENERS) console.log('unsubscribing', data);
    // Payload should lead to specific listener cancellation
    Object.entries(data).forEach(([company, listenersData]) => {
      // If no specific listener is given, cancel all company's listeners.
      if (!listenersData) {
        Object.values(listenersStatePerCompany[company] ?? {}).forEach(cancelListener);
        listenersStatePerCompany[company] = {};
      } else {
        // Otherwise, cancel only the specifics ones
        listenersData.forEach((target) => {
          const companyListeners = listenersStatePerCompany[company];
          if (companyListeners) {
            const targetListener = companyListeners[target];
            if (targetListener) cancelListener(targetListener);

            companyListeners[target] = undefined;
          }
        });
      }
    });
  }
};

/** This fetch should happen once, when the app starts */
const fetchAuthSettings = (store: AppStore) => {
  // This document is public, therefore we don't need to wait for auth
  if (DEBUG_LISTENERS) console.log('Fetching authentication settings...');
  getDoc(buildDocumentRef(getAuthenticationProviderSettingsDoc()))
    .then((authSettingsDS) => {
      store.dispatch(setAuthProvider(authSettingsDS.data()));
    })
    .catch((error: Error) => console.error(`Failed fetching auth providers: (${error.message})`));
};

let userDataListenerUnsubscriber: Unsubscribe | null = null;
/** This listener should be set once the user logs in, and unsubscribed once logged out. */
const listenToCurrentUserProfile = (user: User, store: AppStore) => {
  userDataListenerUnsubscriber?.(); // Subscribe only once.
  if (DEBUG_LISTENERS) console.log('Adding current user data listener...');
  const { uid, emailVerified } = user;
  userDataListenerUnsubscriber = onSnapshot(buildDocumentRef(getUserProfileDoc(uid)), {
    next: (newUserProfile) => {
      user
        ?.getIdTokenResult(true)
        ?.then((userToken) => {
          if (!newUserProfile.exists()) throw new Error('Missing user profile!');
          if (!userToken) throw new Error('Missing user profile!');

          const state = store.getState();
          const existingProfile = selectCurrentUserProfile(state);
          // Whenever the current profile changes, make sure to reset domain settings listener,
          // since it requires refreshing due to possible changes to authDomains for non-key-users.
          // Key users will always listen to all targetCompany's domains, therefore no action is required.
          if (existingProfile) {
            if (!isKeyUserProf(existingProfile)) {
              const company = existingProfile.company as string;
              if (company) {
                if (DEBUG_LISTENERS)
                  console.log(`Resetting domain settings listener for ${company}`);
                // Unsubscribe listeners for old profile
                unsubscribeListeners({ [company]: ['domainSettings'] });
              }
            }
          }

          const newProfile = newUserProfile.data();
          // Key users require setting up globals on auth for consistency
          if (isKeyUserProf(newProfile)) {
            // Set global target company on login
            store.dispatch(
              updateGlobals({
                targetCompany: getStoredOrDefault(
                  getGlobalStorageKey('targetCompany'),
                  newProfile.company[0]
                )
              })
            );
          }

          store.dispatch(
            setUserProfile({
              uid,
              emailVerified,
              profile: newProfile,
              claims: userToken.claims as never
            })
          );
        })
        .catch((error: Error) => {
          console.error(error);
          store.dispatch(setUserProfileError({ uid, error }));
          store.dispatch(updateUi());
        });
    },
    error: (error) => console.error(`Failed fetching user profiles: (${error.message})`)
  });
};

/**
 * Listeners applied to all users.
 */
function listenToCommonData(store: AppStore) {
  const state = store.getState();
  const { profile } = state.user;

  const isKeyUser = isKeyUserProf(profile);
  const targetCompany = selectTargetCompany(state);

  // Company data, key users have a separate unsub criteria to avoid recursive calls from redux state updates
  const target = selectFirestoreTarget('companiesData');
  const companyDataSubscribe = (company: string) =>
    onSnapshot(buildDocumentRef(getCompanyDoc(company)), {
      next: (snap) => {
        store.dispatch(
          snap.exists()
            ? setDataLoaded({ company, target, data: snap.data() })
            : removeData({ target, keys: [company] })
        );
      },
      error: (error) => {
        console.error(`Failed fetching company data: (${error.message})`);
        store.dispatch(setDataError({ company, target, error: error.message }));
      }
    });

  if (isKeyUser) {
    if (!keyUserCompaniesDataUnsubs) {
      keyUserCompaniesDataUnsubs = profile.company.map((company) => {
        store.dispatch(setDataLoading({ company, target }));
        return companyDataSubscribe(company);
      });
    }
  } else if (targetCompany) {
    const companyListeners = listenersStatePerCompany[targetCompany];
    if (!companyListeners) {
      const unsubscribe = companyDataSubscribe(targetCompany);
      updateListenersState(targetCompany, { [target]: unsubscribe });
    }
  }
}

/**
 * Listens to data pertinent to moderator an key user profiles.
 * If key user, it will add listeners whenever a company is selected.
 */
const listenToManagementData = (store: AppStore) => {
  const state = store.getState();
  const { profile, uid, claims, isLoaded: isProfileLoaded } = state.user;

  const isKeyUser = isKeyUserProf(profile);
  const isModerator = isModeratorProf(profile);
  const targetCompany = selectTargetCompany(state);

  if ((isKeyUser || isModerator) && claims && isProfileLoaded) {
    if (targetCompany) {
      const companyListeners = listenersStatePerCompany[targetCompany];
      // User Profiles
      if (!companyListeners?.userProfiles) {
        if (DEBUG_LISTENERS)
          console.log(`Adding public profiles listener for company ${targetCompany}...`);

        const target = selectFirestoreTarget('userProfiles');
        let usersQuery = query(
          buildCollectionRef(getUsersCol()),
          where('company', '==', targetCompany)
        );
        if (isModerator) usersQuery = query(usersQuery, where('parent', '==', uid));

        store.dispatch(setDataLoading({ company: targetCompany, target }));
        const unsubscriber = onSnapshot(usersQuery, {
          next: (usersQS) => {
            if (usersQS.empty) {
              store.dispatch(setDataLoaded({ company: targetCompany, target, data: {} }));
              return;
            }

            const { updated, removed } = getChangedDocs(usersQS);
            if (removed.length)
              store.dispatch(
                removeData({
                  company: targetCompany,
                  target,
                  keys: removed.map((s) => s.id)
                })
              );
            if (updated.length)
              store.dispatch(
                setDataLoaded({
                  company: targetCompany,
                  target,
                  data: docsToMap(updated)
                })
              );
          },
          error: (error) => {
            store.dispatch(
              setDataError({
                company: targetCompany,
                target,
                error: error.message
              })
            );
          }
        });
        updateListenersState(targetCompany, { [target]: unsubscriber });
      }

      // Auth Licenses
      if (!companyListeners?.authLicenses) {
        if (DEBUG_LISTENERS)
          console.log(`Adding auth licenses listener for company ${targetCompany}...`);
        const authLicensesRef = buildCollectionRef(getOurLicensesCol());

        const target = selectFirestoreTarget('authLicenses');
        let unsubscribe: Unsubs;
        const setSnapshot = (_query: Query<License>, queryIndex?: number) =>
          onSnapshot(_query, {
            next: (licensesQS) => {
              if (licensesQS.empty) {
                store.dispatch(
                  setDataLoaded({ company: targetCompany, target, queryIndex, data: {} })
                );
                return;
              }
              const { updated, removed } = getChangedDocs(licensesQS);
              if (updated.length)
                store.dispatch(
                  setDataLoaded({
                    company: targetCompany,
                    target,
                    queryIndex,
                    data: docsToMap(updated)
                  })
                );
              if (removed.length)
                store.dispatch(
                  removeData({
                    company: targetCompany,
                    target,
                    keys: removed.map((s) => s.id)
                  })
                );
            },
            error: (error) => {
              console.error(`Failed fetching auth licenses: (${error.message})`);
              store.dispatch(
                setDataError({
                  company: targetCompany,
                  target,
                  error: error.message
                })
              );
            }
          });
        if (isModerator) {
          // Moderators have specific access.
          const lics = profile.authLicenses ?? [];
          // store.dispatch(setUserProfilesLoading({ company: targetCompany, target }));
          const queries = getDividedQueries(authLicensesRef, documentId(), 'in', lics);
          unsubscribe = queries.map(setSnapshot);
          store.dispatch(
            queries.length
              ? setDataLoading({ company: targetCompany, target, queryCount: queries.length })
              : // If use does not have any auth licenses, set data as loaded.
                setDataLoaded({ company: targetCompany, target, data: {} })
          );
        } else {
          // If not mod, then is certainly key user, which has unrestricted company-based access
          store.dispatch(setDataLoading({ company: targetCompany, target }));
          unsubscribe = setSnapshot(query(authLicensesRef, where('company', '==', targetCompany)));
        }

        updateListenersState(targetCompany, { [target]: unsubscribe });
      }

      // API Keys
      if (!companyListeners?.apiKeys) {
        if (DEBUG_LISTENERS)
          console.log(`Adding API Keys listener for company ${targetCompany}...`);
        const apiKeysRef = buildCollectionRef(getApiKeysCol());

        const target = selectFirestoreTarget('apiKeys');
        let unsubscribe: Unsubs;
        const setSnapshot = (_query: Query<APIKey>, queryIndex?: number) =>
          onSnapshot(_query, {
            next: (apiKeysQS) => {
              if (apiKeysQS.empty) {
                store.dispatch(
                  setDataLoaded({ company: targetCompany, target, queryIndex, data: {} })
                );
                return;
              }

              const { updated, removed } = getChangedDocs(apiKeysQS);
              if (updated.length)
                store.dispatch(
                  setDataLoaded({
                    company: targetCompany,
                    target,
                    queryIndex,
                    data: docsToMap(updated)
                  })
                );
              if (removed.length)
                store.dispatch(
                  removeData({ company: targetCompany, target, keys: removed.map((s) => s.id) })
                );
            },
            error: (error) => {
              console.error(`Failed fetching API Keys: (${error.message})`);
              store.dispatch(
                setDataError({ company: targetCompany, target, error: error.message })
              );
            }
          });

        if (isModerator) {
          // Moderators have specific access.
          const keys = claims.ownedAPIKeys ?? [];
          const queries = getDividedQueries(apiKeysRef, documentId(), 'in', keys);
          unsubscribe = queries.map(setSnapshot);
          store.dispatch(
            queries.length
              ? setDataLoading({ company: targetCompany, target, queryCount: queries.length })
              : // If use does not have any api keys, set data as loaded.
                setDataLoaded({ company: targetCompany, target, data: {} })
          );
        } else {
          // If not mod, then is certainly key user, which has unrestricted company-based access
          store.dispatch(setDataLoading({ company: targetCompany, target }));
          unsubscribe = setSnapshot(query(apiKeysRef, where('company', '==', targetCompany)));
        }

        updateListenersState(targetCompany, { apiKeys: unsubscribe });
      }

      // Auth Domains, if key user
      if (isKeyUser && !companyListeners?.authDomains) {
        if (DEBUG_LISTENERS) console.log(`Adding auth domains listener for ${targetCompany}...`);
        const target = selectFirestoreTarget('authDomains');
        store.dispatch(setDataLoading({ company: targetCompany, target }));

        const unsubscribe = onSnapshot(buildCollectionRef(getDomainsCol(targetCompany)), {
          next: (domainsQS) => {
            store.dispatch(
              setDataLoaded({
                target,
                company: targetCompany,
                data: domainsQS.docs.map((s) => s.id)
              })
            );
          },
          error: (error) => {
            console.error(`Failed domains data: (${error.message})`);
            store.dispatch(setDataError({ company: targetCompany, target, error: error.message }));
          }
        });

        updateListenersState(targetCompany, { [target]: unsubscribe });
      }
    }
  }
};

/**
 * Listens to domain settings data.
 * This listener is careful enough to trigger only once, unless
 */
const listenToDomainSettingsData = (store: AppStore) => {
  const state = store.getState();

  const targetCompany = selectTargetCompany(state);
  const authDomains = selectAuthDomains(state);

  if (
    targetCompany &&
    authDomains?.length &&
    !listenersStatePerCompany[targetCompany]?.domainSettings
  ) {
    if (DEBUG_LISTENERS) {
      console.log(`Adding domain settings listener for ${targetCompany}/${authDomains.join()}...`);
    }
    const target = selectFirestoreTarget('domainSettings');
    store.dispatch(
      setDataLoading({ target, company: targetCompany, queryCount: authDomains.length })
    );

    const unsubscribers = authDomains.map((domain, queryIndex) => {
      return onSnapshot(mostRecentSettingsDocQuery(firestore, targetCompany, domain), {
        next: (settingsQS) => {
          if (settingsQS.empty) {
            console.error(`No domain settings for domain ${targetCompany}/${domain}!`);
            setDataLoaded({ target, company: targetCompany, queryIndex, data: {} });
            return;
          }
          settingsQS.docChanges().forEach((ch) => {
            store.dispatch(
              ch.type === 'removed'
                ? removeData({
                    company: targetCompany,
                    target: 'domainSettings',
                    keys: [ch.doc.id]
                  })
                : setDataLoaded({
                    target,
                    company: targetCompany,
                    queryIndex,
                    data: { [domain]: migrateSettings(ch.doc.data()) }
                  })
            );
          });
        },
        error: (error) => console.error(`Failed fetching domain settings: (${error.message})`)
      });
    });
    updateListenersState(targetCompany, { domainSettings: unsubscribers });
  }
};

// Triggers these actions whenever auth state changes
function setupOnAuthStateListener(store: AppStore, auth: Auth) {
  onAuthStateChanged(auth, (user) => {
    const isLoggedIn = !!user;
    if (isLoggedIn) {
      listenToCurrentUserProfile(user, store);
    } else {
      unsubscribeListeners(null);
    }
  });
}

function getChangedDocs<T>(qs: QuerySnapshot<T>) {
  const [updated, removed] = filterArray(qs.docChanges(), (ch) => ch.type !== 'removed');
  return {
    updated: updated.map((ch) => ch.doc),
    removed: removed.map((ch) => ch.doc)
  };
}

/* eslint-disable @typescript-eslint/no-unused-vars,@typescript-eslint/no-shadow */
export function setupListeners({
  store,
  firebaseApp,
  auth,
  firestore,
  defaultBucket,
  profilePicsBucket
}: /* eslint-enable @typescript-eslint/no-unused-vars,@typescript-eslint/no-shadow */
{
  store: AppStore;
  firebaseApp: FirebaseApp;
  auth: Auth;
  firestore: Firestore;
  defaultBucket: FirebaseStorage;
  profilePicsBucket: FirebaseStorage;
}) {
  setupOnAuthStateListener(store, auth);
  // Listeners to be set once
  fetchAuthSettings(store);

  // Avoid executing the calls repeatedly when states update in short time intervals
  // by debouncing it.
  const executeDebouncedListeners = debounce(() => {
    if (!!auth.currentUser) {
      listenToCommonData(store);
      listenToManagementData(store);
      listenToDomainSettingsData(store);
    }
  }, 50);

  // Listeners that may change, given global state changes.
  // The listeners  contained in 'subscribe' should care about their own listening states in order
  // to avoid multiplicity of listeners.
  store.subscribe(() => executeDebouncedListeners());
}
