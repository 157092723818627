import type { GlobalsState } from 'src/redux/reducers/globalsReducer';
import { GlobalsStateKey, updateGlobals } from 'src/redux/reducers/globalsReducer';
import { useAppDispatch, useAppSelector } from './reduxHooks';

/**
 * This state is backed by redux through {@link GlobalsSlice}.
 * Use it when there is a requirement to use a global state with behavior similar to {@link useState}.
 */
const useGlobalState = <Key extends GlobalsStateKey>(key: Key) => {
  type Value = GlobalsState[GlobalsStateKey];

  const dispatch = useAppDispatch();
  const currValue = useAppSelector((s) => s.globals[key]);
  const setStateFun = (value: Value) => dispatch(updateGlobals({ [key]: value }));

  return [currValue, setStateFun] as [Value, typeof setStateFun];
};

export default useGlobalState;
