import {
  Box,
  lighten,
  TableRow as MuiTableRow,
  Table,
  TableBody,
  TableCell,
  TableHead,
  Typography
} from '@mui/material';
import React from 'react';
import { appMakeStyles } from 'src/theme/theme';
import { CellValue, TableRow } from 'src/util/helpers/table';

const useStyles = appMakeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'left',
    marginBottom: '50px'
  },
  tableTitle: {
    marginTop: '20px',
    marginBottom: '30px',
    fontSize: '26px'
  },
  tableSubtitle: {
    marginTop: '20px',
    marginBottom: '20px',
    fontSize: '18px'
  },
  headerCell: {
    backgroundColor: lighten(theme.palette.primary.dark, 0.85),
    fontWeight: 'bold'
  }
}));

type DivergencyTableData = {
  divergentRows: TableRow[];
  checkFields: {
    field: string;
    checkField: string;
  }[];
  idField: string;
};

type Props = {
  title?: string;
  data: DivergencyTableData;
};

const DivergencyTable: React.FC<Props> = (props) => {
  const classes = useStyles();

  const columns = [props.data.idField];
  const rows: CellValue[][] = [];
  let array: CellValue[] = [];

  props.data.checkFields.forEach((fields) => {
    columns.push(fields.field);
    columns.push(fields.checkField);
  });

  props.data.divergentRows.forEach((row) => {
    array = [];

    if (row[props.data.idField]) {
      array.push(row[props.data.idField]);
    }

    props.data.checkFields.forEach((fields) => {
      if (row[fields.field]) {
        array.push(row[fields.field]);
      } else {
        array.push('');
      }

      if (row[fields.checkField]) {
        array.push(row[fields.checkField]);
      } else {
        array.push('');
      }
    });

    rows.push(array);
  });

  return (
    <Box className={classes.container}>
      <Box>
        {props.title ? <Typography className={classes.tableTitle}>{props.title}</Typography> : null}
        <Table>
          <TableHead>
            {columns.length > 1
              ? columns.map((column, index) => {
                  return (
                    <TableCell key={`${index}${column}`} className={classes.headerCell}>
                      {column}
                    </TableCell>
                  );
                })
              : null}
          </TableHead>
          <TableBody>
            {rows.length
              ? rows.map((row,rowIdx) => {
                  return (
                    <MuiTableRow>
                      {row.map((r, cellIdx) => {
                        return <TableCell key={`r${rowIdx}c${cellIdx}`}>{r}</TableCell>;
                      })}
                    </MuiTableRow>
                  );
                })
              : null}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};

export default DivergencyTable;
