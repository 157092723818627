import { cx } from '@emotion/css';
import { Container, TextField, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { Navigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks';
import { useAuth } from 'src/hooks/useAuth';
import useStateReducer from 'src/hooks/useStateReducer';
import { changePassword } from 'src/redux/actions/userActions';
import { updateUi } from 'src/redux/reducers/uiReducer';
import { selectCurrentUserProfile } from 'src/redux/selectors/userSelectors';
import { appMakeStyles } from 'src/theme/theme';
import { isPilotProf } from '../../util/helpers/user';
import LoadingButton from '../widgets/LoadingButton';

const useStyles = appMakeStyles((theme) => ({
  margin: {
    marginBottom: theme.spacing(2)
  },
  title: {
    color: theme.other.grey.dark
  },
  container: {
    ...theme.resizableContainer(2),
    marginLeft: 0,
    maxWidth: '1000px'
  },
  inputContainer: {
    marginLeft: 0,
    maxWidth: '300px'
  }
}));

const initialState = {
  currentPassword: '',
  newPassword: '',
  confirmation: ''
};
type State = typeof initialState;
type TextFieldProps = Parameters<typeof TextField>[0];

const dataFieldsProps: Record<keyof State, TextFieldProps> = {
  currentPassword: { autoComplete: 'current-password' },
  newPassword: { autoComplete: 'new-password' },
  confirmation: { autoComplete: 'new-password' }
};

type Props = {
  isPin: boolean;
};

const ChangePassword: React.FC<Props> = ({ isPin }) => {
  const [state, setState] = useStateReducer(initialState);

  const classes = useStyles();
  const { $t } = useIntl();
  const dispatch = useAppDispatch();
  const { usingSSO } = useAuth();

  const { profile, ui } = useAppSelector((s) => ({
    profile: selectCurrentUserProfile(s),
    ui: s.ui
  }));

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    setState({ [e.target.name]: e.target.value });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const { currentPassword, newPassword, confirmation } = state;

    if (isPilotProf(profile)) {
      if (!/^\d{6}$/.test(newPassword)) {
        dispatch(
          updateUi({
            snackbar: {
              message: $t({ id: 'err.pilotPassword' }),
              severity: 'error',
              show: true
            }
          })
        );
        return;
      }
    }

    if (currentPassword && newPassword && confirmation && newPassword === confirmation) {
      dispatch(changePassword({ currentPassword, newPassword, isPin }));
      return;
    }

    dispatch(
      updateUi({
        snackbar: {
          message: $t({ id: 'chgPw.noMatch' }),
          severity: 'error',
          show: true
        }
      })
    );
  };
  const isPilotOrCheckerPin = usingSSO && !!(profile?.pilot || profile?.checker);

  return !profile?.pwUpdated && (!usingSSO || isPilotOrCheckerPin) ? (
    <Navigate replace to="/setpw" />
  ) : (
    <Container className={classes.container}>
      <Typography variant="h4" className={cx(classes.margin, classes.title)}>
        {$t({ id: `${isPin ? 'chgPin' : 'chgPw'}.title` })}
      </Typography>
      <form onSubmit={handleSubmit} className={classes.inputContainer}>
        {Object.entries(dataFieldsProps).map(([field, props]) => (
          <TextField
            key={field}
            required
            fullWidth
            variant="outlined"
            data-testid={field}
            id={field}
            name={field}
            type="password"
            label={$t({ id: `${isPin ? 'chgPin' : 'chgPw'}.${field}` })}
            value={state[field] as string}
            onChange={handleChange}
            className={classes.margin}
            inputProps={{ minLength: 6, maxLength: isPilotOrCheckerPin ? 6 : 999 }}
            {...props}
          />
        ))}

        <LoadingButton
          isLoading={ui.loadingButton.isChangePasswordLoading}
          content={$t({ id: 'submit' })}
          type="submit"
          data-testid="submit-btn"
        />
      </form>
    </Container>
  );
};

export default ChangePassword;
