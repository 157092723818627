import React, { JSX } from 'react';
import { Container, Typography, Button } from '@mui/material';
import { appMakeStyles } from 'src/theme/theme';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

const useStyles = appMakeStyles((theme) => ({
  text: {
    margin: theme.spacing(2),
    textAlign: 'center'
  },
  link: {
    margin: theme.spacing(1),
    textAlign: 'center'
  },
  centeredColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  },
  iconStyle: {
    color: theme.palette.error.main,
    fontSize: 75
  }
}));

type Props = {
  text: string;
  iconStyle?: React.CSSProperties;
  link?: {
    text: string;
    actionCallback: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  };
  extraView?: () => JSX.Element;
};

const BadRequest: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { text, iconStyle, link, extraView } = props;

  return (
    <Container className={classes.centeredColumn}>
      <WarningRoundedIcon className={classes.iconStyle} sx={iconStyle} />
      {text && text.length && (
        <Typography variant="h5" className={classes.text}>
          {text}
        </Typography>
      )}
      {link && (
        <Button className={classes.link} onClick={link.actionCallback}>
          {link.text}
        </Button>
      )}
      {extraView && extraView()}
    </Container>
  );
};

export default BadRequest;
