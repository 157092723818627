import { useEffect, useState } from 'react';
import { getPicturePath } from 'flyid-core/dist/Util/database';
import { ref, getDownloadURL } from 'firebase/storage';
import { defaultBucket } from 'src/firebase/firebase';

const useFirebaseImage = (
  company: string | undefined,
  domain: string,
  pictureFilenames: string[]
) => {
  const [images, setImages] = useState<{ src: string; alt: string }[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!company || !domain || !pictureFilenames.length || loading) return;

    setLoading(true);

    // Reference to the image in Firebase Storage
    const storageRefs = pictureFilenames.map((pictureFilename) =>
      ref(defaultBucket, getPicturePath(company, domain, pictureFilename))
    );

    // Fetching the image URLs
    Promise.all(storageRefs.map((storageRef) => getDownloadURL(storageRef)))
      .then((urls) => {
        setImages(urls.map((url) => ({ src: url, alt: url })));
      })
      .catch((err: Error) => {
        console.error(err);
        setError(`Failed to load images: ${err.message}`);
      })
      .finally(() => setLoading(false));
  }, [company, domain, pictureFilenames]);

  return { images, loading, error };
};

export default useFirebaseImage;
