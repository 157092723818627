import AddCircleIcon from '@mui/icons-material/AddCircle';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import DeleteIcon from '@mui/icons-material/Delete';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import TableViewRoundedIcon from '@mui/icons-material/TableViewRounded';
import UpgradeRoundedIcon from '@mui/icons-material/UpgradeRounded';
import { Button, IconButton, Skeleton, Tooltip, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { isEmpty } from 'lodash';
import React from 'react';
import { useIntl } from 'react-intl';
import { Link, Navigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks';
import { Actions } from 'src/redux/actions/actionsHandler';
import { updateUi } from 'src/redux/reducers/uiReducer';
import { selectSettings } from 'src/redux/selectors/dataSelectors';
import { MyDialogState } from 'src/redux/reducers/uiReducer';
import { appMakeStyles } from 'src/theme/theme';
import { AddressesMapKey } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/TabularData';
import { selectDomainParent } from 'src/redux/selectors/userSelectors';

const useStyles = appMakeStyles(({ spacing, form }) => ({
  root: { maxWidth: form.maxWidth, flexGrow: 1, marginLeft: spacing(2) },
  tooltip: { alignSelf: 'center', marginLeft: spacing(1) },
  tooltipRoot: { maxWidth: 300 },
  expander: { maxWidth: form.maxWidth },
  content: { margin: spacing(2, 2) },
  licenseSkeleton: { height: spacing(4) }
}));

const TabularDataEditor: React.FC = () => {
  const { $t } = useIntl();
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const { domain } = useParams<DomainMatchParams>();

  // Fallback to home if domain is missing
  if (!domain) return <Navigate replace to="/" />;

  const { tabularData, parentUid } = useAppSelector((s) => ({
    parentUid: selectDomainParent(s, domain),
    tabularData: selectSettings(s, domain)?.tabularData
  }));

  const showRemoveConfirmationDialog = (
    event: React.MouseEvent<HTMLButtonElement>,
    tableName: string
  ) => {
    event.preventDefault();
    if (!tabularData || !parentUid) return;

    // Todo - replace msgs
    dispatch(
      updateUi({
        dialog: new MyDialogState({
          title: $t({ id: `editTabularData.tableRemConfTitle` }),
          message: $t(
            { id: `editTabularData.tableRemConfMsg` },
            {
              tableName: <b>{tableName === AddressesMapKey ? $t({ id: tableName }) : tableName}</b>,
              nl: <br />
            }
          ),
          useCheckbox: true,
          checkboxState: false,
          checkboxMessage: <b>{$t({ id: `editTabularData.tableRemConfCheck` })}</b>,
          show: true
        }).setConfirmAction(Actions.REMOVE_TABLE, {
          parentUid,
          data: { domain, tableName }
        })
      })
    );
  };

  return (
    <Grid container rowSpacing={1} alignItems="center" className={classes.root}>
      {/* Title and add button */}
      <Grid container xs={12} alignItems="center" flexWrap="nowrap">
        <Grid xs="auto">
          <Typography variant="subtitle1">{$t({ id: 'editTabularData.subtitle' })}</Typography>
        </Grid>
        <Grid xs="auto">
          <Tooltip
            className={classes.tooltip}
            classes={{ tooltip: classes.tooltipRoot }}
            title={$t({ id: 'editTabularData.tooltip' })}
          >
            <HelpOutlineIcon fontSize="small" sx={{ color: 'info.dark' }} />
          </Tooltip>
        </Grid>
        <Grid xs />
        <Grid xs={'auto'}>
          <Button
            component={Link}
            to="addOrUpdateTable"
            color="secondary"
            aria-label={$t({ id: 'add' })}
          >
            <AddCircleIcon sx={{ mr: 1 }} />
            {$t({ id: 'add' })}
          </Button>
        </Grid>
      </Grid>

      {/* Data */}
      {tabularData ? (
        !isEmpty(tabularData.dataTables) ? (
          Object.entries(tabularData.dataTables).map(([tableName, tableData]) => {
            const isAddressMap = tableName === AddressesMapKey;
            const name = isAddressMap ? $t({ id: tableName }) : tableName;
            return (
              <Grid container xs={12} key={tableName} alignItems="center" sx={{ ml: 2 }}>
                <Grid container xs key={tableName} alignItems="center">
                  <Grid container xs={10} alignItems="center" columnSpacing={2}>
                    <Grid xs={12} container alignItems="center" columnSpacing={2} flexWrap="nowrap">
                      <Grid xs="auto">
                        <TableViewRoundedIcon />
                      </Grid>
                      <Grid xs>
                        <Typography>
                          <b>{name}</b> - {$t({ id: 'editTableData.description' })}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      xs={12}
                      alignItems="center"
                      justifyContent={'center'}
                      columnSpacing={2}
                      sx={{ mx: 1 }}
                    >
                      <Grid xs="auto">
                        <Typography>{tableData.keyColumn}</Typography>
                      </Grid>
                      <Grid xs="auto">
                        {isAddressMap ? <SwapHorizIcon /> : <ArrowRightAltIcon />}
                      </Grid>
                      <Grid
                        container
                        xs="auto"
                        alignItems="center"
                        columnSpacing={2}
                        flexDirection="column"
                        sx={{ m: 1 }}
                      >
                        {tableData.valueColumns.map((v, i) => (
                          <Grid xs="auto" key={`${tableName}${i}`}>
                            <Typography>{v}</Typography>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid xs={1}>
                    <Tooltip title={$t({ id: 'update' })}>
                      <IconButton
                        component={Link}
                        to={'addOrUpdateTable'}
                        state={{ updateName: tableName }}
                        edge="end"
                        aria-label={$t({ id: 'update' })}
                        sx={{ color: 'secondary.dark' }}
                        size="large"
                      >
                        <UpgradeRoundedIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid xs={1}>
                    <Tooltip title={$t({ id: 'remove' })}>
                      <IconButton
                        edge="end"
                        aria-label={$t({ id: 'remove' })}
                        sx={{ color: 'error.main' }}
                        onClick={(e) => showRemoveConfirmationDialog(e, tableName)}
                        size="large"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            );
          })
        ) : (
          <Grid xs={12} sx={{ ml: 2 }}>
            <Typography>{$t({ id: 'editTabularData.noTableSet' })}</Typography>
          </Grid>
        )
      ) : (
        [...Array(3).keys()].map((i) => (
          <Grid xs={12} sx={{ ml: 2 }}>
            <Skeleton
              variant="rectangular"
              className={classes.licenseSkeleton}
              animation="wave"
              key={`skel${i}`}
            />
          </Grid>
        ))
      )}
    </Grid>
  );
};

export default TabularDataEditor;
